import React, { useEffect, useState } from "react";
import TopHeaderCommon from "../Common/NewLayout/TopHeaderCommon";
import styles from "../Common/NewLayout/NewLayout.module.css";
import ProjectHeader from "../Common/NewLayout/ProjectHeader";
import arrowBack from "../../../src/Assets/arrowBack.svg"
import { Accordion, Dropdown } from "react-bootstrap";
import style from "./LeadDetails.module.css";
import "react-datepicker/dist/react-datepicker.css";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import SideProjectPanel from "../Common/NewLayout/SideProjectPanel";
import {
  PiCalendar,
  PiCalendarBlank,
  PiCaretDown,
  PiCaretDownBold,
  PiEnvelopeSimple,
  PiMapPin,
  PiPhoneCall,
  PiTag,
  PiX,
} from "react-icons/pi";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ReactComponent as PencilSimple } from "./images/PencilSimpleLine.svg";
import { ReactComponent as FolderNotchPlus } from "./images/FolderNotchPlus.svg";
import { ReactComponent as Trash } from "./images/Trash.svg";
import { ReactComponent as ZeroState } from "./images/ZeroStateImage.svg";
import "./Accordian.css";
import { constants } from "../../piservices/constants";
import DatePicker from "react-datepicker";
import { getToken } from "../../piservices/authService";
import { getReq, postReq } from "../../piservices/apis";
import EditLeadModal from "./EditLeadModal";
import ConfirmCancel from "./Modals/ConfirmCancel";
import toast from "react-hot-toast";

const LeadDetails = () => {
  const [leadsArray, setLeadsArray] = useState([]);
  const [additionalDetails, setAdditionalDetails] = useState([]);
  const [additionalDetails2, setAdditionalDetails2] = useState([]);
  const [allLogs, setAllLogs] = useState([]);
  const [selectedStage, setSelectedStage] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [stageData, setStageData] = useState([]);
  const [editLeadBoolean, setEditLeadBoolean] = useState(false);
  const [showStage, setShowStage] = useState(false);
  const [date, setDate] = useState(null);
  const [canUpdate, setCanUpdate] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [details, setDetails] = useState({});
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [filteredTags, setFilteredTags] = useState([]);
  const [tagSearch, setTagSearch] = useState("");
  const [presentTags, setPresentTags] = useState([]);
  const [addTags, setAddTags] = useState(false);
  const [refreshDetails, setRefreshDetails] = useState(true);
  const [tags, setTags] = useState([]);
  const [availTags, setAvailTags] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [updateDate, setUpdateDate] = useState(false);

  const [allTimelineData, setAllTimelineData] = useState();
  const getUserbyId = async (id) => {
    const res = await getReq(
      `${constants.CRM_API}/user/getUserById?userId=${localStorage.getItem("userId")}`
    );
    if (res && !res.error) {
      console.log(res?.data?.data, "res!")
      setEditLeadBoolean(res?.data?.data?.rolesAssigned?.crm?.edit);
    } else {
      console.log(res.error);
    }
  };
  useEffect(() => {
    getUserbyId();
  }, [])


  const getData = async () => {
    const response = await axios.get(
      `${constants.CRM_API}/user/get-pipeline?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    const data = response.data.data.stages;
    // console.log(data, "data!");
    setAllTimelineData(response?.data?.data);
    setStageData(data);
  };
  useEffect(() => {
    getData();
  }, []);

  const { id } = useParams();
  console.log(selectedStage, "cewcwecwe");
  const getAllData = () => {
    axios
      .get(`${constants.CRM_API}/api/get-lead-by-id?id=${id}`)
      .then((res) => {
        const sortedLogs = res.data.data.leadLogs.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        const data = res.data.data;
        setDetails(data);
        setLeadsArray(res.data.data);
        // setSelectedStage({ stageName: data?.leadStage ? data.leadStage.stageName : 'select stage',status:data?.leadStage ? data.leadStage.status: 'select status' });

        setAllLogs(sortedLogs);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAdditionalDetails = () => {
    axios
      .get(`${constants.CRM_API}/user/additional-details?leadId=${id}`)
      .then((res) => {
        setAdditionalDetails(res.data.data.additionalDetails);
        const spocArray = res.data.data.spoc;
      if (Array.isArray(spocArray) && spocArray.length > 0) {
        spocArray.shift(); // Removes the first element (0th index)
      }

      setAdditionalDetails2(spocArray); 
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getPipelineStage = (id) => {
    // console.log(id);
    for (let i of stageData) {
      if (i._id === id) {
        return i.stageName;
      }
    }
    return "-";
  };

  // save upadted data
  const updateStatusHandler = async (stage, statusid) => {
    try {
      console.log(selectedStage._id, statusid, selectedStage, "xqdwdxqw");
      const obj = {};
      obj["leadId"] = id;
      obj["leadUpdates"] = [{}];
      obj["assignedTo"] = details?.assignedTo;
      if (selectedStage) {
        obj["leadStage"] = selectedStage._id;
        obj["stageOld"] = details?.leadStage?.stageName;
        obj["stageNew"] = selectedStage.stageName;
      }
      if (selectedStatus) {
        obj["leadStatus"] = selectedStatus.id;
        obj["leadUpdates"][0]["callLeadStatus"] = selectedStatus.id;
      }
      if (date) {
        console.log(date, "date!");
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based in JavaScript
        const year = date.getFullYear();
        const dateStr = `${day}-${month}-${year}`;
        obj["leadUpdates"][0]["updateDate"] = dateStr;
      }
      console.log(obj, "obj!");
      await axios.post(`${constants.CRM_API}/user/newleadActions`, obj, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      getData();
      getAllData();
      // setSelectedStage(null);
      // setSelectedStatus(null);
      setDate();
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (selectedStage || selectedStatus) {
      setCanUpdate(true);
    }
  }, [selectedStage, selectedStatus]);

  useEffect(() => {
    if (showStage) {
      const getData = async () => {
        const response = await axios.get(
          `${constants.CRM_API}/user/get-pipeline?userId=${localStorage.getItem(
            "userId"
          )}`
        );
        const data = response.data.data.stages;
        // console.log(data, "data!");
        setStageData(data);
      };
      getData();
    }
  }, [showStage]);

  useEffect(() => {
    const fetchTags = async () => {
      if (addTags) {
        try {
          const response = await axios.get(
            `${constants.CRM_API
            }/user/get-lead-tag?userId=${localStorage.getItem("userId")}`
          );
          setTags(response.data.data);
        } catch (err) {
          console.log(err);
        }
      }
    };
    fetchTags();
  }, [addTags]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await axios.get(
          `${constants.CRM_API}/user/get-lead-tag?userId=${localStorage.getItem(
            "userId"
          )}`
        );
        setTags(response.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchTags();
    getData();
  }, []);

  useEffect(() => {
    setFilteredTags(
      availTags.filter((item) =>
        item.tagName.toLowerCase().includes(tagSearch.toLowerCase())
      )
    );
  }, [tagSearch, availTags]);

  const onTitleClick = () => {
    setIsActive(!isActive);
  };
const [activeOther,setActiveOther] = useState("false");
const onTitleClick2 = () => {
  setActiveOther(!activeOther);
};
  console.log(leadsArray);
  console.log(additionalDetails);
  console.log(allLogs);

  const createdAt = "2024-07-12T07:19:21.122Z";
  const createdAtDate = new Date(createdAt);
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const todayDateOnly = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const isToday = (date) => {
    const logDate = new Date(date);
    return (
      logDate.getDate() === today.getDate() &&
      logDate.getMonth() === today.getMonth() &&
      logDate.getFullYear() === today.getFullYear()
    );
  };

  const isYesterday = (date) => {
    const logDate = new Date(date);
    return (
      logDate.getDate() === yesterday.getDate() &&
      logDate.getMonth() === yesterday.getMonth() &&
      logDate.getFullYear() === yesterday.getFullYear()
    );
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatTime = (dateString) => {
    const options = { hour: "2-digit", minute: "2-digit" };
    return new Date(dateString).toLocaleTimeString(undefined, options);
  };

  const todayArray = allLogs.filter((log) => isToday(log.createdAt));
  console.log(todayArray);
  const yesterdayArray = allLogs.filter((log) => isYesterday(log.createdAt));
  console.log(yesterdayArray);
  const earlierArray = allLogs.filter(
    (log) => !isToday(log.createdAt) && !isYesterday(log.createdAt)
  );
  console.log(earlierArray);

  console.log(yesterdayArray.length);

  const changeStageHandler = (stage) => {
    console.log(stage, "dqwodx");
    setSelectedStage(stage);
    setUpdateStatus(true);
    // setShowStage(false);
    // updateStatusHandler(stage);
  };

  // toggle status selection
  const toggleStatusHandler = () => {
    setShowStatus((prev) => !prev);
  };

  // change the status
  const changeStatusHandler = (status) => {
    console.log(status, "xqhwq");
    setSelectedStatus(status);
    setUpdateStatus(true);
    // setShowStatus(false);
    // updateStatusHandler(status);
  };

  useEffect(() => {
    // console.log(selectedStage, selectedStatus, "edit!")
    if (updateStatus) {
      updateStatusHandler();
      setUpdateStatus(false);
    }
  }, [updateStatus])

  useEffect(() => {
    async function editClosure() {
      await postReq(constants.CRM_API + `/user/edit-lead?id=${id}&userId=${localStorage.getItem("userId")}`, {
        ...details,
        expClosureDate: date,
        // type:0
      });
    }
    if (updateDate) {
      editClosure();
      setUpdateDate(false);
    }
  }, [updateDate])

  const handleDateChange = (date) => {
    setStartDate(date);
    setDate(date);
    setUpdateDate(true);
    // updateStatusHandler();
    // setIsDatePickerOpen(false);
  };
  // toggle stage selection
  const toggleStageHandler = () => {
    setShowStage((prev) => !prev);
  };

  // close add tags
  const addTagsCloseHandler = () => {
    if (addTags) {
      setAddTags(false);
    }
  };

  useEffect(() => {
    if (refreshDetails) {
      fetchLead();
    }
  }, [id, tags, refreshDetails]);

  useEffect(() => {
    setRefreshDetails(true);
  }, [id, tags]);

  const groupLogsByDate = (logs) => {
    return logs.reduce((acc, log) => {
      const date = formatDate(log.createdAt);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(log);
      return acc;
    }, {});
  };
  const handleDateClick = () => {
    setIsDatePickerOpen(true);
  };

  const addTagHandler = (tagId) => {
    async function addTag() {
      try {
        const obj = { leadId: id, tagId };
        await axios.post(
          `${constants.CRM_API
          }/user/add-tag-to-lead?userId=${localStorage.getItem("userId")}`,
          obj
        );
        setRefreshDetails(true);
      } catch (err) {
        console.log(err);
      }
    }
    addTag();
  };

  // search for a tag
  const searchTagsHander = (e) => {
    const value = e.target.value;
    setTagSearch(value);
  };

  // remove tag for lead
  const removeTagHandler = (tagId) => {
    async function removeTag() {
      try {
        const obj = { leadId: id, tagId };
        await axios.put(
          `${constants.CRM_API
          }/user/remove-tag-from-lead?userId=${localStorage.getItem("userId")}`,
          obj
        );
        setRefreshDetails(true);
      } catch (err) {
        console.log(err);
      }
    }
    removeTag();
  };

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      onClick={onClick}
      ref={ref}
      value={value}
      className={style.date_input}
      readOnly
    />
  ));

  // get the lead data
  const fetchLead = async () => {
    const response = await axios.get(
      `${constants.CRM_API}/api/get-lead-by-id?id=${id}`
    );
    const data = response.data.data;
    console.log(data, "data!");
    setDetails(data);
    setSelectedStage(data?.leadStage);
    console.log(data?.leadStage?.stageName, data?.leadStatus);
    setSelectedStatus(
      getStatusObj(data?.leadStage?.stageName, data?.leadStatus)
    );
    data?.expClosureDate && setDate(new Date(data?.expClosureDate));
    data?.expClosureDate && setStartDate(new Date(data?.expClosureDate));
    data?.expClosureDate && setIsDatePickerOpen(true);
    const presentTags = [];
    for (let i of data?.leadTag) {
      presentTags.push(tags[tags.findIndex((item) => item._id === i)]);
    }
    // console.log(presentTags, "tags!");
    setPresentTags(presentTags);
    setRefreshDetails(false);
  };

  useEffect(() => {
    const availTags = [];
    for (let i of tags) {
      if (presentTags.indexOf(i) === -1) {
        availTags.push(i);
      }
    }
    setAvailTags(availTags);
  }, [tags, presentTags]);
  // can click inside add tags
  const propagationHandler = (e) => {
    e.stopPropagation();
  };

  const groupedLogs = groupLogsByDate(earlierArray);

  useEffect(() => {
    getAllData();
    getAdditionalDetails();
    fetchLead();
  }, []);

  // useEffect(() => {
  //   setSelectedStage(details?.leadStage);
  //   setSelectedStatus(
  //     getStatusObj(details?.leadStage?.stageName, details?.leadStatus)
  //   );
  // }, [details, stageData]);

  const getStatusObj = (stage, statusId) => {
    // console.log(stageData, stage, statusId);
    if (stageData.length > 0 && stage && statusId !== (undefined || null)) {
      const stage1 =
        stageData[stageData.findIndex((item) => item.stageName === stage)];
      const status =
        stage1.status[stage1.status.findIndex((item) => item.id === statusId)];
      // console.log(status, stage1, "sta!");
      return status;
    }
  };

  console.log(stageData);
  console.log(details);
  const [showCancel, setShowCancel] = useState(false);
  const [showEditLead, setShowEditLead] = useState(false);
  const closeCancelModal = () => {
    setShowCancel(false);
  };
  const hideEditLead = () => {
    setShowEditLead(false);
  };
  // delete a lead
  const deleteLeadHandler = async () => {
    try {
      await axios.post(
        `${constants.CRM_API
        }/user/edit-lead?id=${id}&userId=${localStorage.getItem("userId")}`,
        { isDeleted: true, deletedBy: localStorage.getItem("userId") }
      );
      const currentUrl = window.location.href;
      const leadFullDetailsPattern = /\/lead-full-details\/[^/]+/;

      if (leadFullDetailsPattern.test(currentUrl)) {
        window.location.href = currentUrl.replace(leadFullDetailsPattern, "/");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="d-flex flex-column" style={{ height: "" }}>
        <div className={styles.main_cont} style={{ zIndex: "1" }}>
          <TopHeaderCommon />
          <ProjectHeader />
          <EditLeadModal
            show={showEditLead}
            onHide={hideEditLead}
            details={details}
          />
          <ConfirmCancel
            show={showCancel}
            hide={closeCancelModal}
            name={"Lead"}
            action={deleteLeadHandler}
          />
        </div>
        <div
          className="container-box"
          style={{
            position: "relative",
            zIndex: "",
            display: "flex",
            marginTop: "6.2rem",
          }}
        >
          <SideProjectPanel />
          <div className={style.lead_details_left}>
            <div className={style.lead_details_heading}>
              <div onClick={() => { window.history.go(-1); }} style={{ cursor: "pointer" }}><img src={arrowBack} /></div>
              {leadsArray?.type == 1 ?
                <h5 className="leadKaname" title={leadsArray?.otherContactDetails[0]?.otherContactFirstName}>
                  {leadsArray?.otherContactDetails[0]?.otherContactFirstName} {leadsArray?.otherContactDetails[0]?.otherContactLastName}
                </h5>
                : <h5 className="leadKaname" title={leadsArray?.name}>{leadsArray?.name}</h5>
              }
              {editLeadBoolean ?
                <Dropdown>
                  <Dropdown.Toggle
                    className={style.lead_details_heading_drop}
                    variant="success"
                    id="dropdown-basic"
                  >
                    <BsThreeDots style={{ color: "black" }} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        color: "#363535",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "normal",
                        letterSpacing: "0.144px",
                      }}
                      onClick={() => setShowEditLead(true)}
                    >
                      <PencilSimple /> Edit Lead
                    </Dropdown.Item>
                    <Dropdown.Item
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        color: "#363535",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "normal",
                        letterSpacing: "0.144px",
                      }}
                      onClick={() =>
                        window.location.assign(
                          `${constants.BASE_URL}/pmt/?leadId=${id}`
                        )
                      }
                    >
                      {" "}
                      <FolderNotchPlus />
                      Convert to project
                    </Dropdown.Item>
                    <Dropdown.Item
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        color: "#363535",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "normal",
                        letterSpacing: "0.144px",
                      }}
                      onClick={() => setShowCancel(true)}
                    >
                      <Trash /> Delete Lead
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                :
                <Dropdown>
                  <Dropdown.Toggle
                    className={style.lead_details_heading_drop}
                    variant="success"
                    id="dropdown-basic"
                    onClick={() => toast.error("Access disabled by Admin")}
                  >
                    <BsThreeDots style={{ color: "black" }} />
                  </Dropdown.Toggle>
                </Dropdown>
              }
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "1rem 0rem",
              }}
            >
              <div
                className={style.leadDetails_stage}
                style={{ marginRight: "77px" }}
              >
                Stage
              </div>
              {editLeadBoolean ?
                <Dropdown
                  className={style.offcanvasStage}
                  show={showStage}
                  onToggle={toggleStageHandler}
                >
                  <Dropdown.Toggle
                    className={style.offcanvasSelectStage}
                  >
                    <div
                      style={{
                        color: "#212121",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "normal",
                      }}
                    >
                      {selectedStage ? selectedStage?.stageName : "Select Stage"}
                    </div>
                    <PiCaretDown className={style.offcanvasCaretDown} />
                  </Dropdown.Toggle>
                  {showStage && (
                    <Dropdown.Menu className={style.offcanvasStageDropdown}>
                      {stageData.map((item) => (
                        <Dropdown.Item
                          key={item._id}
                          className={style.offcanvasStageOption}
                          onClick={changeStageHandler.bind(null, item)}
                        >
                          {item.stageName}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  )}
                </Dropdown>
                :
                <Dropdown
                  className={style.offcanvasStage}
                  show={showStage}
                >
                  <Dropdown.Toggle
                    className={style.offcanvasSelectStage}
                    onClick={() => toast.error("Access disabled by Admin")}
                  >
                    <div
                      style={{
                        color: "#212121",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "normal",
                      }}
                    >
                      {selectedStage ? selectedStage?.stageName : "Select Stage"}
                    </div>
                    <PiCaretDown className={style.offcanvasCaretDown} />
                  </Dropdown.Toggle>
                </Dropdown>
              }
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "1rem 0rem",
              }}
            >
              <div
                className={style.leadDetails_status}
                style={{ marginRight: "74px" }}
              >
                Status
              </div>
              {editLeadBoolean ?
                <Dropdown
                  className={style.offcanvasStatus}
                  show={showStatus}
                  onToggle={toggleStatusHandler}
                >
                  <Dropdown.Toggle
                    className={style.offcanvasSelectStatus}
                  >
                    <div className={style.offcanvasSelectedStatus}>
                      {selectedStatus
                        ? selectedStatus?.statusName
                        : selectedStage
                          ? selectedStage?.status[0]?.statusName
                          : "Select Status"}
                    </div>
                    <PiCaretDown className={style.offcanvasCaretDown} />
                  </Dropdown.Toggle>
                  {showStatus && (
                    <Dropdown.Menu className={style.offcanvasStatusDropdown}>
                      {selectedStage &&
                        selectedStage.status.map((item) => (
                          <Dropdown.Item
                            key={item.id}
                            onClick={changeStatusHandler.bind(null, item)}
                            className={style.offcanvasStatusOption}
                          >
                            {item.statusName}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  )}
                </Dropdown>
                :
                <Dropdown
                  className={style.offcanvasStatus}
                  show={showStatus}
                  onClick={() => toast.error("Access disabled by Admin")}
                >
                  <Dropdown.Toggle
                    className={style.offcanvasSelectStatus}
                  >
                    <div className={style.offcanvasSelectedStatus}>
                      {selectedStatus
                        ? selectedStatus?.statusName
                        : selectedStage
                          ? selectedStage?.status[0]?.statusName
                          : "Select Status"}
                    </div>
                    <PiCaretDown className={style.offcanvasCaretDown} />
                  </Dropdown.Toggle>
                </Dropdown>
              }
            </div>
            <div className={style.lead_details_closure}>
              <div>Expected Closure</div>
              {!isDatePickerOpen && (
                <div
                  className={style.lead_details_closure_div}
                  onClick={() => {
                    if (editLeadBoolean) {
                      handleDateClick();
                    } else {
                      toast.error("Access disabled by Admin");
                    }
                  }}
                >
                  <div>Select Date</div>
                  <PiCalendarBlank />
                </div>
              )}
              {(isDatePickerOpen || startDate) && (
                <DatePicker
                  selected={startDate}
                  onChange={(newDate) => {
                    if (editLeadBoolean) {
                      handleDateChange(newDate);
                    } else {
                      toast.error("Access disabled by Admin");
                    }
                  }}
                  customInput={
                    <CustomInput
                      onClick={() => {
                        if (editLeadBoolean) {
                          handleDateClick();
                        } else {
                          toast.error("Access disabled by Admin");
                        }
                      }}
                      value={startDate ? startDate.toLocaleDateString() : ""}
                    />
                  }
                  onClickOutside={() => setIsDatePickerOpen(false)}
                />
              )}

            </div>
            <div className={style.lead_details}>Lead Details</div>
            <hr />
            <div>
              <div style={{ display: "flex", gap: "12px" }}>
                {presentTags &&
                  presentTags.map((item) => (
                    <div
                      style={{
                        backgroundColor: `${item?.tagColorCode}20`,
                        color: `${item?.tagColorCode}`,
                        width: "fit-content",
                        padding: "6px 10px",
                        fontWeight: "500",
                        fontSize: "12px",
                        borderRadius: "4px",
                      }}
                    >
                      {item?.tagName}
                    </div>
                  ))}
                <button
                  style={{
                    padding: "6px 10px",
                    backgroundColor: "#F5FAFF",
                    display: "flex",
                    borderRadius: "4px",
                    gap: "8px",
                    color: "#0084EF",
                    fontWeight: "500",
                    fontSize: "12px",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "none",
                  }}
                  onClick={() => { editLeadBoolean ? setAddTags(!addTags) : toast.error("Access disabled by Admin") }}
                >
                  <PiTag />
                  Add Tag
                </button>
              </div>
              {addTags && (
                <div
                  onClick={propagationHandler}
                  style={{
                    position: "fixed",
                    backgroundColor: "white",
                    width: "15%",
                    padding: "15px",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 2px 7px",
                    zIndex: "50",
                  }}
                >
                  {filteredTags?.length > 0 && <div
                    style={{
                      color: "#212121",
                      fontWeight: "500",
                      fontSize: "12px",
                    }}
                  >
                    Tags
                  </div>}
                  {filteredTags?.length > 0 &&
                    <div className={style.offcanvasPrevTags}>
                      {presentTags.map((item) => (
                        <div
                          style={{
                            backgroundColor: `${item?.tagColorCode}20`,
                            color: `${item?.tagColorCode}`,
                            width: "fit-content",
                            textWrap: "nowrap",
                            padding: "3px 3px 3px 3px",
                            fontWeight: "500",
                            fontSize: "12px",
                            borderRadius: "4px",
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          {item?.tagName}
                          <button
                            onClick={removeTagHandler.bind(null, item?._id)}
                            style={{
                              border: "none",
                              backgroundColor: `${item?.tagColorCode}10`,
                              color: `${item?.tagColorCode}`,
                            }}
                          >
                            <PiX />
                          </button>
                        </div>
                      ))}
                      <input
                        className={style.offcanvasSearchTag}
                        type="text"
                        // placeholder="Search tags"
                        onChange={searchTagsHander}
                        value={tagSearch}
                      />
                    </div>}
                  {filteredTags?.length > 0 ? <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    {filteredTags &&
                      filteredTags.map((item) => (
                        <div
                          style={{
                            backgroundColor: `${item?.tagColorCode}20`,
                            color: `${item?.tagColorCode}`,
                            width: "fit-content",
                            padding: "6px 10px",
                            fontWeight: "500",
                            fontSize: "12px",
                            borderRadius: "4px",
                            cursor: "pointer",
                          }}
                          onClick={addTagHandler.bind(null, item._id)}
                        >
                          {item.tagName}
                        </div>
                      ))}
                  </div>
                    : <div>No Tags created yet!</div>}
                </div>
              )}
            </div>
            <div className={style.lead_contact}>

              {((leadsArray && leadsArray?.phoneNumber) ||
                (leadsArray?.otherContactDetails && leadsArray.otherContactDetails.length > 0 && leadsArray.otherContactDetails[0]?.otherContactPhoneNumber?.length > 0)) && (
                  <div>
                    <div className={style.lead_contact_icon_div}>
                      <PiPhoneCall style={{ color: "#0084EF" }} />
                    </div>
                    {leadsArray?.type == 1 ? (
                      <div>{leadsArray?.otherContactDetails[0]?.otherContactPhoneNumber[0]}</div>
                    ) : (
                      <div>{leadsArray?.phoneNumber}</div>
                    )}
                  </div>
                )}

              {((leadsArray && leadsArray?.email) ||
                (leadsArray?.otherContactDetails && leadsArray.otherContactDetails.length > 0 && leadsArray.otherContactDetails[0]?.otherContactEmailId)) && (
                  <div>
                    <div className={style.lead_contact_icon_div}>
                      <PiEnvelopeSimple style={{ color: "#0084EF" }} />
                    </div>
                    {leadsArray?.type == 1 ? (
                      <div>{leadsArray?.otherContactDetails[0]?.otherContactEmailId}</div>
                    ) : (
                      <div>{leadsArray?.email}</div>
                    )}
                  </div>
                )}

              {leadsArray.address && (
                <div>
                  <div className={style.lead_contact_icon_div}>
                    <PiMapPin style={{ color: "#0084EF" }} />
                  </div>
                  <div>{leadsArray.address}</div>
                </div>
              )}
            </div>
            <div
              style={{
                margin: "1.5rem 0rem",
                borderRadius: "8px",
                boxShadow: "0px 4px 12px 0px rgba(137, 137, 137, 0.25)",
              }}
            >
              <div className="accordion-item">
                <div
                  className={`accordion-header ${isActive ? "active" : ""}`}
                  onClick={onTitleClick}
                >
                  <span>Additional Details</span>
                  <div
                    className={`accordion-arrow ${isActive ? "active" : ""}`}
                  >
                    <PiCaretDown />
                  </div>
                </div>
                <div
                  className={`accordion-body ${isActive ? "active" : ""}`}
                  style={{
                    padding: "0.5rem",
                    display: isActive ? "block" : "none",
                  }}
                >
                  {additionalDetails?.length > 0 && (
                    <div>
                      {additionalDetails.map((item, index) => (
                        <div key={index}>
                          <>
                            {item.budget && (
                              <div className={style.additionalDetails_div}>
                                <div
                                  className={style.additionalDetails_paraKey}
                                >
                                  Budget
                                </div>{" "}
                                <div
                                  className={style.additionalDetails_paraValue}
                                >
                                  {item.budget}
                                </div>
                              </div>
                            )}
                            {item.area && (
                              <div className={style.additionalDetails_div}>
                                <div
                                  className={style.additionalDetails_paraKey}
                                >
                                  Area
                                </div>{" "}
                                <div
                                  className={style.additionalDetails_paraValue}
                                >
                                  {item.area}
                                </div>
                              </div>
                            )}
                            {item.workScope && (
                              <div className={style.additionalDetails_div}>
                                <div
                                  className={style.additionalDetails_paraKey}
                                >
                                  Work Scope
                                </div>{" "}
                                <div
                                  className={style.additionalDetails_paraValue}
                                >
                                  {item.workScope}
                                </div>
                              </div>
                            )}
                            {item.sourceName && (
                              <div className={style.additionalDetails_div}>
                                <div
                                  className={style.additionalDetails_paraKey}
                                >
                                  Source Name
                                </div>{" "}
                                <div
                                  className={style.additionalDetails_paraValue}
                                >
                                  {item.sourceName}
                                </div>
                              </div>
                            )}
                            {item.leadSource && (
                              <div className={style.additionalDetails_div}>
                                <div
                                  className={style.additionalDetails_paraKey}
                                >
                                  Lead Source
                                </div>{" "}
                                <div
                                  className={style.additionalDetails_paraValue}
                                >
                                  {item.leadSource}
                                </div>
                              </div>
                            )}
                            {item.assignedTo && (
                              <div className={style.additionalDetails_div}>
                                <div
                                  className={style.additionalDetails_paraKey}
                                >
                                  Assigned To
                                </div>{" "}
                                <div
                                  className={style.additionalDetails_paraValue}
                                >
                                  {item.assignedTo}
                                </div>
                              </div>
                            )}
                            {item.castCall && (
                              <div className={style.additionalDetails_div}>
                                <div
                                  className={style.additionalDetails_paraKey}
                                >
                                  Last Call
                                </div>{" "}
                                <div
                                  className={style.additionalDetails_paraValue}
                                >
                                  {item.lastCall}
                                </div>
                              </div>
                            )}
                            {item.callOutcome && (
                              <div className={style.additionalDetails_div}>
                                <div
                                  className={style.additionalDetails_paraKey}
                                >
                                  Call Outcome
                                </div>{" "}
                                <div
                                  className={style.additionalDetails_paraValue}
                                >
                                  {item.callOutcome}
                                </div>
                              </div>
                            )}
                          </>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div  
            style={{
                margin: "1.5rem 0rem",
                borderRadius: "8px",
                boxShadow: "0px 4px 12px 0px rgba(137, 137, 137, 0.25)",
              }}
            >
            <div className="accordion-item">
                <div
                  className={`accordion-header ${activeOther ? "active" : ""}`}
                  onClick={onTitleClick2}
                >
                  <span>Other Contact details</span>
                  <div
                    className={`accordion-arrow ${activeOther ? "active" : ""}`}
                  >
                    <PiCaretDown />
                  </div>
                </div>
                <div
                  className={`accordion-body ${activeOther ? "active" : ""}`}
                  style={{
                    padding: "0.5rem",
                    display: activeOther ? "block" : "none",
                  }}
                >
                  {additionalDetails2?.length > 1 && (
                    <div>
                      {additionalDetails2.map((item, index) => (
                        <div key={index}>
                          {console.log(item,"dfghjk")}
                          <>
                            <div className={style.additionalDetails_div}>
                                <div
                                  className={style.additionalDetails_paraKey}
                                >
                                 Contact Type
                                </div>{" "}
                                <div
                                  className={style.additionalDetails_paraValue}
                                >
                                  {item.otherContactType ? item.otherContactType :"-"}
                                </div>
                              </div>
                            <div className={style.additionalDetails_div}>
                                <div
                                  className={style.additionalDetails_paraKey}
                                >
                                  Name
                                </div>{" "}
                                <div
                                  className={style.additionalDetails_paraValue}
                                >
                                  {item.otherContactFirstName} {item?.otherContactLastName}
                                </div>
                              </div>
                              <div className={style.additionalDetails_div}>
                                <div
                                  className={style.additionalDetails_paraKey}
                                >
                                 Designation
                                </div>{" "}
                                <div
                                  className={style.additionalDetails_paraValue}
                                >
                                  {item.otherContactDesginationName ? item?.otherContactDesginationName :"-"}
                                </div>
                              </div>
                              <div className={style.additionalDetails_div}>
                                <div
                                  className={style.additionalDetails_paraKey}
                                >
                                 Phone number
                                </div>{" "}
                                <div
                                  className={style.additionalDetails_paraValue}
                                >
                                  {item.otherContactPhoneNumber[0] ? item.otherContactPhoneNumber[0] :"-" }
                                </div>
                              </div>
                              <div className={style.additionalDetails_div}>
                                <div
                                  className={style.additionalDetails_paraKey}
                                >
                                 Email address
                                </div>{" "}
                                <div
                                  className={style.additionalDetails_paraValue}
                                >
                                  {item.otherContactEmailId ? item?.otherContactEmailId : "-"}
                                </div>
                              </div>
                          </>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className={style.remark_div}>
              <h6>Remarks</h6>
              <div>
    {leadsArray?.leadUpdates?.[leadsArray.leadUpdates.length - 1]?.text || "No remarks available"}
  </div>
            </div>
          </div>
          <div className={style.lead_details_right}>
            {console.log(leadsArray, "ceww")}
            <div className={style.lead_details_pipeline_div}>
              <div>
                Pipeline Name:{" "}
                <span style={{ color: "#191D23", fontWeight: "600" }}>
                  {allTimelineData?.name}
                </span>
              </div>
              <div>
                Stage:{" "}
                <span style={{ color: "#191D23", fontWeight: "600" }}>
                  {allTimelineData?.leadStage
                    ? allTimelineData?.leadStage
                    : "-"}
                </span>
              </div>
            </div>
            <div className={style.lead_details_log_history}>Log History</div>
            {allLogs.length > 0 ? (
              <div style={{ height: "27rem", overflow: "scroll" }}>
                {todayArray.length > 0 && (
                  <div>
                    <div
                      className={style.lead_details_today}
                      style={{ paddingTop: "10px", paddingBottom: "20px" }}
                    >
                      <div className={style.today_line}></div>
                      <div className={style.today_text}>Today</div>
                      <div className={style.today_line}></div>
                    </div>
                    <div style={{
                      // overflow:"scroll",
                      // height:"22rem"
                      // margin: "1rem 0rem"

                    }}>
                      {todayArray.map((item, index) => (
                        <div key={index} className={style.data_div}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <div className={style.date_time}>
                              <div>{formatDate(item.createdAt)}</div>
                              <div>{formatTime(item.createdAt)}</div>
                            </div>
                            <div style={{ display: "flex", gap: "4px" }}>
                              <div className={style.author}>
                                {item?.assignedTo?.fullName ? item?.assignedTo?.fullName : item?.updatedBy}
                              </div>
                              <div className={style.message}>
                                : {item.logText?.length !== 0 ? item.logText : item?.leadUpdates[0]?.text ? item?.leadUpdates[0]?.text : ""}
                              </div>
                              {item.isProjectCreated && (
                                <div className={style.amount}>
                                  ₹{item.amount}
                                </div>
                              )}
                            </div>
                            {/* <div className={style.status}>Status : {item.status}</div> */}
                          </div>
                          {/* <div className={style.view_details}>View Details</div> */}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {yesterdayArray.length > 0 && (
                  <div>
                    <div className={style.lead_details_today}>
                      <div className={style.today_line}></div>
                      <div className={style.today_text}>Yesterday</div>
                      <div className={style.today_line}></div>
                    </div>
                    <div style={{ margin: "1rem 0rem" }}>
                      {yesterdayArray.map((item, index) => (
                        <div key={index} className={style.data_div}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <div className={style.date_time}>
                              <div>{formatDate(item.createdAt)}</div>
                              <div>{formatTime(item.createdAt)}</div>
                            </div>
                            <div style={{ display: "flex", gap: "4px" }}>
                              <div className={style.author}>
                                {item.assignedTo?.fullName}
                              </div>
                              <div className={style.message}>
                                : {item.logText}
                              </div>
                              {item.isProjectCreated && (
                                <div className={style.amount}>
                                  ₹{item.amount}
                                </div>
                              )}
                            </div>
                            {/* <div className={style.status}>Status : {item.status}</div> */}
                          </div>
                          {/* <div className={style.view_details}>View Details</div> */}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {Object.keys(groupedLogs).map((date) => (
                  <div key={date}>
                    <div className={style.lead_details_today}>
                      <div className={style.today_line}></div>
                      <div className={style.today_text}>{date}</div>
                      <div className={style.today_line}></div>
                    </div>
                    <div style={{ margin: "1rem 0rem" }}>
                      {groupedLogs[date].map((item, index) => (
                        <div key={index} className={style.data_div}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <div className={style.date_time}>
                              <div>{formatDate(item.createdAt)}</div>
                              <div>{formatTime(item.createdAt)}</div>
                            </div>
                            <div style={{ display: "flex", gap: "4px" }}>
                              <div className={style.author}>
                                {item.assignedTo?.fullName}
                              </div>
                              <div className={style.message}>
                                : {item.logText}
                              </div>
                              {item.isProjectCreated && (
                                <div className={style.amount}>
                                  ₹{item.amount}
                                </div>
                              )}
                            </div>
                            {/* <div className={style.status}>Status : {item.status}</div> */}
                          </div>
                          {/* <div className={style.view_details}>View Details</div> */}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={style.zeroState}>
                <ZeroState />
                <p>No logs to show</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadDetails;
