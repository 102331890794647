import React from "react";
import { Modal } from "react-bootstrap";
import styles from "./modals.module.css";

const ConfirmDelete = ({ show, hide, name, action }) => {
  return (
    <Modal show={show} onHide={hide} centered>
      <Modal.Header className={styles.deleteHeader}>Delete {name}</Modal.Header>
      <Modal.Body className={styles.deleteBody}>
        Are you sure you want to delete the {name}?
      </Modal.Body>
      <div className={styles.deleteFooter} style={{marginBottom:"25px",marginRight:"20px"}}>
        <div className={styles.deleteCancel} style={{cursor:"pointer"}} onClick={hide}>
          Cancel
        </div>
        <div
         style={{cursor:"pointer"}} 
          className={styles.deleteDelete}
          onClick={() => {
            action();
            hide();
          }}
        >
          Delete
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDelete;
