import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const LeadManagementSettingsSidebar = () => {
  const [url, setUrl] = useState("");
  const location = useLocation();

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  return (
    <div
      style={{
        height: "100%",
        width: "15%",
        position: "fixed",
        display: "flex",
        gap: "6px",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        border: "1px solid #EEEEEF",
        paddingTop: "1.5rem",
      }}
    >
      <Link
        to="/leads/lead-management-settings/custom-field"
        style={{
          width: "75%",
          padding: "12px 16px",
          backgroundColor:
            url.includes("/leads/lead-management-settings") &&
            (url.includes("custom-field") ||
              url.includes("create-new-field") ||
              url.includes("edit-field"))
              ? "#E5F4FF"
              : "#FAFAFA",
          fontWeight: "600",
          fontSize: "12px",
          color:
            url.includes("/leads/lead-management-settings") &&
            (url.includes("custom-field") ||
              url.includes("create-new-field") ||
              url.includes("edit-field"))
              ? "#0084EF"
              : "#454545",
        }}
      >
        Custom Field
      </Link>
      <Link
        to="/leads/lead-management-settings/status"
        style={{
          width: "75%",
          padding: "12px 16px",
          backgroundColor:
            url.includes("/leads/lead-management-settings") &&
            url.includes("status")
              ? "#E5F4FF"
              : "#FAFAFA",
          fontWeight: "600",
          fontSize: "12px",
          color:
            url.includes("/leads/lead-management-settings") &&
            url.includes("status")
              ? "#0084EF"
              : "#454545",
        }}
      >
        Status
      </Link>
      <Link
        to="/leads/lead-management-settings/tags"
        style={{
          width: "75%",
          padding: "12px 16px",
          backgroundColor:
            url.includes("/leads/lead-management-settings") &&
            url.includes("tags")
              ? "#E5F4FF"
              : "#FAFAFA",
          fontWeight: "600",
          fontSize: "12px",
          color:
            url.includes("/leads/lead-management-settings") &&
            url.includes("tags")
              ? "#0084EF"
              : "#454545",
        }}
      >
        Tags
      </Link>
      <Link
        to="/leads/lead-management-settings/teams"
        style={{
          width: "75%",
          padding: "12px 16px",
          backgroundColor:
            url.includes("/leads/lead-management-settings") &&
            url.includes("teams")
              ? "#E5F4FF"
              : "#FAFAFA",
          fontWeight: "600",
          fontSize: "12px",
          color:
            url.includes("/leads/lead-management-settings") &&
            url.includes("teams")
              ? "#0084EF"
              : "#454545",
        }}
      >
        Teams
      </Link>
    </div>
  );
};

export default LeadManagementSettingsSidebar;
