import React, { useMemo, useRef, useCallback } from "react";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Autocomplete,
  FormHelperText,
  OutlinedInput,
  Select,
  TextField,

} from "@mui/material";


import { Button, Modal, Spinner, Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  setAddLeadModalOpen,
  newLeadObj,
} from "../../Redux/Slices/newLeadSlice";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { BiChevronDown } from "react-icons/bi";
import "react-phone-input-2/lib/material.css";
import plusBluehover from "../../Assets/plusBluehover.svg";
import PlusBlue from "../../Assets/PlusBlue.svg";
import Trash from "../../Assets/Trash.svg"
import styles from "./AddLeadModalNew.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import expf, { constants } from "../../piservices/constants";
import { useEffect } from "react";
import { getReq, postReq } from "../../piservices/apis";
import toast from "react-hot-toast";
import MuiPhoneNumber from "material-ui-phone-number";
import { notification } from "antd";
import PhoneInput from "react-phone-input-2";

import useApiFetchCustomHook from "../../piservices/ApiFetchCustomHook";
import useApiFetchForLeadCountCustomHook from "../../piservices/ApiFetchForLeadCount";
import useApiFetchCustomHookForEmailService from "../../piservices/ApiFetchCustomHookForEmailService";
import { countryFlags } from "../../Pages/MockDataCountries";
import axios from "axios";
import { useCountries } from 'use-react-countries'
import countrydata from "country-codes-list"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";



function AddLeadModalNew({ setProgress }) {
  //  get  code data for state 
  const { countries } = useCountries()

  const countrycode = countries.map((item) => {

    return item.countryCallingCode
  })

  const profileData = useSelector((state) => state.lmsSlice.profileData);
  const haveAssigneToAccess = useSelector(
    (state) => state.notificationSlice.allAssignToAccessUser
  );
  const addLeadModalOpen = useSelector(
    (state) => state.newLeadSlice.addLeadModalOpen
  );
  const importedDataFromFile = useSelector(
    (state) => state.newLeadSlice.fileImportedData
  );
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedTeamObject, setSelectedTeamObject] = useState(null);

  let { tab } = useParams();

  const uploadImportedData = () => {
    importedDataFromFile?.map((item) => { });
  };

  const { apiFetch } = useApiFetchCustomHook();
  const { getLeadsCount } = useApiFetchForLeadCountCustomHook();

  useEffect(() => {
    if (importedDataFromFile?.length > 0) {
    }
  }, [importedDataFromFile]);
  const dispatch = useDispatch();
  const [leadFirstName, setLeadFirstName] = useState("");
  const [leadLastName, setLeadLastName] = useState("");
  const [leadCompanyName, setLeadCompanyName] = useState("");
  const [phoneNumber, setPhonenumber] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [data, setData] = useState({
    uniqueFields: [],
    primaryContact: [],
  });
  const [teamAssignList, setTeamAssignList] = useState([
    { selectedTeam: '', assignedToName: '', assignedToObject: {} }
  ]);
  const [storeTeams, setStoreTeams] = useState();

  const [phoneError, setPhoneError] = useState(false);
  const [option, setOption] = useState("Individual");
  const [phoneCode, setPhoneCode] = useState("");
  const [errorPin, setErrorPin] = useState(false);
  const [leadEmail, setLeadEmail] = useState("");
  const [leadCity, setLeadCity] = useState("");
  const [leadArea, setLeadArea] = useState("");
  const [leadAddress, setLeadAddress] = useState("");
  const [workScope, setWorkScope] = useState("");
  const [leadCityCode, setLeadCityCode] = useState("");
  const [userData, setUserData] = useState({});
  const [leadSources, setLeadSources] = useState([]);
  const [corpData, setCorpData] = useState({});
  const textfieldClass = {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0, // Optional: Removes margin around the spin buttons
      },
      '&[type=number]': {
        MozAppearance: 'textfield', // Firefox
      },
    },
  };

  const [leadWorkTypes, setLeadWorkTypes] = useState([]);
  const [sourceNames, setSourceNames] = useState([]);
  const [leadSource, setLeadSource] = useState([]);
  const [leadWorkType, setLeadWorkType] = useState([]);
  const [sourceName, setSourceName] = useState([]);
  const [selectedLeadSource, setSelectedLeadSource] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [assignedToName, setAssignedToName] = useState(storeTeams?.length <= 0 && "UNASSIGNED");
  const [areaUnit, setAreaUnit] = useState("sqft");
  const [assignedToObject, setAssignedToObject] = useState("");
  const [selectedSourceName, setSelectedSourceName] = useState("");
  const [addNewWorkScope, setAddNewWorkType] = useState("");
  const [addNewLeadSource, setAddLeadNewSource] = useState("");
  const [companyNameError, setCompanyNameError] = useState(false);
  const [corporateFieldsCluster, setCorporateFieldsCluster] = useState([]);
  const [addNewSourceName, setAddNewSourceName] = useState("");
  // const [addNewWorkScopeName, setAddNewWorkScopeName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [leadSourceClicked, setLeadSourceClicked] = useState(false);
  const [sourceInputName, setSourceInputName] = useState("");
  const [workScopeInput, setWorkScopeInput] = useState("");
  const [leadSourceInput, setLeadSourceInput] = useState("");
  const [filteredSourceName, setFilteredSourceName] = useState([]);
  const [filteredWorkScopeName, setFilteredWorkScopeName] = useState([]);
  const [filteredLeadSourceName, setFilteredLeadSourceName] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const [dropdownOpen3, setDropdownOpen3] = useState(false);
  // State for checking whether user has access to edit lead or not :
  const [editLeadBoolean, setEditLeadBoolean] = useState(false);
  const [loggedInUserName, setLoggedInUserName] = useState(true);

  // console.log("True/False", editLeadBoolean);
  const [sourceNameArchitectDetails, setSourceNameArchitectDetails] = useState(
    {}
  );
  const [inputValueWorkScope, setInputValueWorkScope] = useState("");
  const [inputValueLeadSource, setInputValueLeadSource] = useState("");
  const [inputValueScopeName, setInputValueScopeName] = useState("");

  // const classes = useStyles();
  const [selectedCountryCode, setSelectedCountryCode] = useState("1");
  const [countryCodeInput, setCountryCodeInput] = useState("+1");
  const [phoneNumberInput, setPhoneNumberInput] = useState("");
  const handleCountryCodeChange = (e) => {
    const countryCode = e.target.value;
    setSelectedCountryCode(countryCode);
    setCountryCodeInput("+" + countryCode);
  };

  const handleWorkScopeInputChange = (event, newInputValue) => {
    setInputValueWorkScope(newInputValue);
    setAddNewWorkType(newInputValue);
  };
  const handleScopeNameInputChange = (event, newInputValue) => {
    setInputValueScopeName(newInputValue);
    setAddNewSourceName(newInputValue);
  };
  const handleInputValueInputChange = (event, newInputValue) => {
    setInputValueLeadSource(newInputValue);
    setAddLeadNewSource(newInputValue);
  };

  const { sendEmailPushBellNotifications } =
    useApiFetchCustomHookForEmailService();
  const [buttonClickedOnce, setButtonClickedOnce] = useState(false);
  const saveLeadSource = async () => {
    if (addNewLeadSource != "" && editLeadBoolean) {
      const res = await postReq(`${constants.CRM_API}/user/save-lead-source`, {
        userId: localStorage.getItem("userId"),
        leadSource: addNewLeadSource,
      });

      if (res && !res.error) {
        getLeadSource();
        setAddLeadNewSource("");
        toast.success("New Lead Source Added");
      } else {
        console.log(res.error);
      }
    }
  };
  const handleDropdownFocus = () => {
    setTimeout(() => {
      setDropdownOpen(true);
    }, 100);
  };

  const handleDropdownBlur = () => {
    setTimeout(() => {
      setDropdownOpen(false);
    }, 100);
  };
  const handleDropdownFocus2 = () => {
    setTimeout(() => {
      setDropdownOpen2(true);
    }, 100);
  };

  const handleDropdownBlur2 = () => {
    setTimeout(() => {
      setDropdownOpen2(false);
    }, 100);
  };
  const handleDropdownFocus3 = () => {
    setTimeout(() => {
      setDropdownOpen3(true);
    }, 100);
  };

  const handleDropdownBlur3 = () => {
    setTimeout(() => {
      setDropdownOpen3(false);
    }, 100);
  };

  const saveworkScope = async () => {
    if (addNewWorkScope != "") {
      const res = await postReq(`${constants.CRM_API}/user/save-work-type`, {
        userId: localStorage.getItem("userId"),
        type: inputValueWorkScope,
      });

      if (res && !res.error) {
        getWorkTypes();
        setAddNewWorkType("");
        toast.success("New Work Scope Added");
      } else {
        console.log(res.error);
      }
    }
  };

  const savesourceName = async () => {
    // alert("async call");
    console.log("NewSourceName", addNewSourceName);
    if (addNewSourceName != "" && editLeadBoolean) {
      const res = await postReq(`${constants.CRM_API}/user/save-source-name`, {
        userId: localStorage.getItem("userId"),
        sourceName: addNewSourceName,
      });

      if (res && !res.error) {
        // console.log('getting lead source', res.data.data)
        getSourceName();
        setAddNewSourceName("");
        toast.success("New Source Name Added");
      } else {
        console.log(res.error);
      }
    }
  };

  // console.log("SourceNames : ", sourceNames);

  const filterSourceChange = (e) => {
    setSourceInputName(e.target.value);
    // var val = e.target.value;
    const filteredNames = sourceNames.filter((name) =>
      name.sourceName.toLowerCase().includes(sourceInputName.toLowerCase())
    );
    setFilteredSourceName(filteredNames);
    // setFilteredSourceName(sourceName.filter)

    // Aman
  };
  const filterWorkScopeChange = (e) => {
    setWorkScopeInput(e.target.value);

    const filteredNames = leadWorkTypes.filter((name) =>
      name.type.toLowerCase().includes(workScopeInput.toLowerCase())
    );
    // aman
    setFilteredWorkScopeName(filteredNames);
  };
  const filterLeadSourceChange = (e) => {
    setLeadSourceInput(e.target.value);

    const filteredNames = leadSources.filter((name) =>
      name.leadSource.toLowerCase().includes(leadSourceInput.toLowerCase())
    );
    // aman
    setFilteredLeadSourceName(filteredNames);
  };

  const defaultValue = {
    label: storeTeams?.length <= 0 ? "UNASSIGNED":"",
    _id: "",
    email: "",
  };


  const [formData, setFormData] = useState({});
  const [teamLeadArr, setTeamLeadArr] = useState([]);
  const [budget, setBudget] = useState();
  const [phoneNumerror, setphoneNumerror] = useState({
    bol: false,
    msg: ""
  })
  const projectObj = useSelector((state) => state.newLeadObj);
  const tempObj = {
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    pinCode: "",
    phoneNumber: "",
    budget: "",
    requirements: "",
    leadSource: "",
    sourceName: "",
    assignedTo: "",
    area: "",
  };
  const exceptThisSymbols2 = [
    "~",
    "`",
    "!",
    "#",
    "%",
    "$",
    "^",
    "&",
    "*",
    "(",
    " ",
    ")",
    "+",
    "=",
    "[",
    "_",
    "]",
    "'",
    ";",
    "/",
    "{",
    "}",
    "|",
    ,
    "<",
    ">",
    "?",
    ",",
  ];

  const leadFirstNameHandle = (e) => {
    let value = e.target.value;
    if (value.length <= 24) {
      // Capitalize the first letter
      value = value.charAt(0).toUpperCase() + value.slice(1);
      if (/^[a-zA-Z\s]*$/.test(value)) {
        setLeadFirstName(value);
        setFirstNameError(false);
      }
    } else {
      setFirstNameError(true);
    }
  };

  const leadCityHandle = (e) => {
    let value = e.target.value;
    if (value.length <= 23) {
      // Capitalize the first letter
      value = value.charAt(0).toUpperCase() + value.slice(1);
      if (/^[a-zA-Z\s]*$/.test(value)) {
        setLeadCity(value);
      }
    }
  };

  console.log("YeRahaTeamLead", teamLeadArr);
  console.log("YeRahaFilteredArray", filteredWorkScopeName);
  const handleDiscardModalOpen = () => {
    console.log("handleDiscardModalOpen caling");
    if (
      !(
        leadFirstName == "" &&
        leadLastName == "" &&
        phoneNumber == "" &&
        leadEmail == "" &&
        leadCity == "" &&
        leadArea == "" &&
        leadAddress == "" &&
        leadCityCode == "" &&
        budget == "" &&
        workScope == "" &&
        selectedLeadSource == "" &&
        selectedSourceName == "" &&
        leadArea == ""
      )
    ) {
      setDiscardOpen(true);
    } else {
      handleNewLeadClose();
    }
  };

  const leadLastNameHandle = (e) => {
    if (e.target.value?.length <= 24) {
      if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
        setLeadLastName(e.target.value);
        setLastNameError(false);
      }
    } else {
      setLastNameError(true);
    }
  };

  const handlePhoneNumberChange = (event, country) => {
    console.log(event);
    let inputValue = event.replace(/[^0-9]/g, "");
    inputValue = `${inputValue}`;
    console.log(country.dialCode.length, "dialCode")
    let newNumbvalue = inputValue.substring(country.dialCode.length);
    console.log(newNumbvalue, "countrycodevalue")


    if (newNumbvalue?.length < 5) {
      setphoneNumerror({
        bol: true,
        msg: "Number must be greater than 5"
      })
    }

    if (newNumbvalue?.length >= 5) {
      setphoneNumerror({
        bol: false,
        msg: ""
      })
    }

    if (newNumbvalue?.length > 15) {
      setphoneNumerror({
        bol: true,
        msg: "Number must be less  than 15 Digits"
      })
    } else {
      let PhoneNum = `+${country.dialCode} ${newNumbvalue}`
      console.log(PhoneNum, "FinalNumber")
      setPhonenumber(PhoneNum);
    }
    // if (event?.length <= 10) {
    //   setPhonenumber(inputValue);
    //   if (/^\d+$/.test(inputValue) && inputValue.length <= 10) {
    //     if (inputValue.length <= 9) {
    //       setPhoneError(true);
    //     } else {
    //       setPhoneError(false);
    //     }
    //     setPhonenumber(inputValue);
    //   }
    // }
  };
  const handlePhoneNumberChangeNew = (contactIndex, phoneIndex, value) => {
    const cleanedValue = value.replace(/[^0-9]/g, "");
    setContacts((prevContacts) => {
      const newContacts = [...prevContacts];
      newContacts[contactIndex].otherContactPhoneNumber[phoneIndex] = cleanedValue;

      // Validate only the specific phone number field that has changed
      const errors = validatePhoneNumber(newContacts[contactIndex], phoneIndex);
      newContacts[contactIndex].errors.otherContactPhoneNumber[phoneIndex] =
        errors.otherContactPhoneNumber[phoneIndex];

      return newContacts;
    });
  };

  const handleNewLeadClose = () => {
    setphoneNumerror({
      bol: false,
      msg: ""
    })
    setLeadFirstName("");
    setLeadLastName("");
    setPhonenumber("");
    setEmailError(false);
    setLeadEmail("");
    setLeadCity("");
    setLeadArea("");
    setLeadAddress("");
    setWorkScope("");
    setLeadSource("");
    setSourceName("");
    setAssignedTo("");
    setErrorPin(false);
    setLeadArea("");
    setLeadCityCode("");
    setBudget("");
    setFirstNameError(false);
    setLastNameError(false);
    setTeamAssignList([
      { selectedTeam: '', assignedToName: '', assignedToObject: {}, teamLeads: [] }
    ]);
    setDiscardOpen(false);
    setFilteredSourceName(sourceNames);
    setFilteredWorkScopeName(leadWorkTypes);
    setFilteredLeadSourceName(leadSources);
    dispatch(setAddLeadModalOpen(false));
    setAssignedTo("");
    setAssignedToName(storeTeams?.length <= 0 && "UNASSIGNED");
  };

  const handlePinCodeChange = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    if (event.target.value?.length <= 6) {
      setLeadCityCode(inputValue);

      if (
        /^\d+$/.test(inputValue) &&
        inputValue.length >= 6 &&
        inputValue.length <= 6
      ) {
        setLeadCityCode(inputValue);
        setErrorPin(false);
      } else {
        setErrorPin(true);
        setLeadCityCode(inputValue);
      }
    }
  };
  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i;

    if (inputValue.length < 64 && emailPattern.test(inputValue)) {
      setLeadEmail(inputValue);
      setEmailError(false);
    } else {
      if (inputValue?.length == 0) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
      if (inputValue.length < 64) {
        setLeadEmail(inputValue);
      }
    }
  };

  const handleBudget = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    if (event.target.value?.length <= 10) {
      setBudget(inputValue);
      if (/^\d+$/.test(inputValue) && inputValue.length <= 9) {
        setBudget(inputValue);
      }
    }
  };

  function handleLeadArea(event) {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    if (event.target.value?.length <= 6) {
      setLeadArea(inputValue);
      if (/^\d+$/.test(inputValue) && inputValue.length <= 6) {
        setLeadArea(inputValue);
      }
    }
  }
  const changeOption = (option) => {
    setOption(option);
  };
// console.log(teamAssignList,"xdewdew")
const handleSubmit = () => {
console.log(contacts[0],"dfghjsw")
  if (
    !contacts[0]?.otherContactFirstName || 
    // contacts[0]?.otherContactLastName === undefined || 
    // leadCityCode === "" || 
    phoneNumerror.bol || 
    contacts[0]?.otherContactPhoneNumber[0] ==""  
    // errorPin 
     // leadLastName == "" ||
      // phoneNumber == "" ||
      // phoneNumber == undefined ||
      // leadEmail == "" ||
      // leadEmail == undefined ||
      // leadCity == "" ||
      // leadCity == undefined ||
      // leadArea == "" ||
      // leadArea == undefined ||
      // leadAddress == "" ||
      // leadAddress == undefined ||
      // leadCityCode == "" ||
      // leadCityCode == undefined ||
  ) {
    alert("Please enter all the details");
    return;
  } 
  
  if (phoneNumber.length > 15 && phoneNumerror?.bol === true) {
    alert("Number must be less than or equal to 15 digits");
    return;
  }
  for (let contact of contacts) {
    console.log(contact, "contact validation");

    if (
      !contact.otherContactFirstName || // Check if other contact's first name is missing
      // !contact.otherContactLastName || 
      // !contact.otherContactDesginationName || 
      // !contact.otherContactEmailId || 
      contact.otherContactPhoneNumber.some(phone => !phone.trim()) // Check if any phone number is empty
    ) {
      alert("Please enter all the contact details");
      return false;
    }
  }
  handleFormSubmit({
    firstName: contacts[0]?.otherContactFirstName,
    lastName: contacts[0]?.otherContactLastName || "", // Use empty string if lastName is not provided
    address: leadAddress,
    city: leadCity,
    pinCode: leadCityCode,
    phoneNumber: contacts[0]?.otherContactPhoneNumber[0],
    // leadFirstName,leadLastName,phoneNumber
    email: contacts[0]?.otherContactEmailId,
    budget: budget,
    requirements: workScope,
    leadSource: selectedLeadSource,
    sourceName: editLeadBoolean ? selectedSourceName : loggedInUserName,
    otherContactDetails: contacts.map(({ errors, ...rest }) => rest), // Exclude errors from contacts
    area: leadArea + " " + areaUnit,
    areaUnit: areaUnit,
    // ...(editLeadBoolean ? { assignedTo: assignedTo || teamAssignList[0]?.assignedToObject?._id } : {}),
  });
};

  
  const getUserbyId = async (id) => {
    const res = await getReq(`${constants.CRM_API}/user/getUserById?userId=${id}`);
    if (res && !res.error) {
      setUserData(res?.data?.data);
      setLoggedInUserName(res?.data?.data.fullName);
      // console.log("YeRahaUser", res?.data?.data.rolesAssigned.CRM_API.edit);
      setEditLeadBoolean(res?.data?.data?.rolesAssigned?.crm?.edit);
    } else {
      console.log(res.error);
    }
  };

  const showError = (error) => {
    // console.log(error)
    if (error.response && error.response.data.message) {
      // toast.error(error.response.data.message);
      toast.error(error.response.data.message, {
        // position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      // clg
      // alert(error.response.data.message)
      return;
    } else {
      window.location.reload();
    }
  };
  // const handleFormSubmit = async (data) => {
  //   setButtonClickedOnce(true);
  //   console.log(data,buttonClickedOnce, "Submitted data");
  
  //   try {
  //     for (const key in data) {
  //       const value = data[key];
  //       console.log(key, value, "Data cleanup");
  // if (typeof value === 'string' && value.trim() === "") {
  //         delete data[key];
  //       }
  //     }
  
  //   if (contacts[0]?.otherContactEmailId) {
  //       const [emailUsername, emailDomain] = contacts[0].otherContactEmailId.split('@');
  //       console.log("Email split successful");
  
  //      for (let i = 0; i < teamAssignList.length; i++) {
  //         const incrementedEmail = i === 0
  //           ? contacts[0]?.otherContactEmailId  
  //           : `${emailUsername}+${i}@${emailDomain}`;  
  //        const assignedToObjectReceivers = teamAssignList[i]?.assignedToObject 
  //           ? [teamAssignList[i]?.assignedToObject] 
  //           : haveAssigneToAccess;
  
  //         const res = await postReq(constants.CRM_API + "/user/addEditLead", {
  //           ...data,
  //           assignedTo: teamAssignList[i]?.assignedToObject?._id,  
  //           architectDetails: sourceNameArchitectDetails,
  //           email: incrementedEmail, 
  //         });
  
  //         if (res.error) {
  //           showError(res.error);
  //         } else {
  //           console.log("Lead creation response:", res);
  
  //           sendEmailPushBellNotifications(
  //             "Add Lead",
  //             {
  //               "@leadName": `${leadFirstName} ${leadLastName}`,
  //               "@leadCompany": profileData.CompanySettingDetails.companyName,
  //               "@Lead Email": leadEmail,
  //               "@leadPhone": phoneNumber,
  //               "@leadSource": leadSource,
  //               "@SalesRep": teamAssignList[i]?.assignedToName,
  //               "@Sender": profileData.fullName,
  //             },
  //             assignedToObjectReceivers 
  //           );
  //         }
  //       }
  
  //       setButtonClickedOnce(false);
  //       dispatch(setAddLeadModalOpen(false));
  //       apiFetch(tab); 
  //       getLeadsCount(tab); 
  
  //       notification.success({
  //         message: "Lead Created Successfully",
  //         duration: 2,
  //       });
  
  //       handleNewLeadClose(); 
  //       await setProgress(100);
  //     } else {
  //       console.error("Error: No contact email available.");
  //     }
  //   } catch (error) {
  //     console.error("Error in form submission:", error);
  //     setButtonClickedOnce(false);  
  //   }
  // };
  
  const handleFormSubmit = async (data) => {
    setButtonClickedOnce(true);
    console.log(data, buttonClickedOnce, "Submitted data");
  
    try {
      // Clean up the data by removing empty string fields
      for (const key in data) {
        const value = data[key];
        console.log(key, value, "Data cleanup");
  
        // Remove any key where the value is an empty trimmed string
        if (typeof value === 'string' && value.trim() === "") {
          delete data[key];
        }
      }
  
      // Ensure contacts exist
      if (contacts.length > 0) {
        let emailUsername, emailDomain;
  
        // Check if the first contact has an email; if not, set a default value or handle it gracefully
        if (contacts[0]?.otherContactEmailId) {
          [emailUsername, emailDomain] = contacts[0]?.otherContactEmailId.split('@');
          console.log("Email split successful:", emailUsername, emailDomain);
        } else {
          console.warn("No contact email available, proceeding without email splitting.");
          emailUsername = '';  // Default to empty string if no email is provided
          emailDomain = '';     // Default to empty string if no email is provided
        }
  
        // Loop through each team in teamAssignList and hit the API
        for (let i = 0; i < teamAssignList.length; i++) {
          const incrementedEmail = i === 0
            ? contacts[0]?.otherContactEmailId || ''  // Keep original email or default to empty
            : `${emailUsername ? `${emailUsername}+${i}@${emailDomain}` : ''}`;  // Modify email or skip if empty
  
          // If assignedToObject exists, use it, otherwise fallback to haveAssigneToAccess
          const assignedToObjectReceivers = teamAssignList[i]?.assignedToObject
            ? [teamAssignList[i]?.assignedToObject]
            : haveAssigneToAccess;
  
          // Make API request to add or edit the lead
          const res = await postReq(constants.CRM_API + "/user/addEditLead", {
            ...data,
            assignedTo: teamAssignList[i]?.assignedToObject?._id,  // Use respective assignedToObject _id
            architectDetails: sourceNameArchitectDetails,
            email: incrementedEmail,  // Use the correct email for this iteration
          });
  
          // Handle response or error
          if (res.error) {
            showError(res.error);
          } else {
            console.log("Lead creation response:", res);
  
            // Send notification after successful lead creation
            sendEmailPushBellNotifications(
              "Add Lead",
              {
                "@leadName": `${leadFirstName} ${leadLastName}`,
                "@leadCompany": profileData.CompanySettingDetails.companyName,
                "@Lead Email": leadEmail,
                "@leadPhone": phoneNumber,
                "@leadSource": leadSource,
                "@SalesRep": teamAssignList[i]?.assignedToName,
                "@Sender": profileData.fullName,
              },
              assignedToObjectReceivers  // Send to the correct recipient(s)
            );
          }
        }
  
        // Perform UI updates after successful form submission
        setButtonClickedOnce(false);
        dispatch(setAddLeadModalOpen(false));
        apiFetch(tab);  // Fetch updated leads data
        getLeadsCount(tab);  // Refresh lead count
  
        // Show success notification
        notification.success({
          message: "Lead Created Successfully",
          duration: 2,
        });
  
        handleNewLeadClose();  // Close the form modal
        await setProgress(100);  // Update any progress indication (if needed)
      } else {
        console.error("Error: No contacts available.");
      }
    } catch (error) {
      console.error("Error in form submission:", error);
      setButtonClickedOnce(false);  // Reset button state in case of error
    }
  };
  const [selectedWorkScope, setSelectedWorkScope] = useState(null);
  
  const getTeamLeads = async (teamId = null) => {
    try {
      let res;
  console.log(teamId,"dfghjklkjh")
      if (storeTeams?.length > 0 && teamId) {
        res = await getReq(
          `${constants.CRM_API}/team/${teamId}?userId=${localStorage.getItem("userId")}`
        );
      } else {
        res = await getReq(
          `${constants.CRM_API}/api/enterprise/get-team-leaders?userId=${localStorage.getItem("userId")}&access=1`
        );
      }
      
      if (res && !res.error) {
        console.log(res?.data,"dewdfwe")
        setTeamLeadArr((storeTeams?.length > 0 && teamId) ? res?.data?.members:res.data.data);
      } else {
        console.log(res.error);
      }
    } catch (error) {
      console.log('Error fetching team leads:', error);
    }
  };
  
  
  
  const getTeams = async () => {
    await axios
      .get(
        `${constants.CRM_API}/teams?userId=${localStorage.getItem("userId")}`
      )
      .then(function (response) {
        setStoreTeams(response?.data);
        console.log(response?.data, "dwedwessssss")
      })
      .catch(function (error) {
      });
    };
  useEffect(() => {
    getTeams();
  }, [])
  console.log(storeTeams, "eiuhciuhec")
  const getLeadSource = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-lead-source?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      // console.log('getting lead source', res.data.data)
      setLeadSources(res.data.data);
      setFilteredLeadSourceName(res.data.data);
    } else {
      console.log(res.error);
    }
  };
  const getWorkTypes = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-work-types?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      setLeadWorkTypes(res.data.data);

      setFilteredWorkScopeName(res.data.data);

      console.log("thisIsData : ", res.data.data);
    } else {
      console.log(res.error);
    }
  };
  const getSourceName = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-source-name?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      // console.log('getting lead source', res.data.data)
      setSourceNames(res.data.data);
      setFilteredSourceName(res.data.data);
    } else {
      console.log(res.error);
    }
  };
  useEffect(() => {
    setPhoneCode(profileData?.CompanySettingDetails?.countryCode);
  }, [profileData]);
  // useEffect(() => {
  //   getProfileData();
  // }, []);
  console.log(profileData, "vewrv");
  const leadCompanyNameHandle = (e) => {
    const { value } = e.target;
    setLeadCompanyName(value);
    // Validation logic
    setCompanyNameError(value.length === 0);
  };
  const [contacts, setContacts] = useState([
    {
      otherContactFirstName: "",
      otherContactLastName: "",
      otherContactEmailId: "",
      otherContactDesginationName: "",
      // otherContactType: "",
      otherContactPhoneNumber: [""],
      errors: {
        otherContactFirstName: "",
        otherContactLastName: "",
        otherContactEmailId: "",
        otherContactDesginationName: "",
        // otherContactType: "",
        otherContactPhoneNumber: [""],
      },
    },
  ]);

  const validatePhoneNumber = (contact, phoneIndex) => {
    const errors = { otherContactPhoneNumber: [] };
    const cleanedNumber = contact.otherContactPhoneNumber[phoneIndex].replace(/[^0-9]/g, "");
    if (phoneCode === "+91" && cleanedNumber.length !== 10) {
      errors.otherContactPhoneNumber[phoneIndex] = "Invalid phone number";
    } else {
      errors.otherContactPhoneNumber[phoneIndex] = "";
    }
    return errors;
  };
  useEffect(() => {
    async function corporateFetchFields() {
      try {
        const response = await axios.get(
          `${constants.CRM_API
          }/api/get-custom-fields-corporate?userId=${localStorage.getItem(
            "userId"
          )}`
        );

        console.log("Response received:", response);

        const fieldsData = response.data.data.data;
        const primaryContactData = response.data.data.primaryContact;
        
        console.log("Fields Data:", fieldsData);
        console.log("Primary Contact Data:", primaryContactData);
        
        if (!Array.isArray(fieldsData)) {
          console.error("Fields Data is not an array:", fieldsData);
          return;
        }

        const uniqueFieldNames = new Set();
        const uniqueFields = [];

        fieldsData.forEach((field) => {
          if (
            field.fieldName &&
            field.fieldName !== "companyName" &&
            !uniqueFieldNames.has(field.fieldName)
          ) {
            uniqueFieldNames.add(field.fieldName);
            uniqueFields.push(field);
          }
        });
        
        console.log("Unique fields:", uniqueFields);
        
        setData({
          uniqueFields: uniqueFields,
          primaryContact: primaryContactData[0],
        });
      } catch (error) {
        console.error("Error fetching corporate fields:", error);
      }
    }

    corporateFetchFields();
  }, []);
  const handleAddContact = () => {
    setContacts((prevContacts) => [
      ...prevContacts,
      {
        otherContactFirstName: "",
        otherContactLastName: "",
        otherContactEmailId: "",
        otherContactDesginationName: "",
        otherContactType: "",
        otherContactPhoneNumber: [""],
        errors: {
          otherContactFirstName: "",
          otherContactLastName: "",
          otherContactEmailId: "",
          otherContactDesginationName: "",
          otherContactType: "",
          otherContactPhoneNumber: [""],
        },
      },
    ]);
  };

  const handleAddPhoneNumber = (contactIndex) => {
    setContacts((prevContacts) => {
      const newContacts = [...prevContacts];
      newContacts[contactIndex].otherContactPhoneNumber.push("");
      newContacts[contactIndex].errors.otherContactPhoneNumber.push("");
      return newContacts;
    });
  };
  const validateField = (contact, field) => {
    const errors = {
      otherContactFirstName: "",
      otherContactLastName: "",
      otherContactEmailId: "",
      otherContactDesginationName: "",
      otherContactType: "",
    };

    switch (field) {
      case "firstName":
        if (contact.otherContactFirstName.trim() === "") {
          errors.otherContactFirstName = "Please enter first name";
        }
        break;

      // case "lastName":
      //   if (contact.otherContactLastName.trim() === "") {
      //     errors.otherContactLastName = "Please enter last name";
      //   }
      //   break;
      
      case "designation":
        if (contact.otherContactDesginationName.trim() === "") {
          errors.otherContactDesginationName = "Please enter designation name";
        }
        break;
        
        case "contactType":
        if (contact.otherContactType.trim() === "") {
          errors.otherContactType = "Please enter Contact Type";
        }
        break;

      case "email": {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(contact.otherContactEmailId)) {
          errors.otherContactEmailId = "Invalid email address";
        }
        break;
      }

      default:
        break;
    }

    return errors;
  };
  const handleInputChange = (contactIndex, field, value) => {
    setContacts((prevContacts) => {
      const newContacts = [...prevContacts];
      newContacts[contactIndex][field] = value;
      
      // Validate only the specific field that has changed
      const errors = validateField(newContacts[contactIndex], field);
      newContacts[contactIndex].errors = {
        ...newContacts[contactIndex].errors,
        ...errors,
      };
      
      return newContacts;
    });
  };
  const handleRemovePhoneNumber = (contactIndex, phoneIndex) => {
    setContacts((prevContacts) => {
      const newContacts = [...prevContacts];
      newContacts[contactIndex].otherContactPhoneNumber.splice(phoneIndex, 1);
      newContacts[contactIndex].errors.otherContactPhoneNumber.splice(phoneIndex, 1);
      return newContacts;
    });
  };
  const handleRemoveContact = (index) => {
    setContacts((prevContacts) => prevContacts.filter((_, i) => i !== index));
  };
  useEffect(() => {
    let i = 0;
    const cluster = [];
    const values = {};

    const addSpecialField = (field) => {
      cluster.push([field]);
      values[field.fieldName] =
        field.fieldType === "Date" ? dayjs(new Date()) : "";
    };

    while (i < data?.uniqueFields.length) {
      const field = data?.uniqueFields[i];

      if (field.fieldName === "Address" || field.fieldName === "Remarks") {
        // Add special fields directly
        addSpecialField(field);
        i++;
      } else {
        if (
          i + 1 < data?.uniqueFields.length &&
          data?.uniqueFields[i + 1].fieldName !== "Address" &&
          data?.uniqueFields[i + 1].fieldName !== "Remarks"
        ) {
          // Pair fields, excluding special fields
          cluster.push([data?.uniqueFields[i], data?.uniqueFields[i + 1]]);
          values[data?.uniqueFields[i].fieldName] =
            data?.uniqueFields[i].fieldType === "Date" ? dayjs(new Date()) : "";
          values[data?.uniqueFields[i + 1].fieldName] =
            data?.uniqueFields[i + 1].fieldType === "Date"
              ? dayjs(new Date())
              : "";
          i += 2;
        } else {
          // Handle single fields
          cluster.push([data?.uniqueFields[i]]);
          values[data?.uniqueFields[i].fieldName] =
            data?.uniqueFields[i].fieldType === "Date" ? dayjs(new Date()) : "";
          i++;
        }
      }
    }
    // Set the state with all clusters
    setCorporateFieldsCluster(cluster);
    setCorpData(values);
    // Debug logs
    console.log("Cluster:", cluster);
    console.log("Values:", values);
  }, [data]);
  const onChange = (event, fieldName) => {
    // Update the corpData with the new field value
    const updatedValue = event.target.value;

    setCorpData((prevState) => ({
      ...prevState,
      [fieldName]: updatedValue,
    }));
  };

  // Function to save a new "Assign To" entry when user types a custom value
  const saveAssignedTo = () => {
    if (!assignedToName) return; // Avoid saving if the input is empty

    // Create a new team lead object
    const newTeamLead = {
      label: assignedToName,
      _id: Math.random().toString(36).substr(2, 9), // Generate a temporary ID for the new team lead
      email: "", // Add email if needed, or leave it as an empty string
    };

    // Add the new team lead to the array
    setTeamLeadArr([...teamLeadArr, newTeamLead]);

    // Update the selection and form data
    setAssignedTo(newTeamLead._id); // Set the new team lead ID
    setAssignedToObject(newTeamLead); // Set the new team lead object
    onChange({ target: { value: assignedToName } }, "Assign To"); // Update corpData for submission
  };

  const changeCorporateValueHandler = React.useCallback((e, fieldId) => {
    const { value } = e.target;
    setCorpData((prevData) => ({
      ...prevData,
      [fieldId]: value,
    }));
  }, []);

  const GetComponentCorporate = React.memo(({ field, corpData, onChange }) => {
    console.log(field, "Rendering GetComponentCorporate");
    console.log(corpData, "dwiheindw");

    const [localValue, setLocalValue] = useState(
      corpData[field.fieldName] || ""
    );

    useEffect(() => {
      setLocalValue(corpData[field.fieldName] || "");
    }, [corpData, field.fieldName]);
    
    const handleChange = useCallback((e) => {
      setLocalValue(e.target.value);
    }, []);
    console.log(field, "dxqwdew4fs")
    const handleBlur = useCallback(() => {
      onChange({ target: { value: localValue } }, field.fieldName);
    }, [localValue, onChange, field.fieldName]);
    const inputRef = useRef(null); // Ref to manage focus

    // Memoize the options to avoid recalculating on every render
    const leadSourceOptions = useMemo(() => {
      return filteredLeadSourceName
      ?.slice()
        .sort((a, b) => a.leadSource.localeCompare(b.leadSource))
        .map((leadWorkType) => ({
          label: leadWorkType.leadSource,
        }));
    }, [filteredLeadSourceName]);
    switch (field?.fieldType) {
      case "Text":
        if (field?.fieldName === "Source Name") {
          return (
            <FormControl size="small">
              <Autocomplete
                sx={{
                  width: '359px',
                  '& .MuiInputBase-root': {
                    height: '50px',
                    minHeight: '50px',
                  },
                  '& .MuiOutlinedInput-root': {
                    height: '50px',
                    minHeight: '50px',
                  },
                }}
                disablePortal
                id="combo-box-demo"
                style={{ width: "359px", height: "50px" }}
                options={filteredSourceName
                  ?.slice()
                  .sort((a, b) => a.sourceName.localeCompare(b.sourceName))
                  .map((workScope) => ({
                    label: workScope.sourceName,
                  }))}
                value={selectedSourceName} // Manage selected value
                onChange={(_event, newValue) => {
                  setSelectedSourceName(newValue); // Update selected value
                  const newSourceValue = newValue?.label || "";
                  setLocalValue(newSourceValue); // Update the state for localValue
                  setInputValueScopeName(newSourceValue); // Sync input value with selected value

                  // Update the corpData object so it's available for form submission
                  onChange({ target: { value: newSourceValue } }, "Source Name");
                }}
                inputValue={inputValueScopeName} // Manage input value
                onInputChange={(_event, newInputValue) => {
                  setInputValueScopeName(newInputValue); // Update input value as user types
                }}
                noOptionsText={
                  <span onClick={savesourceName} style={{ cursor: "pointer" }}>
                    {inputValueScopeName ? (
                      <span>
                        Create:{" "}
                        <span style={{ color: "blue" }}>
                          "{inputValueScopeName}"
                        </span>
                      </span>
                    ) : (
                      "No options"
                    )}
                  </span>
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Source Name"
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        height: "50px",
                        padding: "0 14px",
                      },
                    }}
                    onKeyDown={(event) => {
                      if (
                        event.key === "Enter" &&
                        !filteredSourceName.some(
                          (option) => option.sourceName === inputValueScopeName
                        )
                      ) {
                        savesourceName();
                      }
                    }}
                  />
                )}
              />
            </FormControl>

          );
        } else if (field?.fieldName === "Assign To") {
          return (
            <FormControl size="small">
              <Autocomplete
                sx={{
                  width: '359px',
                  '& .MuiInputBase-root': {
                    height: '50px',
                    minHeight: '50px',
                  },
                  '& .MuiOutlinedInput-root': {
                    height: '50px',
                    minHeight: '50px',
                  },
                }}
                disablePortal
                id="assign-to-autocomplete"
                style={{ width: "359px", height: "50px" }}
                options={teamLeadArr
                  ?.slice()
                  .sort((a, b) => a.fullName.localeCompare(b.fullName))
                  .map((teamLead) => ({
                    label: teamLead.fullName,
                    _id: teamLead._id,
                    email: teamLead.email,
                  }))}
                value={assignedToName ? { label: assignedToName } : null} // Manage selected value
                onChange={(_event, newValue) => {
                  const newAssignedToValue = newValue?.label || "";
                  setAssignedTo(newValue?._id || ""); // Set team lead ID
                  setAssignedToName(newAssignedToValue); // Update the name
                  onChange({ target: { value: newValue?._id } }, "Assign To");
                }}
                inputValue={assignedToName} // Manage input value for search
                onInputChange={(_event, newInputValue) => {
                  setAssignedToName(newInputValue); // Update input value while typing
                }}
                noOptionsText={
                  <span onClick={saveAssignedTo} style={{ cursor: "pointer" }}>
                    {assignedToName ? (
                      <span>
                        Create:{" "}
                        <span style={{ color: "blue" }}>
                          "{assignedToName}"
                        </span>
                      </span>
                    ) : (
                      "No options"
                    )}
                  </span>
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Assign To"
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        height: "50px",
                        padding: "0 14px",
                      },
                    }}
                    onKeyDown={(event) => {
                      if (
                        event.key === "Enter" &&
                        !teamLeadArr.some((lead) => lead.fullName === assignedToName)
                      ) {
                        saveAssignedTo(); // Save new team lead if not found
                      }
                    }}
                  />
                )}
              />
            </FormControl>
          );
        }
        else {
          return (
            <TextField
              label={field?.fieldName}
              type="text"
              // required={field?.isRequired}
              value={localValue}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          );
        }
      case "Number":
        if (field?.fieldName === "Phone Number") {
          return (
            <FormControl
              fullWidth
              sx={{
                m: 0,
                width: "100%",
                "& .MuiOutlinedInput-root": { padding: 0 },
              }}
              variant="outlined"
            >
              <InputLabel htmlFor="phone-number">{field?.fieldName}</InputLabel>
              <OutlinedInput
                id="phone-number"
                type="number"
                style={{ width: "232px" }}
                required={field?.isRequired}
                value={localValue}
                onChange={handleChange}
                onBlur={handleBlur}
                startAdornment={
                  <InputAdornment
                    style={{
                      borderRight: "1px solid #EDEDED",
                      padding: "10px",
                    }}
                    position="start"
                  >
                    +91
                  </InputAdornment>
                }
                label={field?.fieldName}
              />
            </FormControl>
          );
        }
        else if (field?.fieldName === "Expected Budget") {
          return (
            <FormControl
              fullWidth
              sx={{
                m: 0,
                width: "100%",
                "& .MuiOutlinedInput-root": { padding: 0 },
                "& input[type=number]": {
                  MozAppearance: "textfield", // Firefox
                  WebkitAppearance: "none",   // Chrome, Safari, Edge
                  appearance: "textfield",    // Default
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                  "&::-moz-inner-spin-button": {
                    MozAppearance: "none",
                    margin: 0,
                  },
                },
              }}
              variant="outlined"
            >
              <InputLabel htmlFor="expected-budget">
                {field?.fieldName}
              </InputLabel>
              <OutlinedInput
                id="expected-budget"
                type="number"
                // required={field?.isRequired}
                value={localValue}
                onChange={handleChange}
                onBlur={handleBlur}
                startAdornment={
                  <InputAdornment style={{ padding: "10px" }} position="start">
                    ₹
                  </InputAdornment>
                }
                label={field?.fieldName}
              />
            </FormControl>
          );
        }
        else if (field?.fieldName === "Area") {
          return (
            <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
              <InputLabel htmlFor="area">{field?.fieldName}</InputLabel>
              <OutlinedInput
                id="area"
                type="number"
                // required={field?.isRequired}
                value={localValue}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{
                  width: "357px",
                  marginRight: "-8px",
                  marginLeft: "-7px",
                }}
                endAdornment={<InputAdornment position="end">Sqft</InputAdornment>}
                label={field?.fieldName}
                sx={{
                  "& input[type=number]": {
                    MozAppearance: "textfield", // Firefox
                    WebkitAppearance: "none",   // Chrome, Safari, Edge
                    appearance: "textfield",    // Default
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                    "&::-moz-inner-spin-button": {
                      MozAppearance: "none",
                      margin: 0,
                    },
                  },
                }}
              />
            </FormControl>
          );
        } else {
          return (
            <TextField
              label={field?.fieldName}
              type="number"
              // required={field?.isRequired}
              value={localValue}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              sx={{
                // Remove the up/down arrows for the number input field across all browsers
                "& input[type=number]": {
                  MozAppearance: "textfield", // Firefox
                  WebkitAppearance: "none",   // Chrome, Safari, Edge
                  appearance: "textfield",    // Default
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                  "&::-moz-inner-spin-button": {
                    MozAppearance: "none",
                    margin: 0,
                  },
                },
              }}
            />

          );
        }
      case "Email":
        return (
          <TextField
            label={field?.fieldName}
            type="email"
            // required={field?.isRequired}
            value={localValue}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        );
      case "Website":
        return (
          <TextField
            label={field?.fieldName}
            type="text"
            // required={field?.isRequired}
            value={localValue}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        );
      case "Date":
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={field?.fieldName}
              // required={field?.isRequired}
              value={localValue || null}
              onChange={(date) => handleChange({ target: { value: date } })}
              onBlur={handleBlur}
              fullWidth
            />
          </LocalizationProvider>
        );
      case "Drop-Down":
        if (field?.fieldName === "Work Scope") {
          return (
            <FormControl size="small">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                style={{ width: "359px", height: "50px" }}
                options={filteredWorkScopeName
                  ?.slice()
                  .sort((a, b) => a.type.localeCompare(b.type))
                  .map((workScope) => ({
                    label: workScope.type,
                  }))}
                value={selectedWorkScope} // Manage selected value
                onChange={(_event, newValue) => {
                  setSelectedWorkScope(newValue); // Update selected value
                  const newWorkScopeValue = newValue?.label || "";
                  setLocalValue(newWorkScopeValue); // Update the state for localValue
                  setInputValueWorkScope(newWorkScopeValue); // Sync input value with selected value

                  // Update the corpData object so it's available for form submission
                  onChange({ target: { value: newWorkScopeValue } }, field.fieldName);
                }}
                inputValue={inputValueWorkScope} // Manage input value
                onInputChange={(_event, newInputValue) => {
                  setInputValueWorkScope(newInputValue); // Update input value as user types
                }}
                noOptionsText={
                  <span onClick={saveworkScope} style={{ cursor: "pointer" }}>
                    {inputValueWorkScope ? (
                      <span>
                        Create:{" "}
                        <span style={{ color: "blue" }}>
                          "{inputValueWorkScope}"
                        </span>
                      </span>
                    ) : (
                      "No options"
                    )}
                  </span>
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Work Scope"
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        height: "50px",
                        padding: "0 14px",
                      },
                    }}
                    onKeyDown={(event) => {
                      if (
                        event.key === "Enter" &&
                        !filteredWorkScopeName.some(
                          (option) => option.type === inputValueWorkScope
                        )
                      ) {
                        saveworkScope();
                      }
                    }}
                  />
                )}
              />
            </FormControl>
          );
        } else if (field?.fieldName === "Lead Source") {
          return (
            <FormControl size="small">
              <Autocomplete
                disablePortal
                sx={{
                  width: '359px',
                  '& .MuiInputBase-root': {
                    height: '50px',
                    minHeight: '50px',
                  },
                  '& .MuiOutlinedInput-root': {
                    height: '50px',
                    minHeight: '50px',
                  },
                }}
                id="combo-box-demo"
                options={leadSourceOptions} // Use memoized options
                value={selectedLeadSource}
                onChange={(_event, newValue) => {
                  // Only update if the value has changed
                  if (newValue?.label !== selectedLeadSource) {
                    setSelectedLeadSource(newValue?.label || '');
                    setInputValueLeadSource(newValue?.label || '');
                    onChange({ target: { value: newValue?.label || '' } }, 'Lead Source');
                  }
                }}
                inputValue={inputValueLeadSource}
                onInputChange={(_event, newInputValue) => {
                  // Update input value only if it has actually changed
                  if (newInputValue !== inputValueLeadSource) {
                    setInputValueLeadSource(newInputValue);
                  }
                }}
                noOptionsText={
                  inputValueLeadSource && editLeadBoolean ? (
                    <span onClick={saveLeadSource} style={{ cursor: 'pointer' }}>
                      Create: <span style={{ color: 'blue' }}>"{inputValueLeadSource}"</span>
                    </span>
                  ) : (
                    'No options'
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Lead Source"
                    inputRef={inputRef} // Use input ref to manage focus
                    onKeyDown={(event) => {
                      if (
                        event.key === 'Enter' &&
                        editLeadBoolean &&
                        !filteredLeadSourceName.some(
                          (option) =>
                            option.leadSource.toLowerCase() === inputValueLeadSource.toLowerCase()
                        )
                      ) {
                        saveLeadSource();
                      }
                    }}
                  />
                )}
              />
            </FormControl>
          );
        }
        break;
      case "Dropdown":
        return (
          <FormControl fullWidth>
            <InputLabel id={field?.fieldName.toLowerCase()}>
              {field?.fieldName}
            </InputLabel>
            <Select
              labelId={field?.fieldName.toLowerCase()}
              id={field?.fieldName.toLowerCase()}
              label={field?.fieldName}
              // required={field?.isRequired}
              value={localValue}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              {field?.options?.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      default:
        return null;
    }
  });
 
  const handleAddMoreTeam = () => {
    // Add a new entry to the teamAssignList array
    setTeamAssignList([
      ...teamAssignList,
      { selectedTeam: '', assignedToName: '', assignedToObject: {} }
    ]);
  };

  console.log(teamAssignList,"fefer")
  const handleTeamChange = async (index, team) => {
    const updatedTeams = [...teamAssignList];
  
    updatedTeams[index].selectedTeam = team.label;
    updatedTeams[index].selectedTeamObject = {
      _id: team._id,
      name: team.label,
      cId: team.cId
    };
  
    // Fetch team leads conditionally
    try {
      await getTeamLeads(team._id);  // Pass team._id to fetch team-specific leads
    } catch (error) {
      console.log('Error fetching team leads:', error);
    }
  
    setTeamAssignList(updatedTeams);
  };
  

  const handleAssignToChange = (index, assignTo) => {
    const updatedTeams = [...teamAssignList];
    updatedTeams[index].assignedToName = assignTo?.label;
    updatedTeams[index].assignedToObject = {
      _id: assignTo?._id,
      email: assignTo?.email,
      fullName: assignTo?.label
    };
    setTeamAssignList(updatedTeams);
  };
  const handleAssignToDropdownToggle = async (index) => {
    const selectedTeamId = teamAssignList[index]?.selectedTeamObject?._id;
  
    if (selectedTeamId && !teamLeadArr[selectedTeamId]) {
      // Fetch leads only if they haven't been fetched for this team
      await getTeamLeads(selectedTeamId);
    }
  };
  
  const combineData = (leadCompanyName, corpData, contacts) => {
    // Add leadCompanyName to corpData
    const updatedCorpData = {
      ...corpData,
      "companyName": leadCompanyName,
    };

    // Create the final payload
    const finalPayload = {
      ...updatedCorpData,
      type: 1,
      otherContactDetails: contacts.map(({ errors, ...rest }) => rest),
    };
    if (!finalPayload["Assign To"]) {
      delete finalPayload["Assign To"];
    }
    return finalPayload;
  };
  const finalPayload = combineData(leadCompanyName, corpData, contacts);
  console.log(finalPayload, "heyyouuu");

  const handleLeadSource = (leadSource) => {
  };
  const handleSourceName = (sourceName1) => {
    // setSourceName(e.target.value);
    const tempSourceName = sourceNames.find(
      (sourceName) => sourceName.sourceName === sourceName1
    );
    setSelectedSourceName(tempSourceName.sourceName);
    if (tempSourceName?.architectDetails) {
      setSourceNameArchitectDetails(tempSourceName?.architectDetails);
    }
  };
  // const createCorpLead = async () => {
  //   await axios
  //     .post(
  //       `${constants.CRM_API}/user/add-lead?userId=${localStorage.getItem(
  //         "userId"
  //       )}`,
  //       finalPayload
  //     )
  //     .then(function (response) {
  //       console.log(response, "oobbjj");
  //       toast.success("Added Successfully");
  //       // window.location.reload();
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };
  const validateCorporateForm = (formData) => {
    const { leadCompanyName, contacts, corpData } = formData;

    // Check if company name is empty
    if (!leadCompanyName) {
      alert("Please enter the company name");
      return false;
    }

    // Check if any contact fields are empty
    for (let contact of contacts) {
      console.log(contact, "contact validation");

      if (
        !contact.otherContactFirstName ||
        // !contact.otherContactLastName ||
        // !contact.otherContactDesginationName ||
        // !contact.otherContactEmailId ||
        // !contact.otherContactType ||
        // Only check the phone numbers array, ignore errors object
        contact.otherContactPhoneNumber.some(phone => !phone.trim())
      ) {
        alert("Please enter all the contact details");
        return false;
      }
    }

    // Check if any corporate fields are empty
    // for (let key in corpData) {
    //   if (key !== "Assign To" && !corpData[key]) {
    //     alert(`Please fill in the ${key} field`);
    //     return false;
    //   }
    // }

    return true;
  };

  const resetContacts = () => {
    setLeadCompanyName("");
    const initialContactState = [
      {
        otherContactFirstName: "",
        otherContactLastName: "",
        otherContactEmailId: "",
        otherContactDesginationName: "",
        otherContactType: "",
        otherContactPhoneNumber: [""],
        errors: {
          otherContactFirstName: "",
          otherContactLastName: "",
          otherContactEmailId: "",
          otherContactDesginationName: "",
          otherContactType: "",
          otherContactPhoneNumber: [""],
        },
      },
    ];

    setContacts(initialContactState);
  };
  const createCorpLead = async () => {
    const formData = {
      leadCompanyName,
      contacts,
      corpData,
    };
    if (validateCorporateForm(formData)) {
      const finalPayload = combineData(leadCompanyName, corpData, contacts);
      console.log(contacts,finalPayload,"fghjk")

      try {
        const response = await axios.post(
          `${constants.CRM_API}/user/add-lead?userId=${localStorage.getItem("userId")}`,
          finalPayload
        );
        console.log(response, "oobbjj");
        setButtonClickedOnce(false);
        dispatch(setAddLeadModalOpen(false));
        apiFetch(tab);
        getLeadsCount(tab);
        notification.success({
          message: response?.message || "Lead Created Successfully",
          duration: 2,
        });
        // resetContacts();
        window.location.reload();
        handleNewLeadClose();
        // toast.success("Added Successfully");
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getLeadSource();
    getSourceName();
    getTeamLeads();
    getWorkTypes();
    getUserbyId(localStorage.getItem("userId"));
  }, []);
  const [discardOpen, setDiscardOpen] = useState(false);

  const handleOnFocus = (fieldName, errorStateName) => {
    if (fieldName?.length === 0) {
      errorStateName(true);
    }
  };

  <script type="text/javascript"></script>;

  //  custom phone input

  return (
    <>
      <Modal
        title={false}
        show={discardOpen}
        centered
        width={200}
        bodyStyle={{ height: 60 }}
        closable={false}
      >
        <div
          style={{
            border: "1px solid black",
            borderRadius: "5px",
            margin: "5px",
          }}
        >
          <h6 style={{ padding: "3%" }}>Do you want to discard the lead ?</h6>
          <Modal.Footer>
            <div className={styles.discardCloseBtn}>
              <button
                className={styles.createModalSave}
                onClick={handleNewLeadClose}
              >
                <span
                  style={{
                    color: "#FFF",
                    fontWeight: "600",
                    fontSize: "1rem",
                  }}
                >
                  Yes{" "}
                </span>
              </button>
              <button
                className={styles.createModalClose}
                onClick={() => setDiscardOpen(false)}
              >
                <span
                  style={{
                    color: "#C9C9C9",
                    fontWeight: "600",
                    fontSize: "1rem",
                  }}
                >
                  No{" "}
                </span>
              </button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
      {console.log(contacts, "xwqxqwx")}
      <Modal
        show={addLeadModalOpen}
        onHide={() => handleNewLeadClose()}
        backdrop="static"
        keyboard={false}
        dialogClassName={styles.mainwidthmodal}
        centered
        width={800}>
        <Modal.Header className={styles.addLeadHeader}>
          {console.log("fcrewcder")}
          <div className={styles.addLeadTitle}>Add Lead</div>
          <div className={styles.addLeadHeaderButtons}>
            <button
              onClick={handleDiscardModalOpen}
              className={styles.addLeadCancel}
            >
              Cancel
            </button>
            <button
              disabled={buttonClickedOnce}
              className={styles.createModalSave}
              onClick={() => {
                option == "Corporate" && createCorpLead();
                option === "Individual" && handleSubmit();
              }}
            >
              <span
                style={{
                  color: "#FFF",
                }}
              >
                {!buttonClickedOnce ? (
                  "Add Lead"
                ) : (
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ height: "1.25rem", width: "1.25rem" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                )}
              </span>
            </button>
          </div>
        </Modal.Header>
       
        <Modal.Body>
          <div className={styles.addLeadOptions}>
            <div
              className={
                option === "Individual"
                  ? styles.addLeadOptionSelected
                  : styles.addLeadOptionNotSelected
              }
              onClick={changeOption.bind(null, "Individual")}
            >
              Individual
            </div>
            <div
              className={
                option === "Corporate"
                  ? styles.addLeadOptionSelected
                  : styles.addLeadOptionNotSelected
              }
              onClick={changeOption.bind(null, "Corporate")}
            >
              Corporate
            </div>
          </div>
          {option === "Individual" && (
            <div className={styles.offcanvasBody} >
              {/* <div className={styles.formName} style={{ paddingTop: "10px" }}> */}
                {/* <TextField
                  style={{ width: "363px" }}
                  id="outlined-basic"
                  label="First Name*"
                  size="small"
                  helperText={
                    firstNameError && leadFirstName.length == 0
                      ? "Please enter first name"
                      : firstNameError && "Limit of 24 characters is reached"
                  }
                  variant="outlined"
                  isRequired={true}
                  error={firstNameError}
                  value={leadFirstName}
                  InputProps={{
                    inputMode: "text",
                  }}
                  onChange={(e) => {
                    leadFirstNameHandle(e);
                  }}
                /> */}

                {/* <TextField
                  style={{ width: "359px" }}
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  size="small"
                  // helperText={
                  //   lastNameError && leadLastName.length == 0
                  //     ? "Please enter last name"
                  //     : lastNameError && "Limit of 24 characters is reached"
                  // }
                  // error={lastNameError}
                  // onFocus={() => handleOnFocus(leadLastName, setLastNameError)}
                  isRequired={true}
                  value={leadLastName}
                  InputProps={{
                    inputMode: "text",
                  }}
                  onChange={(e) => leadLastNameHandle(e)}
                /> */}
              {/* </div> */}
              {/* <div className={styles.formName} style={{ marginLeft: "-3px" }}>

                <div >
                  <PhoneInput
                    placeholder="Enter the Number"
                    enableSearch={true}
                    inputStyle={{ height: "42px", width: "359px", }}
                    value={phoneNumber}
                    containerClass="phoneNumber"
                    countryCodeEditable={false}
                    enableLongNumbers={17}
                    prefix="+"

                    onChange={(e, country) => handlePhoneNumberChange(e, country)}
                    specialLabel="Phone No"
                    country="in"
                  />
                  <div style={{ color: "red", marginTop: "3px", paddingLeft: "5px", fontSize: "10px" }}>
                    {phoneNumerror && phoneNumerror?.bol === true ? phoneNumerror?.msg : ""}
                  </div>
                </div> */}

                {/* <TextField
                  style={{ width: "359px" }}
                  id="outlined-basic"
                  label="Email Address"
                  variant="outlined"
                  isRequired={false}
                  value={leadEmail}
                  size="small"
                  onKeyDown={(e) =>
                    exceptThisSymbols2.includes(e.key) && e.preventDefault()
                  }
                
                  onChange={(e) => handleEmailChange(e)}
                /> */}
                  {/* // error={emailError}
                  // helperText={
                  //   emailError
                  //     ? leadEmail.length == 0
                  //       ? "Please input email"
                  //       : "Invalid email address"
                  //     : ""
                  // } */}
              {/* </div> */}
              <div>
                  {contacts?.map((contact, contactIndex) => (
                    <div key={contactIndex} style={{ marginBottom: "20px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent:
                            contactIndex === 1 ? "space-between" : "flex-end",
                        }}
                      >
                        {contactIndex == 1 && (
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "500",
                              color: "#212529",
                              marginBottom: "10px",
                            }}
                          >
                            Other Contact
                          </div>
                        )}
                        {contacts?.length > 1 && contactIndex != 0 && (
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "#CC0000",
                              cursor: "pointer",
                            }}
                            onClick={() => handleRemoveContact(contactIndex)}
                          >
                            Remove
                          </div>
                        )}
                      </div>
                      {contactIndex == 0 ?
                        <div>
                          <div style={{ display: "flex", gap: "31px" }}>
                            <TextField
                              style={{ width: "359px", borderRadius: "8px" }}
                              id={`first-name-${contactIndex}`}
                              label="First Name"
                              variant="outlined"
                              required
                              error={Boolean(contact.errors?.otherContactFirstName)}
                              helperText={contact.errors?.otherContactFirstName || ""}
                              value={contact.otherContactFirstName}
                              onChange={(e) =>
                                handleInputChange(
                                  contactIndex,
                                  "otherContactFirstName",
                                  e.target.value
                                )
                              }
                            />
                            <TextField
                              style={{ width: "359px", borderRadius: "8px" }}
                              id={`last-name-${contactIndex}`}
                              label="Last Name"
                              variant="outlined"
                              error={Boolean(contact.errors?.otherContactLastName)}
                              helperText={contact.errors?.otherContactLastName || ""}
                              value={contact.otherContactLastName}
                              onChange={(e) =>
                                handleInputChange(
                                  contactIndex,
                                  "otherContactLastName",
                                  e.target.value
                                )
                              }
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              gap: "31px", marginTop: "21px",
                            }}
                          >
                            <TextField
                              style={{ width: "359px", borderRadius: "8px" }}
                              id={`email-${contactIndex}`}
                              label="Email Address"
                              variant="outlined"
                              // required
                              error={Boolean(contact.errors?.otherContactEmailId)}
                              helperText={contact.errors?.otherContactEmailId || ""}
                              value={contact.otherContactEmailId}
                              onChange={(e) =>
                                handleInputChange(
                                  contactIndex,
                                  "otherContactEmailId",
                                  e.target.value
                                )
                              }
                            />
                            <TextField
                              style={{ width: "359px", borderRadius: "8px" }}
                              id={`designation-${contactIndex}`}
                              label="Designation Name"
                              variant="outlined"
                              // required
                              error={Boolean(contact.errors?.otherContactDesginationName)}
                              helperText={contact.errors?.otherContactDesginationName || ""}
                              value={contact.otherContactDesginationName || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  contactIndex,
                                  "otherContactDesginationName",
                                  e.target.value
                                )
                              }
                            />
                          </div>

                          <div style={{
                            display: "flex", gap: "31px",
                            flexDirection: "column", marginTop: "21px",
                          }}>

                            {contact.otherContactPhoneNumber.map((phone, phoneIndex) => (
                              <div
                                key={phoneIndex}
                                style={{
                                  display: "flex",
                                  gap: "13px",
                                  alignItems: "center",
                                }}
                              >

                                <TextField
                                  style={{ width: "328px" }}
                                  label="Phone number"
                                  required
                                  value={phone}
                                  error={Boolean(contact.errors?.otherContactPhoneNumber[phoneIndex])}
                                  helperText={
                                    contact.errors?.otherContactPhoneNumber[phoneIndex] || ""
                                  }
                                  onChange={(e) =>
                                    handlePhoneNumberChangeNew(
                                      contactIndex,
                                      phoneIndex,
                                      e.target.value
                                    )
                                  }
                                  InputProps={{
                                    inputMode: "numeric",
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {phoneCode}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                {contact.otherContactPhoneNumber.length > 1 && (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleRemovePhoneNumber(
                                        contactIndex,
                                        phoneIndex
                                      )
                                    }
                                  >
                                    <img
                                      src={Trash}
                                      style={{ height: "20px", width: "27px" }}
                                    />
                                  </div>
                                )}
                                <div
                                  className={styles.plusii}
                                  onClick={() => handleAddPhoneNumber(contactIndex)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={plusBluehover}
                                    style={{ height: "22px", width: "24px" }}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        :
                        <div>
                          <div
                            style={{
                              display: "flex",
                              gap: "31px",
                            }}
                          >
                            <TextField
                              style={{ width: "359px", borderRadius: "8px" }}
                              id={`contactType-${contactIndex}`}
                              label="Contact Type"
                              variant="outlined"
                              placeholder="e.g Lead POC"
                              required
                              error={Boolean(contact.errors?.otherContactType)}
                              helperText={contact.errors?.otherContactType || ""}
                              value={contact.otherContactType}
                              onChange={(e) =>
                                handleInputChange(
                                  contactIndex,
                                  "otherContactType",
                                  e.target.value
                                )
                              }
                            />
                            <TextField
                              style={{ width: "359px", borderRadius: "8px" }}
                              id={`designation-${contactIndex}`}
                              label="Designation Name"
                              variant="outlined"
                              // required
                              error={Boolean(contact.errors?.otherContactDesginationName)}
                              helperText={contact.errors?.otherContactDesginationName || ""}
                              value={contact.otherContactDesginationName || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  contactIndex,
                                  "otherContactDesginationName",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div style={{ display: "flex", gap: "31px", marginTop: "21px", }}>
                            <TextField
                              style={{ width: "359px", borderRadius: "8px" }}
                              id={`first-name-${contactIndex}`}
                              label="First Name"
                              variant="outlined"
                              required
                              error={Boolean(contact.errors?.otherContactFirstName)}
                              helperText={contact.errors?.otherContactFirstName || ""}
                              value={contact.otherContactFirstName}
                              onChange={(e) =>
                                handleInputChange(
                                  contactIndex,
                                  "otherContactFirstName",
                                  e.target.value
                                )
                              }
                            />
                            <TextField
                              style={{ width: "359px", borderRadius: "8px" }}
                              id={`last-name-${contactIndex}`}
                              label="Last Name"
                              variant="outlined"
                              error={Boolean(contact.errors?.otherContactLastName)}
                              helperText={contact.errors?.otherContactLastName || ""}
                              value={contact.otherContactLastName}
                              onChange={(e) =>
                                handleInputChange(
                                  contactIndex,
                                  "otherContactLastName",
                                  e.target.value
                                )
                              }
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap", // Allows wrapping to the next line
                              gap: "15px",
                              marginTop: "21px",
                            }}
                          >
                            {/* Email Address Field */}
                            <TextField
                              style={{ width: "359px", borderRadius: "8px" }}
                              id={`email-${contactIndex}`}
                              label="Email Address"
                              variant="outlined"
                              // required
                              error={Boolean(contact.errors?.otherContactEmailId)}
                              helperText={contact.errors?.otherContactEmailId || ""}
                              value={contact.otherContactEmailId}
                              onChange={(e) =>
                                handleInputChange(contactIndex, "otherContactEmailId", e.target.value)
                              }
                            />

                            {/* First Phone Number */}
                            {contact.otherContactPhoneNumber.map((phone, phoneIndex) => (
                              <div
                                key={phoneIndex}
                                style={{
                                  display: "flex",
                                  gap: "13px",
                                  alignItems: "center",
                                  marginLeft: "16px",
                                  marginTop: phoneIndex === 0 ? "0px" : "21px", // Push the next phone number to the next line
                                  width: phoneIndex === 0 ? "360px" : "100%", // Make sure the first phone number stays on the same line as email
                                }}
                              >
                                {/* Phone Number Input */}
                                <TextField
                                  style={{ width: "328px" }}
                                  label="Phone number"
                                  required
                                  value={phone}
                                  error={Boolean(contact.errors?.otherContactPhoneNumber[phoneIndex])}
                                  helperText={
                                    contact.errors?.otherContactPhoneNumber[phoneIndex] || ""
                                  }
                                  onChange={(e) =>
                                    handlePhoneNumberChangeNew(contactIndex, phoneIndex, e.target.value)
                                  }
                                  InputProps={{
                                    inputMode: "numeric",
                                    startAdornment: (
                                      <InputAdornment position="start">{phoneCode}</InputAdornment>
                                    ),
                                  }}
                                />

                                {/* Remove Phone Button */}
                                {contact.otherContactPhoneNumber.length > 1 && (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleRemovePhoneNumber(contactIndex, phoneIndex)}
                                  >
                                    <img src={Trash} style={{ height: "20px", width: "27px" }} />
                                  </div>
                                )}

                                {/* Add Phone Button */}
                                <div
                                  className={styles.plusii}
                                  onClick={() => handleAddPhoneNumber(contactIndex)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={plusBluehover} style={{ height: "22px", width: "24px" }} />
                                </div>
                              </div>
                            ))}
                          </div>


                        </div>
                      }
                    </div>
                  ))}

                  <div style={{ display: "flex" }}>
                    <div
                      className={styles.addduuucon}
                      style={{ marginTop: "12px", cursor: "pointer" }}
                      onClick={handleAddContact}
                    >
                      + Add More Contacts
                    </div>
                  </div>
                </div>
              <div className={styles.formName}>
                <TextField
                  style={{ width: "765px" }}
                  id="outlined-basic"
                  label="Address"
                  variant="outlined"
                  size="small"
                  value={leadAddress}
                  onChange={(e) => {
                    if (e.target.value.length < 1024) {
                      setLeadAddress(e.target.value);
                    }
                  }}
                />
              </div>
              <div className={styles.formName}>
                <TextField
                  style={{ width: "359px" }}
                  id="outlined-basic"
                  label="City"
                  size="small"
                  variant="outlined"
                  // isRequired={true}
                  value={leadCity}
                  onChange={(e) => leadCityHandle(e)}
                />

                <TextField
                  style={{ width: "359px" }}
                  className={textfieldClass}
                  id="outlined-basic"
                  label="Zip/Postal code"
                  variant="outlined"
                  size="small"
                  value={leadCityCode}
                  error={errorPin}
                  helperText={
                    errorPin
                      ? leadCityCode.length === 0
                        ? "Please enter city code"
                        : "Invalid Pin Code"
                      : ""
                  }
                  onChange={(e) => handlePinCodeChange(e)}
                  InputProps={{
                    inputMode: "numeric",
                    inputProps: {
                      style: {
                        appearance: "textfield", // This hides the spinner in most browsers
                        MozAppearance: "textfield", // Firefox
                        WebkitAppearance: "none", // Safari and Chrome
                      },
                    },
                  }}
                />

              </div>
              {/* aman */}
              <div className={styles.formName}>
                <FormControl size="small">

                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    style={{ width: "359px", height: "50px" }}
                    options={filteredWorkScopeName
                      ?.slice()
                      .sort((a, b) => a.type.localeCompare(b.type))
                      .map((workScope) => {
                        return {
                          label: workScope.type,
                        };
                      })}
                    onChange={(_object, value) => {
                      setWorkScope(value?.label);
                    }}
                    inputValue={inputValueWorkScope}
                    onInputChange={handleWorkScopeInputChange}
                    noOptionsText={
                      <span onClick={saveworkScope} style={{ cursor: "pointer" }}>
                        {inputValueWorkScope ? (
                          <span>
                            Create :{" "}
                            <span style={{ color: "blue" }}>
                              "{inputValueWorkScope}"
                            </span>
                          </span>
                        ) : (
                          "No options"
                        )}
                      </span>
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Work Scope"
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            height: '50px',
                            padding: '0 14px', // Adjust padding if needed
                          },
                        }}
                        onKeyDown={(event) => {
                          if (
                            event.key === "Enter" &&
                            !filteredWorkScopeName.some(
                              (option) => option.type === inputValueWorkScope
                            )
                          ) {
                            saveworkScope();

                          }
                        }}
                      />
                    )}
                  />
                </FormControl>
                {/* AmanHere */}
                <TextField
                  style={{ width: "359px", height: "50px" }}
                  id="outlined-basic"
                  label="Budget"
                  size="small"
                  variant="outlined"
                  value={budget}
                  onChange={(e) => handleBudget(e)}
                  InputProps={{
                    inputMode: "numeric",
                    style: {
                      height: '50px',
                    },
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ height: '50px', display: 'flex', alignItems: 'center' }}
                      >
                        {profileData?.CompanySettingDetails?.currencySymbol}
                      </InputAdornment>
                    ),
                    inputProps: {
                      style: {
                        height: '50px',
                        padding: '0 14px', // Adjust padding if needed
                      },
                    },
                  }}
                />
              </div>
              <div
                className={styles.formName}
                style={{
                  // background: "yellow",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "1rem",
                }}
              >
                {userData?.rolesAssigned?.crm?.canAssign && editLeadBoolean &&
                  teamAssignList.map((teamAssign, index) =>  {const selectedTeamIds = teamAssignList
                  .filter((_, assignIndex) => assignIndex !== index) // Exclude the current dropdown
                  .map(assign => assign.selectedTeamObject?._id); // Get selected team IDs from selectedTeamObject
            
                return (
                    <div   className={styles.formName}
                    style={{
                      // background: "yellow",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "1rem",
                    }} key={index}>
                      {console.log(teamAssignList,"Wedwed")}
                      {/* Select Team Dropdown */}
                      <FormControl size="small">
                        <Autocomplete
                          sx={{
                            width: '359px',
                            '& .MuiInputBase-root': {
                              height: '50px',
                              minHeight: '50px'
                            },
                            '& .MuiOutlinedInput-root': {
                              height: '50px',
                              minHeight: '50px'
                            }
                          }}
                          style={{ width: "359px", height: "50px" }}
                          disablePortal
                          id={`team-name-dropdown-${index}`}
                          disabled={storeTeams?.length === 0}
                          options={storeTeams
                            ?.filter((team) => !selectedTeamIds.includes(team._id)) // Exclude teams that are already selected
                            ?.map((team) => ({
                              label: team.name,
                              _id: team._id,
                              cId: team.cId
                            }))}
                          onChange={(event, value) => handleTeamChange(index, value)}
                          value={teamAssign.selectedTeam}
                          renderInput={(params) => (
                            <TextField {...params} size="small" style={{ height: "50px" }} label="Select Team" />
                          )}
                        />
                      </FormControl>

                      {/* Assign To Dropdown */}
                      <FormControl size="small">
                        <Autocomplete
                          sx={{
                            width: '359px',
                            '& .MuiInputBase-root': {
                              height: '50px',
                              minHeight: '50px'
                            },
                            '& .MuiOutlinedInput-root': {
                              height: '50px',
                              minHeight: '50px'
                            }
                          }}
                          style={{ width: "359px", height: "50px" }}
                          disablePortal
                          id={`assign-to-dropdown-${index}`}
                          disabled={!teamAssign.selectedTeam && storeTeams?.length > 0}
                          options={[
                            defaultValue,
                            ...teamLeadArr?.map((teamLead) => ({
                              label: teamLead.fullName,
                              _id: teamLead._id,
                              email: teamLead.email
                            }))
                          ]}
                          onChange={(event, value) => handleAssignToChange(index, value)}
                          onOpen={() => handleAssignToDropdownToggle(index)}
                          value={teamAssign.assignedToName}
                          renderInput={(params) => (
                            <TextField {...params} size="small" style={{ height: "50px" }} label="Assign To" />
                          )}
                        />
                      </FormControl>
                    </div>
                  )})
                }
              </div>
           {storeTeams?.length > 0 && <div style={{width:"100%",display:"flex",gap:"5px",color:"#0084EF",fontSize:"16px",fontWeight:"400", justifyContent: "end"}}> 
            <div style={{cursor:"pointer"}} onClick={handleAddMoreTeam}> <img src={PlusBlue} alt=""/><span>Add More Team</span></div>
             </div>}
              <div
                className={styles.formName}
                style={{
                  // background: "yellow",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "1rem",
                }}
              >
                <TextField
                  style={{ width: "359px", height: "50px" }}
                  id="outlined-basic"
                  label="Area"
                  size="small"
                  variant="outlined"
                  // isRequired={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Select
                          variant="standard"
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={areaUnit}
                          renderValue={() => areaUnit}
                          label="Unit"
                          // onChange={handleChange}
                          InputProps={{
                            disableUnderline: true, // <== added this
                          }}
                          disableUnderline
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxHeight: 150,
                                "&.MuiPaper-root": {
                                  left:
                                    window.screen.width < 1400
                                      ? "48.5vw !important"
                                      : "52.5vw !important",
                                },
                              },
                            },
                          }}
                        >
                          <MenuItem
                            sx={{ minWidth: 200 }}
                            onClick={() => setAreaUnit("sqft")}
                            value={"sqft"}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className={styles.areaUnit}>Sqft</div>
                            <div className={styles.areaDescription}>
                              Square feet
                            </div>
                          </MenuItem>
                          <MenuItem
                            sx={{ minWidth: 200 }}
                            onClick={() => setAreaUnit("sqm")}
                            value={"sqm"}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className={styles.areaUnit}>Sqm</div>
                            <div className={styles.areaDescription}>
                              Square meter
                            </div>
                          </MenuItem>
                          <MenuItem
                            sx={{ minWidth: 200 }}
                            onClick={() => setAreaUnit("Sq yds")}
                            value={"Sq yds"}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className={styles.areaUnit}>Sq yds</div>
                            <div className={styles.areaDescription}>
                              Square Yards
                            </div>
                          </MenuItem>
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                  value={leadArea}
                  onChange={handleLeadArea}
                />
                {editLeadBoolean && (
                  <FormControl size="small">

                    <Autocomplete
                      disablePortal
                      sx={{
                        width: '359px',
                        '& .MuiInputBase-root': {
                          height: '50px',
                          minHeight: '50px',
                        },
                        '& .MuiOutlinedInput-root': {
                          height: '50px',
                          minHeight: '50px',
                        },
                      }}
                      id="combo-box-demo"
                      style={{ width: "359px", height: "50px" }}
                      options={filteredLeadSourceName
                        ?.slice()
                        .sort((a, b) => a.leadSource.localeCompare(b.leadSource))
                        .map((leadWorkType) => {
                          return {
                            label: leadWorkType.leadSource,
                          };
                        })}
                      onChange={(_object, value) => {
                        setSelectedLeadSource(value?.label);
                      }}
                      inputValue={inputValueLeadSource}
                      onInputChange={handleInputValueInputChange}
                      noOptionsText={
                        <span
                          onClick={saveLeadSource}
                          style={{ cursor: "pointer" }}
                        >
                          {inputValueLeadSource && editLeadBoolean ? (
                            <span>
                              Create :{" "}
                              <span style={{ color: "blue" }}>
                                "{inputValueLeadSource}"
                              </span>
                            </span>
                          ) : (
                            "No options"
                          )}
                        </span>
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Lead Source"
                          onKeyDown={(event) => {
                            if (
                              event.key === "Enter" &&
                              editLeadBoolean &&
                              !filteredLeadSourceName.some(
                                (option) =>
                                  option.leadSource === inputValueLeadSource
                              )
                            ) {
                              saveLeadSource();
                            }
                          }}
                        />
                      )}
                    />
                  </FormControl>
                )}

                {/* adding other field */}

                {editLeadBoolean && (
                  <FormControl size="small">

                    <Autocomplete
                      sx={{
                        width: '359px',
                        '& .MuiInputBase-root': {
                          height: '50px',
                          minHeight: '50px',
                        },
                        '& .MuiOutlinedInput-root': {
                          height: '50px',
                          minHeight: '50px',
                        },
                      }}
                      disablePortal
                      id="combo-box-demo"
                      style={{ width: "359px", height: "50px" }}
                      options={filteredSourceName
                        ?.slice()
                        .sort((a, b) => a.sourceName.localeCompare(b.sourceName))
                        .map((workScope) => {
                          return {
                            label: workScope.sourceName,
                          };
                        })}
                      onChange={(_object, value) => {
                        setSelectedSourceName(value?.label);
                      }}
                      inputValue={inputValueScopeName}
                      onInputChange={handleScopeNameInputChange}
                      noOptionsText={
                        <span
                          onClick={savesourceName}
                          style={{ cursor: "pointer" }}
                        >
                          {inputValueScopeName && editLeadBoolean ? (
                            <span>
                              Create :{" "}
                              <span style={{ color: "blue" }}>
                                "{inputValueScopeName}"
                              </span>
                            </span>
                          ) : (
                            "No options"
                          )}
                        </span>
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Source Name"
                          onKeyDown={(event) => {
                            if (
                              event.key === "Enter" &&
                              editLeadBoolean &&
                              !filteredSourceName.some(
                                (option) =>
                                  option.sourceName === inputValueScopeName
                              )
                            ) {
                              savesourceName();
                            }
                          }}
                        />
                      )}
                    />
                  </FormControl>
                )}

              </div>
            </div>)}
          {option === "Corporate" && (
            <div
              className={styles.offcanvasBody}
              // className={styles.addLeadFields}
              style={{ alignItems: "baseline" }}
            >
              {console.log(data?.uniqueFields, corpData, "xqxq")}
              <div style={{ paddingTop: "10px" }}>
                <TextField
                  style={{ width: "46.7rem" }}
                  id="outlined-basic"
                  label="Company Name"
                  size="small"
                  helperText={companyNameError ? "Please enter company name" : ""}
                  variant="outlined"
                  required
                  error={companyNameError} // Error state
                  value={leadCompanyName} // Controlled value
                  InputProps={{
                    inputMode: "text",
                  }}
                  onChange={leadCompanyNameHandle} // Event handler for input change
                />
              </div>
              {console.log(contacts, leadCompanyName, "shwetaasharmaa")}
              <div className={styles.primryCon}>Primary Contact</div>
              <div>
                <div>
                  {contacts?.map((contact, contactIndex) => (
                    <div key={contactIndex} style={{ marginBottom: "20px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent:
                            contactIndex === 1 ? "space-between" : "flex-end",
                        }}
                      >
                        {contactIndex == 1 && (
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "500",
                              color: "#212529",
                              marginBottom: "10px",
                            }}
                          >
                            Other Contact
                          </div>
                        )}
                        {contacts?.length > 1 && contactIndex != 0 && (
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "#CC0000",
                              cursor: "pointer",
                            }}
                            onClick={() => handleRemoveContact(contactIndex)}
                          >
                            Remove
                          </div>
                        )}
                      </div>
                      {contactIndex == 0 ?
                        <div>
                          <div style={{ display: "flex", gap: "31px" }}>
                            <TextField
                              style={{ width: "359px", borderRadius: "8px" }}
                              id={`first-name-${contactIndex}`}
                              label="First Name"
                              variant="outlined"
                              required
                              error={Boolean(contact.errors?.otherContactFirstName)}
                              helperText={contact.errors?.otherContactFirstName || ""}
                              value={contact.otherContactFirstName}
                              onChange={(e) =>
                                handleInputChange(
                                  contactIndex,
                                  "otherContactFirstName",
                                  e.target.value
                                )
                              }
                            />
                            <TextField
                              style={{ width: "359px", borderRadius: "8px" }}
                              id={`last-name-${contactIndex}`}
                              label="Last Name"
                              variant="outlined"
                              error={Boolean(contact.errors?.otherContactLastName)}
                              helperText={contact.errors?.otherContactLastName || ""}
                              value={contact.otherContactLastName}
                              onChange={(e) =>
                                handleInputChange(
                                  contactIndex,
                                  "otherContactLastName",
                                  e.target.value
                                )
                              }
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              gap: "31px", marginTop: "21px",
                            }}
                          >
                            <TextField
                              style={{ width: "359px", borderRadius: "8px" }}
                              id={`email-${contactIndex}`}
                              label="Email Address"
                              variant="outlined"
                              // required
                              error={Boolean(contact.errors?.otherContactEmailId)}
                              helperText={contact.errors?.otherContactEmailId || ""}
                              value={contact.otherContactEmailId}
                              onChange={(e) =>
                                handleInputChange(
                                  contactIndex,
                                  "otherContactEmailId",
                                  e.target.value
                                )
                              }
                            />
                            <TextField
                              style={{ width: "359px", borderRadius: "8px" }}
                              id={`designation-${contactIndex}`}
                              label="Designation Name"
                              variant="outlined"
                              // required
                              error={Boolean(contact.errors?.otherContactDesginationName)}
                              helperText={contact.errors?.otherContactDesginationName || ""}
                              value={contact.otherContactDesginationName || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  contactIndex,
                                  "otherContactDesginationName",
                                  e.target.value
                                )
                              }
                            />
                          </div>

                          <div style={{
                            display: "flex", gap: "31px",
                            flexDirection: "column", marginTop: "21px",
                          }}>

                            {contact.otherContactPhoneNumber.map((phone, phoneIndex) => (
                              <div
                                key={phoneIndex}
                                style={{
                                  display: "flex",
                                  gap: "13px",
                                  alignItems: "center",
                                }}
                              >

                                <TextField
                                  style={{ width: "328px" }}
                                  label="Phone Number"
                                  required
                                  value={phone}
                                  error={Boolean(contact.errors?.otherContactPhoneNumber[phoneIndex])}
                                  helperText={
                                    contact.errors?.otherContactPhoneNumber[phoneIndex] || ""
                                  }
                                  onChange={(e) =>
                                    handlePhoneNumberChangeNew(
                                      contactIndex,
                                      phoneIndex,
                                      e.target.value
                                    )
                                  }
                                  InputProps={{
                                    inputMode: "numeric",
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {phoneCode}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                {contact.otherContactPhoneNumber.length > 1 && (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleRemovePhoneNumber(
                                        contactIndex,
                                        phoneIndex
                                      )
                                    }
                                  >
                                    <img
                                      src={Trash}
                                      style={{ height: "20px", width: "27px" }}
                                    />
                                  </div>
                                )}
                                <div
                                  className={styles.plusii}
                                  onClick={() => handleAddPhoneNumber(contactIndex)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={plusBluehover}
                                    style={{ height: "22px", width: "24px" }}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        :
                        <div>
                          <div
                            style={{
                              display: "flex",
                              gap: "31px",
                            }}
                          >
                            <TextField
                              style={{ width: "359px", borderRadius: "8px" }}
                              id={`contactType-${contactIndex}`}
                              label="Contact Type"
                              variant="outlined"
                              placeholder="e.g Lead POC"
                              required
                              error={Boolean(contact.errors?.otherContactType)}
                              helperText={contact.errors?.otherContactType || ""}
                              value={contact.otherContactType}
                              onChange={(e) =>
                                handleInputChange(
                                  contactIndex,
                                  "otherContactType",
                                  e.target.value
                                )
                              }
                            />
                            <TextField
                              style={{ width: "359px", borderRadius: "8px" }}
                              id={`designation-${contactIndex}`}
                              label="Designation Name"
                              variant="outlined"
                              // required
                              error={Boolean(contact.errors?.otherContactDesginationName)}
                              helperText={contact.errors?.otherContactDesginationName || ""}
                              value={contact.otherContactDesginationName || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  contactIndex,
                                  "otherContactDesginationName",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div style={{ display: "flex", gap: "31px", marginTop: "21px", }}>
                            <TextField
                              style={{ width: "359px", borderRadius: "8px" }}
                              id={`first-name-${contactIndex}`}
                              label="First Name"
                              variant="outlined"
                              required
                              error={Boolean(contact.errors?.otherContactFirstName)}
                              helperText={contact.errors?.otherContactFirstName || ""}
                              value={contact.otherContactFirstName}
                              onChange={(e) =>
                                handleInputChange(
                                  contactIndex,
                                  "otherContactFirstName",
                                  e.target.value
                                )
                              }
                            />
                            <TextField
                              style={{ width: "359px", borderRadius: "8px" }}
                              id={`last-name-${contactIndex}`}
                              label="Last Name"
                              variant="outlined"
                              error={Boolean(contact.errors?.otherContactLastName)}
                              helperText={contact.errors?.otherContactLastName || ""}
                              value={contact.otherContactLastName}
                              onChange={(e) =>
                                handleInputChange(
                                  contactIndex,
                                  "otherContactLastName",
                                  e.target.value
                                )
                              }
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap", // Allows wrapping to the next line
                              gap: "15px",
                              marginTop: "21px",
                            }}
                          >
                            {/* Email Address Field */}
                            <TextField
                              style={{ width: "359px", borderRadius: "8px" }}
                              id={`email-${contactIndex}`}
                              label="Email Address"
                              variant="outlined"
                              // required
                              error={Boolean(contact.errors?.otherContactEmailId)}
                              helperText={contact.errors?.otherContactEmailId || ""}
                              value={contact.otherContactEmailId}
                              onChange={(e) =>
                                handleInputChange(contactIndex, "otherContactEmailId", e.target.value)
                              }
                            />

                            {/* First Phone Number */}
                            {contact.otherContactPhoneNumber.map((phone, phoneIndex) => (
                              <div
                                key={phoneIndex}
                                style={{
                                  display: "flex",
                                  gap: "13px",
                                  alignItems: "center",
                                  marginLeft: "16px",
                                  marginTop: phoneIndex === 0 ? "0px" : "21px", // Push the next phone number to the next line
                                  width: phoneIndex === 0 ? "360px" : "100%", // Make sure the first phone number stays on the same line as email
                                }}
                              >
                                {/* Phone Number Input */}
                                <TextField
                                  style={{ width: "328px" }}
                                  label="Phone number"
                                  required
                                  value={phone}
                                  error={Boolean(contact.errors?.otherContactPhoneNumber[phoneIndex])}
                                  helperText={
                                    contact.errors?.otherContactPhoneNumber[phoneIndex] || ""
                                  }
                                  onChange={(e) =>
                                    handlePhoneNumberChangeNew(contactIndex, phoneIndex, e.target.value)
                                  }
                                  InputProps={{
                                    inputMode: "numeric",
                                    startAdornment: (
                                      <InputAdornment position="start">{phoneCode}</InputAdornment>
                                    ),
                                  }}
                                />

                                {/* Remove Phone Button */}
                                {contact.otherContactPhoneNumber.length > 1 && (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleRemovePhoneNumber(contactIndex, phoneIndex)}
                                  >
                                    <img src={Trash} style={{ height: "20px", width: "27px" }} />
                                  </div>
                                )}

                                {/* Add Phone Button */}
                                <div
                                  className={styles.plusii}
                                  onClick={() => handleAddPhoneNumber(contactIndex)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={plusBluehover} style={{ height: "22px", width: "24px" }} />
                                </div>
                              </div>
                            ))}
                          </div>


                        </div>
                      }
                    </div>
                  ))}

                  <div style={{ display: "flex" }}>
                    <div
                      className={styles.addduuucon}
                      style={{ marginTop: "12px", cursor: "pointer" }}
                      onClick={handleAddContact}
                    >
                      + Add More Contacts
                    </div>
                  </div>
                </div>

                {corporateFieldsCluster.map((item, index) => (
                  <div className={styles.addLeadFieldsRow} key={index}>
                    {console.log(item, "dcwecxwedcwecxwe")}
                    {item && item.length === 2 ? (
                      item.map((field, index) => (
                        <div className={styles.addLeadFieldsTwo}>
                          {console.log(field, "cervccervc")}
                          <GetComponentCorporate
                            field={field}
                            corpData={corpData}
                            onChange={changeCorporateValueHandler}
                          />
                        </div>
                      ))
                    ) : (
                      <div className={styles.addLeadFieldsOne}>
                        {console.log(item[0], "qwswedxqwswedx")}
                        <GetComponentCorporate
                          field={item[0]}
                          corpData={corpData}
                          onChange={changeCorporateValueHandler}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* <Modal.Footer>
            <div className={styles.closeBtn}>
              <button
                className={styles.createModalClose}
                onClick={handleDiscardModalOpen}
              >
                <span
                  style={{
                    color: "#C9C9C9",
                  }}
                >
                  Cancel{" "}
                </span>
              </button>
              <button
                disabled={buttonClickedOnce}
                className={styles.createModalSave}
                onClick={handleSubmit}
              >
                <span
                  style={{
                    color: "#FFF",
                  }}
                >
                  {!buttonClickedOnce ? (
                    "Add Lead"
                  ) : (
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ height: "1.25rem", width: "1.25rem" }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  )}
                </span>
              </button>
            </div>
          </Modal.Footer> */}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddLeadModalNew;
