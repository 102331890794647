import React, { useEffect, useState } from "react";
import { PiQuestionLight } from "react-icons/pi";
import { PiNotePencilLight } from "react-icons/pi";
import { PiTrashLight } from "react-icons/pi";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import AddStatusModal from "./modals/AddStatusModal";
import axios from "axios";
import { constants } from "../../../piservices/constants";
import EditStatusModal from "./modals/EditStatusModal";
import { getReq, putReq } from "../../../piservices/apis";
import "./leadManagementSettings.css";
import ConfirmDelete from "../Modals/ConfirmDelete";
import toast from "react-hot-toast";

const dummyStatusList = [
  { id: 1, stageName: "Unassigned", statusName: "Follow up later" },
  { id: 2, stageName: "Unassigned", statusName: "Client went cold" },
  { id: 3, stageName: "Unassigned", statusName: "Not in charge of decision" },
  { id: 4, stageName: "Unassigned", statusName: "Follow Up" },
  { id: 5, stageName: "Unassigned", statusName: "Positive reply" },
];

const Status = () => {
  const [status, setStatus] = useState(dummyStatusList);

  const [showAddStatus, setShowAddStatus] = useState(false);
  const [showEditStatus, setShowEditStatus] = useState(false);
  const [statusData, setStatusData] = useState(null);
  const [stages, setStages] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [page, setPage] = useState(1);
  const [editLeadBoolean, setEditLeadBoolean] = useState(false);
  const [limit, setLimit] = useState(12);
  const [totalStatus, setTotalStatus] = useState(0);
  const [defaultIds, setDefaultIds] = useState([2, 3, 4, 6]);
  const [deleted, setDeleted] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);

  useEffect(() => {
    if (!showAddStatus) {
      fetchStatus();
    }
  }, [showAddStatus, page, limit]);

  useEffect(() => {
    if (!showEditStatus) {
      fetchStatus();
    }
  }, [showEditStatus]);

  const getUserbyId = async (id) => {
    const res = await getReq(
      `${constants.CRM_API}/user/getUserById?userId=${localStorage.getItem("userId")}`
    );
    if (res && !res.error) {
      console.log(res?.data?.data, "res!")
      setEditLeadBoolean(res?.data?.data?.rolesAssigned?.crm?.edit);
    } else {
      console.log(res.error);
    }
  };
  useEffect(() => {
   getUserbyId();
  }, [])
  
 
  useEffect(() => {
    if (deleted) {
      fetchStatus();
      setDeleted(false);
    }
  }, [deleted]);

  const closeDeleteModal = () => {
    setShowDelete(false);
  };

  // get status list
  const fetchStatus = async () => {
    try {
      let response = await axios.get(
        `${
          constants.CRM_API
        }/user/get-pipeline-pagination?userId=${localStorage.getItem(
          "userId"
        )}&page=${page}&limit=${limit}`
      );
      let data = response?.data?.data;
      console.log(data, "data!");
      setStatus(data?.stages);
      setMaxPages(data?.pagination?.totalPages);
      setCurrPage(Number(data?.pagination?.page));
      setTotalStatus(data?.pagination?.total);

      response = await axios.get(
        `${constants.CRM_API}/user/get-pipeline?userId=${localStorage.getItem(
          "userId"
        )}`
      );
      data = response?.data?.data?.stages;
      // console.log(data, "data!");
      const stageData = [];
      for (let i of data) {
        stageData.push({ id: i?._id, stageName: i?.stageName });
      }
      setStages(stageData);
      // const statusList = [];
      // const stageList = [];
      // for (let i of data) {
      //   stageList.push({ id: i._id, stageName: i.stageName });
      //   for (let j of i.status) {
      //     statusList.push({
      //       stageName: i.stageName,
      //       statusName: j.statusName,
      //     });
      //   }
      // }
      // setStages(stageList);
      // setStatus(statusList);
    } catch (err) {
      console.log(err);
    }
  };

  // delete a particular status
  const deleteParticularStatus = async (status) => {
    console.log(status,"hucnwuie")
    const res = await putReq(
      `${constants.CRM_API}/user/delete-status?userId=${localStorage.getItem(
        "userId"
      )}`,
      {
        statusId: status.statusId,
        stageId:
          stages[
            stages.findIndex((item) => item.stageName === status?.stageName)
          ].id,
      }
    );
    if (res && !res.error) {
      console.log(res?.data?.data, "SDSfgwshew");
      fetchStatus()
      // setStoreAverage(res?.data?.data)
    } else {
      console.log(res.error);
    }
    setDeleted(true);
  };

  // show and hide add status modal
  const addStatusModalShow = () => {
    setShowAddStatus(true);
  };
  const addStatusModalHide = () => {
    setShowAddStatus(false);
  };

  // show and hide edit status modal
  const editStatusModalShow = (item) => {
    setStatusData(item);
    setShowEditStatus(true);
  };
  const editStatusModalHide = () => {
    setShowEditStatus(false);
  };

  // set previous page
  const previousPageHandler = () => {
    setPage((prev) => prev - 1);
  };

  // set next page
  const nextPageHandler = () => {
    setPage((prev) => prev + 1);
  };

  // set last page
  const lastPageHandler = () => {
    setPage(maxPages);
  };

  // console.log(status, "sta1");

  return (
    <>
      <AddStatusModal
        show={showAddStatus}
        hide={addStatusModalHide}
        stages={stages}
      />
      <EditStatusModal
        show={showEditStatus}
        hide={editStatusModalHide}
        stages={stages}
        data={statusData}
      />
      <ConfirmDelete
        show={showDelete}
        hide={closeDeleteModal}
        name="Status"
        action={deleteParticularStatus.bind(null, deleteItem)}
      />
      <div
        style={{
          position: "fixed",
          left: "15%",
          width: "80%",
          paddingTop: "1rem",
          borderTop: "1px solid #EEEEEF",
          borderLeft: "1px solid #EEEEEF",
          borderBottom: "1px solid #EEEEEF",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "2rem",
            marginRight: "4rem",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "5px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3
              style={{
                fontWeight: "600",
                fontSize: "24px",
                color: "#212529",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              Create your own custom status
            </h3>
            <Tooltip
              id="manage-custom-fields-tooltip"
              style={{ width: "350px", fontWeight: "600", fontSize: "12px" }}
            />
            <PiQuestionLight
              style={{ height: "22px", width: "22px", color: "#212529" }}
              data-tooltip-id="manage-custom-fields-tooltip"
              data-tooltip-content="Customize lead status effortlessly: Create, edit, and 
delete fields to tailor your data entry experience."
              data-tooltip-place="top"
            />
          </div>
          <button
            style={{
              border: "1px solid #0084EF",
              borderRadius: "8px",
              padding: "8px 24px 8px 24px",
              backgroundColor: "#FFFFFF",
              fontWeight: "500",
              cursor:editLeadBoolean ? "cursor" : "default",
              fontSize: "14px",
              color: "#0084EF",
            }}
            onClick={()=>{editLeadBoolean ? addStatusModalShow() : toast.error("Access disabled by Admin")}}
          >
            Add Status
          </button>
        </div>
        <div
          style={{
            marginTop: "1.5rem",
            marginLeft: "2rem",
            marginRight: "2rem",
            overflowY: "auto",
            maxHeight: "59%",
            position: "relative",
          }}
        >
          <table style={{ width: "100%" }}>
            <thead
              style={{ color: "#64748B", fontWeight: "600", fontSize: "12px" }}
            >
              <tr>
                <th
                  style={{ borderBottom: "1px solid #E7EAEE", padding: "8px" }}
                >
                  S no.
                </th>
                <th
                  style={{ borderBottom: "1px solid #E7EAEE", padding: "8px" }}
                >
                  Stage Name
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #E7EAEE",
                    padding: "8px 0px 8px 8px",
                  }}
                >
                  Status Name
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #E7EAEE",
                    padding: "8px 0px 8px 8px",
                    textAlign: "center",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody
              style={{ color: "#191D23", fontWeight: "400", fontSize: "12px" }}
            >
              {status &&
                status.map((item, index) => (
                  <tr>
                    {/* {console.log(item, "dfgqwshj")} */}
                    <td
                      style={{
                        borderBottom: "1px solid #E7EAEE",
                        padding: "8px",
                      }}
                    >
                      {index + 1}.
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #E7EAEE",
                        padding: "8px",
                      }}
                    >
                      {item.stageName}
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #E7EAEE",
                        padding: "8px 0px 8px 8px",
                      }}
                    >
                      {item.statusName}
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #E7EAEE",
                        padding: "8px 0px 8px 8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          style={{
                            backgroundColor: "white",
                            border: "none",
                           color: "#343330",
                          }}
                          onClick={() => {
                            if (editLeadBoolean) {
                              editStatusModalShow.bind(null, item);
                            } else {
                              toast.error("Access disabled by Admin");
                            }
                          }}
                          >
                          <PiNotePencilLight
                            style={{ height: "20px", width: "20px" }}
                          />
                        </button>
                        <Tooltip
                          id="deleteButton"
                          events={["click"]}
                          style={{
                            width: "186px",
                            fontWeight: "600",
                            fontSize: "12px",
                          }}
                        />
                        <button
                          style={{
                            backgroundColor: "white",
                            border: "none",
                            pointerEvents:
                              defaultIds.indexOf(item.statusId) !== -1 &&
                              "none",
                            color:
                              defaultIds.indexOf(item.statusId) !== -1
                                ? "#CCCCCC"
                                : "#343330",
                          }}
                          onClick={() => {
                            if (editLeadBoolean) {
                              setShowDelete(true);
                              setDeleteItem(item);
                            } else {
                              toast.error("Access disabled by Admin");
                            }
                          }}
                          
                          data-tooltip-id={item.permanent && "deleteButton"}
                          data-tooltip-content="This field can’t be deleted"
                          data-tooltip-place="top"
                        >
                          <PiTrashLight
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="CFpaginationMain">
          <div>
            {totalStatus === 0 ? 0 : (currPage - 1) * limit + 1}-
            {totalStatus < currPage * limit ? totalStatus : currPage * limit} of{" "}
            {totalStatus}
          </div>
          <div className="CFChangePages">
            <button
              disabled={currPage === 1 && true}
              className="CFPrevNext"
              onClick={previousPageHandler}
            >
              Previous
            </button>
            <span className="CFCurr">{currPage}</span>
            {currPage < maxPages - 1 && (
              <button onClick={nextPageHandler} className="CFPageButton">
                {currPage + 1}
              </button>
            )}
            {currPage !== maxPages && (
              <>
                {maxPages > 4 && <span className="CFPageButton">...</span>}
                <button onClick={lastPageHandler} className="CFPageButton">
                  {maxPages}
                </button>
              </>
            )}
            <button
              disabled={currPage === maxPages && true}
              className="CFPrevNext"
              onClick={nextPageHandler}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Status;
