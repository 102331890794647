import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Dropdown, Modal, Offcanvas, OverlayTrigger, Tooltip } from "react-bootstrap";
import { constants } from "../../piservices/constants";
import iconGreen from "../../Assets/iconGreen.svg";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  PiEnvelopeSimple,
  PiClipboardText,
  PiCalendar,
  PiClock,
  PiTag,
  PiCaretDown,
  PiPencilSimpleLine,
  PiUserCirclePlus,
  PiTrash,
  PiArrowsInSimple,
  PiCaretDoubleRight,
  PiX,
} from "react-icons/pi";
import { IoEllipse } from "react-icons/io5";
import dollar from "./images/currency.png";
import folder from "./images/FolderNotchPlus.png";
import { useNavigate, useParams } from "react-router-dom";
import "react-clock/dist/Clock.css";
import styles from "./ModuleCssFiles/lms.module.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import AssignLead from "./Modals/AssignLead";
import desquoContext from "../../picontext/DashBoard/desquoContext";
import { getReq, postReq, putReq } from "../../piservices/apis";
import useApiFetchCustomHook from "../../piservices/ApiFetchCustomHook";
import { notification, Select } from "antd";
import { getToken } from "../../piservices/authService";
import EditLead from "../Common/NewLayout/Modals/EditLead";
import toast from "react-hot-toast";
import crossBold from "./images/crossBold.svg";
import downArrowGrey from "./images/downArrowGrey.svg";
import ReactQuill from "react-quill";
import { RxCross1, RxCross2 } from "react-icons/rx";
import attachedFile from "./images/attach_file_add.svg";
import close from "./images/close.svg";
import "./newVersion.css";
import ConfirmCancel from "./Modals/ConfirmCancel";
import { formateDate } from "../../piservices/compCommon";
import { useSelector } from "react-redux";
import EditLeadModal from "./EditLeadModal";
import { formatDistanceToNow } from "date-fns";
import { selectedLeadData } from "../../Redux/Slices/specificleadSlice";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import useApiFetchCustomHookForEmailService from "../../piservices/ApiFetchCustomHookForEmailService";

const dummyLogHistory = [
  {
    updatedBy: "Animesh Mallick",
    createdAt: "2024-07-29T12:56:54.596Z",
    stageOld: "Unassigned",
    stageNew: "Prospecting",
    meetingType: "Call",
    callStatus: "Follow up",
    comment:
      "The client is interested and wants to set up a meeting next Monday.",
  },
  {
    updatedBy: "Animesh Mallick",
    dateTime: "25 June, 24 3:28 PM",
    prevStage: "Prospecting",
    stage: "Qualification",
    meetingType: "Call",
    callStatus: "Follow up",
    comment:
      "The client is interested and wants to set up a meeting next Monday.",
  },
  {
    name: "Animesh Mallick",
    dateTime: "25 June, 24 3:28 PM",
    meetingType: "Call",
    callStatus: "Follow up",
    comment:
      "The client is interested and wants to set up a meeting next Monday.",
  },
  {
    name: "Animesh Mallick",
    dateTime: "25 June, 24 3:28 PM",
    comment:
      "The client is interested and wants to set up a meeting next Monday.",
  },
];

const LeadManagementOffcanvas = ({
  show,
  init,
  hide,
  id,
  assignTo, setAssignTo,
  focusComment,
  setFocusComment,
  active, setActive,
  offcanvasRef,
  assignColor,
}) => {
  let { tab } = useParams();
  const quillRef = useRef(null);
  const uploadFileRef = useRef(null);
  const commentEL = document.getElementById("comment");

  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [details, setDetails] = useState({});
  const [isFocused, setIsFocused] = useState(false);
  const [addTags, setAddTags] = useState(false);
  const [tags, setTags] = useState([]);
  const [presentTags, setPresentTags] = useState([]);
  const [availTags, setAvailTags] = useState([]);
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [collapse, setCollapse] = useState(false);
  const [stageData, setStageData] = useState([]);
  const [showStage, setShowStage] = useState(false);
  const [selectedStage, setSelectedStage] = useState(null);
  const [editLeadBoolean, setEditLeadBoolean] = useState(false);
  const [selectAssignTo, setSelectAssignTo] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [showStatus, setShowStatus] = useState(false);
  const [callRemark, setCallRemark] = useState([
    { id: 0, remark: "Call" },
    { id: 1, remark: "Virtual Meeting" },
    { id: 2, remark: "Client Visit" },
  ]);
  const { sendEmailPushBellNotifications } = useApiFetchCustomHookForEmailService()
 
  const [showRemark, setShowRemark] = useState(false);
  const [selectedRemark, setSelectedRemark] = useState(callRemark[0]);
  const [tagSearch, setTagSearch] = useState("");
  const [filteredTags, setFilteredTags] = useState([]);
  const [refreshDetails, setRefreshDetails] = useState(true);
  const [logHistory, setLogHistory] = useState(dummyLogHistory);
  const [comment, setComment] = useState("");
  const [canUpdate, setCanUpdate] = useState(false);
  const [showAssignLead, setShowAssignedLead] = useState(false);
  // const [assignTo, setAssignTo] = useState(null);
  const [showEditLead, setShowEditLead] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // email modal
  const [openShareEmail, setOpenShareEmail] = useState(false);
  const [mailFiles, setMailFiles] = useState([]);
  const [templateName, setTemplateName] = useState("Select Template");
  const [allEmails, setAllEmails] = useState([]);
  const [tempmail, setTempmail] = useState("");
  const [members, setMembers] = useState([]);
  const [emailError, setEmailError] = useState(false);
  const [subjectForEmail, setSubjectForEmail] = useState("");
  const [mentionDropdownVisible, setMentionDropdownVisible] = useState(false);
  const [filteredMentions, setFilteredMentions] = useState([]);
  const [dummyfilteredMentions, setDummyFilteredMentions] =
    useState(filteredMentions);
  const dispatch = useDispatch()
  const [shareData, setShareData] = useState("");
  const [mentionQuery, setMentionQuery] = useState("");
  let toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons

    [{ list: "ordered" }, { list: "bullet" }],

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];
  let commonCss = {
    color: "#454545",
    fontSize: "0.875rem",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    whiteSpace: "nowrap",
    // marginLeft: "1rem",
    cursor: "pointer",
  };
  const [templates, setTemplates] = useState([
    {
      id: 1,
      name: "Template 1",
      subject: "this is the subject for Template 1",
      data: "This is the data for template 1",
    },
    {
      id: 2,
      name: "Template 2",
      subject: "this is the subject for Template 2",
      data: "This is the data for template 2",
    },
    {
      id: 3,
      name: "Template 3",
      subject: "this is the subject for Template 3",
      data: "This is the data for template 3",
    },
    {
      id: 4,
      name: "Template 4",
      subject: "this is the subject for Template 4",
      data: "This is the data for template 4",
    },
    {
      id: 5,
      name: "Template 5",
      subject: "this is the subject for Template 5",
      data: "This is the data for template 5",
    },
  ]);

  const context = useContext(desquoContext);
  const { setActiveLeadState } = context;

  const { apiFetch } = useApiFetchCustomHook();

  const { fullName } = useSelector(
    (state) => state.persist.profileDataDataValue
  );


  useEffect(() => {
    if (focusComment) {
      if (commentEL) {
        commentEL.focus();
        setFocusComment(false);
      }
    }
  }, [focusComment, commentEL]);
  const textRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    // Check if the text is overflowing
    if (textRef.current) {
      const isOverflow = textRef.current.scrollWidth > textRef.current.clientWidth;
      setIsOverflowing(isOverflow);
    }
  }, [details?.phoneNumber]);

  useEffect(() => {
    async function getLogs() {
      try {
        const response = await axios.get(
          `${constants.CRM_API}/user/get-lead-logs?leadId=${id}`
        );
        setLogHistory(response.data.data);
      } catch (err) {
        console.log(err);
      }
    }
    if (active === "logHistory") {
      getLogs();
    }
  }, [active]);

  // useEffect(() => {
  //   if (show) {
  //     setSelectedRemark(callRemark[0]);
  //     setComment("");
  //     setDate();
  //     setTime();
  //   }
  // }, [show]);

  useEffect(() => {
    if (show) {
      setSelectedRemark(callRemark[0]);
      setComment("");

      const recentUpdate = details?.leadUpdates?.reduce((latest, current) => {
        const currentUpdateDate = dayjs(current.updateDate, "DD-MM-YYYY");
        const latestUpdateDate = dayjs(latest.updateDate, "DD-MM-YYYY");

        return currentUpdateDate.isAfter(latestUpdateDate) ? current : latest;
      }, details?.leadUpdates[0]);

      setDate(recentUpdate ? dayjs(recentUpdate.updateDate, "DD-MM-YYYY") : null); // Set the most recent date
      setTime(recentUpdate ? dayjs(recentUpdate.updateTime, "h:mm A") : null);
    }
  }, [show, details]);
  // useEffect(() => {
  //   if (
  //     (selectedStage?.stageName === details?.leadStage?.stageName &&
  //       selectedStatus?.id === details?.leadStatus &&
  //       selectedRemark === callRemark[details?.todayRemark] &&
  //       comment === "" &&
  //       date === null &&
  //       time === null) &&
  //     (selectedStage === null &&
  //       selectedStatus === undefined &&
  //       selectedRemark === undefined &&
  //       comment === "" &&
  //       date === null &&
  //       time === null) &&
  //     assignTo == null // If assignTo is set, canUpdate should be true
  //   ) {
  //     setCanUpdate(false); // Set to true if any of these conditions are met
  //   } else {
  //     setCanUpdate(true); // Otherwise, set to false
  //   }
  // }, [
  //   selectedStage,
  //   selectedStatus,
  //   selectedRemark,
  //   selectAssignTo,
  //   assignTo,
  //   comment,
  //   date,
  //   time,
  //   details,
  // ]);
  useEffect(() => {
    if (assignTo != null) {
      setCanUpdate(true);
    }
  }, [assignTo])
  useEffect(() => {
    if (
      (selectedStage?.stageName === details?.leadStage?.stageName &&
        selectedStatus?.id === details?.leadStatus &&
        selectedRemark === callRemark[details?.todayRemark] &&
        comment == "" &&
        date == null &&
        time == null) ||
      (selectedStage == null &&
        selectedStatus == undefined &&
        selectedRemark == undefined &&
        comment == "" &&
        date == null &&
        time == null)
    ) {
      setCanUpdate(false);
    } else {
      setCanUpdate(true);
    }
  }, [
    selectedStage,
    selectedStatus,
    selectedRemark,
    comment,
    date,
    time,
    details,
  ]);

  console.log(assignTo, canUpdate, "uxtfaisyx")


  useEffect(() => {
    const fetchTags = async () => {
      if (addTags) {
        try {
          const response = await axios.get(
            `${constants.CRM_API
            }/user/get-lead-tag?userId=${localStorage.getItem("userId")}`
          );
          setTags(response.data.data);
        } catch (err) {
          console.log(err);
        }
      }
    };
    fetchTags();
  }, [addTags]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await axios.get(
          `${constants.CRM_API}/user/get-lead-tag?userId=${localStorage.getItem(
            "userId"
          )}`
        );
        setTags(response.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchTags();
    getData();
  }, []);

  useEffect(() => {
    const availTags = [];
    for (let i of tags) {
      if (presentTags.indexOf(i) === -1) {
        availTags.push(i);
      }
    }
    setAvailTags(availTags);
  }, [tags, presentTags]);

  useEffect(() => {
    if (showStage) {
      getData();
    }
  }, [showStage]);

  useEffect(() => {
    if (refreshDetails) {
      fetchLead();
    }
  }, [id, tags, refreshDetails]);

  useEffect(() => {
    setRefreshDetails(true);
  }, [id, tags]);

  useEffect(() => {
    setFilteredTags(
      availTags.filter((item) =>
        item.tagName.toLowerCase().includes(tagSearch.toLowerCase())
      )
    );
  }, [tagSearch, availTags]);

  const hideEditLead = () => {
    setShowEditLead(false);
  };

  useEffect(() => {
    setSelectedStage(details?.leadStage);
    setSelectedStatus(
      getStatusObj(details?.leadStage?.stageName, details?.leadStatus)
    );
    setSelectedRemark(callRemark[details?.todayRemark]);
  }, [details, stageData]);

  // get the lead data
  const fetchLead = async () => {
    const response = await axios.get(
      `${constants.CRM_API}/api/get-lead-by-id?id=${id}`
    );
    const data = response.data.data;
    setDetails(data);
    const presentTags = [];
    for (let i of data?.leadTag) {
      presentTags.push(tags[tags.findIndex((item) => item._id === i)]);
    }
    setPresentTags(presentTags);
    setRefreshDetails(false);
  };
  console.log(details?.leadUpdates, "cfdwrcfwerf")
  // get stage data
  const getData = async () => {
    const response = await axios.get(
      `${constants.CRM_API}/user/get-pipeline?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    const data = response.data.data.stages;
    setStageData(data);
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);

    // Format the date
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear().toString().slice(-2);

    // Format the time
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    return `${day} ${month} ${year} at ${hours}:${minutes} ${ampm}`;
  };
  const profileData = useSelector((state) => state.lmsSlice.profileData);
  // hide 'assign lead/s to' modal
  const hideAssignLeadHandler = () => {
    setShowAssignedLead(false);
  };
  // assign lead/s
  const assignLeadsHandler = () => {
    // setAssignLeads(selectedLeads);
    setShowAssignedLead(true);
  };
  const [allUserList,setAllUserList] = useState([]);
  useEffect(() => {
    const fetchUserData = async () => {
      const res =  await getReq(`${constants.CRM_API}/api/enterprise/get-team-member?userId=${localStorage.getItem(
              "userId"
            )}`
          )
      if (res) {

        const changedTeamMemberList = res?.data?.data?.map((item)=>{return {email:item?.email, _id:item?.memberId,fullName:item?.memberName }})
       setAllUserList(changedTeamMemberList);
      } else {
        //console.log("Something went wrong");
      }
    };
    // fetchPredData();
    fetchUserData();
  }, []);
  // delete a lead
  const deleteLeadHandler = async () => {
    const reassignReceivers = [];
    try {
      const leadName = details.firstName && details.lastName 
        ? `${details.firstName} ${details.lastName}` 
        : `${details?.otherContactDetails[0]?.otherContactFirstName} ${details?.otherContactDetails[0]?.otherContactLastName}`;
        const userId = localStorage.getItem("userId")?.trim();

      // API call to delete the lead
      await axios.post(
        `${constants.CRM_API}/user/edit-lead?id=${id}&userId=${userId}`,
        { isDeleted: true, deletedBy: userId }
      );
  console.log(reassignReceivers,profileData?.admins,profileData?.superAdmins,"fghjk")
  reassignReceivers.push(allUserList.find(item => item._id == details?.assignedTo));
       
  // Sending notifications
      sendEmailPushBellNotifications('Delete Lead', {
        '@leadName': leadName,
        '@leadname': leadName, // Assuming this is a duplicate key, keeping the same logic
        '@senderFirstName': profileData.firstName,
        '@sender': profileData?.fullName,
        '@Sender': profileData?.fullName
      }, 
      reassignReceivers, [], [...profileData?.admins], [...profileData?.superAdmins]);
  
      // Hide modal or UI component
      hide();
    } catch (err) {
      console.error("Error deleting lead:", err);
    }
  };
  

  const formatTime = (date) => {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };
  console.log(details, "cxewcsxswcd")
  // save upadted data
  const updateStatusHandler = async () => {
    console.log("just for check")
    try {
      const obj = {};
      obj["updatedBy"] = fullName;
      obj["leadId"] = id;
      obj["leadUpdates"] = [{}];
      if (selectedStage) {
        obj["leadStage"] = selectedStage._id;
        obj["stageOld"] = details?.leadStage?.stageName;
        obj["stageNew"] = selectedStage.stageName;
      }
      obj["assignedTo"] = selectAssignTo ? selectAssignTo : details?.assignedTo;
      if (selectAssignTo) {
        obj["leadUpdates"][0]["logText"] = `The Lead has been reassigned from ${details.assignToName} to ${assignTo}`;
      }
      if (selectedStatus) {
        obj["leadStatus"] = selectedStatus.id;
        obj["leadUpdates"][0]["callLeadStatus"] = selectedStatus.id;
      }
      if (selectedRemark) {
        obj["todayRemark"] = selectedRemark.id;
      }
      if (comment.length !== 0) {
        obj["leadUpdates"][0]["text"] = comment;
      }
      if (date && time) {
        const day = String(date["$d"].getDate()).padStart(2, "0");
        const month = String(date["$d"].getMonth() + 1).padStart(2, "0"); // Months are 0-based in JavaScript
        const year = date["$d"].getFullYear();
        const dateStr = `${day}-${month}-${year}`;

        let hours = time["$d"].getHours();
        const minutes = String(time["$d"].getMinutes()).padStart(2, "0");
        const ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const timeStr = `${hours}:${minutes} ${ampm}`;

        obj["leadUpdates"][0]["updateDate"] = dateStr;
        obj["leadUpdates"][0]["updateTime"] = timeStr;
      }
      await axios.post(`${constants.CRM_API}/user/newleadActions`, obj, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      getData();
      setRefreshDetails(true);
      setSelectedRemark(callRemark[0]);
      setComment("");
      hide();
      setShowModal(true);
      setDate();
      setTime();
    } catch (err) {
      console.log(err);
    }
  };

  // update the comment
  const commentHandler = (e) => {
    const value = e.target.value;
    setComment(value);
  };
  // switch to log history
  const makeLogHistoryActive = () => {
    if (active !== "logHistory") {
      setActive("logHistory");
    }
  };

  // switch to update status
  const makeUpdateStatusActive = () => {
    if (active !== "updateStatus") {
      setActive("updateStatus");
    }
  };

  // close add tags
  const addTagsCloseHandler = () => {
    if (addTags) {
      setAddTags(false);
    }
  };

  // can click inside add tags
  const propagationHandler = (e) => {
    e.stopPropagation();
  };

  // hide content in offcanvas
  const collapseHandler = () => {
    setCollapse((prev) => !prev);
  };

  // toggle stage selection
  const toggleStageHandler = () => {
    setShowStage((prev) => !prev);
  };

  // change the state
  const changeStageHandler = (stage) => {
    setSelectedStage(stage);
    // setShowStage(false);
  };

  // toggle status selection
  const toggleStatusHandler = () => {
    setShowStatus((prev) => !prev);
  };

  // change the status
  const changeStatusHandler = (status) => {
    setSelectedStatus(status);
    // setShowStatus(false);
  };

  // toggle call remark dropdown
  const toggleRemarkHandler = () => {
    setShowRemark((prev) => !prev);
  };

  // change call remark
  const changeRemarkHandler = (remark) => {
    setSelectedRemark(remark);
    // setShowRemark(false);
  };

  // calculate days ago
  function daysAgo(datetimeStr) {
    // Parse the given datetime string
    const givenDate = new Date(datetimeStr);

    // Get the current date and time
    const currentDate = new Date();

    // Calculate the difference in time (in milliseconds)
    const timeDifference = currentDate - givenDate;

    // Convert the time difference from milliseconds to days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  }

  // search for a tag
  const searchTagsHander = (e) => {
    const value = e.target.value;
    setTagSearch(value);
  };

  // remove tag for lead
  const removeTagHandler = (tagId) => {
    async function removeTag() {
      try {
        const obj = { leadId: id, tagId };
        await axios.put(
          `${constants.CRM_API
          }/user/remove-tag-from-lead?userId=${localStorage.getItem("userId")}`,
          obj
        );
        setRefreshDetails(true);
      } catch (err) {
        console.log(err);
      }
    }
    removeTag();
  };

  // add tag for lead
  const addTagHandler = (tagId) => {
    async function addTag() {
      try {
        const obj = { leadId: id, tagId };
        await axios.post(
          `${constants.CRM_API
          }/user/add-tag-to-lead?userId=${localStorage.getItem("userId")}`,
          obj
        );
        setRefreshDetails(true);
      } catch (err) {
        console.log(err);
      }
    }
    addTag();
  };

  const createProjectAndGoToQuotation = async () => {
    const activeLead = details;
    localStorage.setItem("leadId", activeLead?._id);
    if (activeLead?.projectId) {
      localStorage.setItem("projectId", activeLead?.projectId);
      window.open(
        `${constants.BASE_URL}/quotation/new-quotation/${activeLead?.projectId}?isLead=true`,
        "_blank"
      );
    } else {
      if (activeLead.email) {
        try {
          await axios
            .post(
              `${constants.PMT_API
              }/api/projects/addClient?userId=${localStorage.getItem(
                "userId"
              )}`,
              {
                email: activeLead.email,
                location: activeLead.address,
                name: activeLead.name,
                projectName: activeLead.name,
              }
            )
            .then(async (res) => {
              await axios
                .post(
                  `${constants.PMT_API
                  }/api/projects/add-project?userId=${localStorage.getItem(
                    "userId"
                  )}`,
                  {
                    clientId: res.data._id,
                    location: activeLead.address,
                    projectName: activeLead.name,
                    userId: localStorage.getItem("userId"),
                    isConfirmed: false,
                    phoneNumber: activeLead.phoneNumber,
                    clientName: activeLead.name,
                    isFromLead: true,
                    projectAdmin: activeLead?.assignedTo?._id,
                    teamMembers: [activeLead?.assignedTo?._id],
                  }
                )
                .then(async (response) => {
                  localStorage.setItem("projectId", response?.data?._id);
                  await setActiveLeadState({
                    ...activeLead,
                    projectId: response?.data?._id,
                  });
                  await postReq(
                    `${constants.CRM_API}/api/save-projectId-leads?leadId=${activeLead?._id}&projectId=${response?.data?._id}`
                  )
                    .then((res2) => {
                      apiFetch(tab);
                      window.open(
                        `${constants.BASE_URL}/quotation/new-quotation/${response.data._id}?isLead=true`,
                        "_blank"
                      );
                    })
                    .catch((err) => {
                      notification.error({
                        message: err.error || "client already exist",
                        duration: 1,
                      });
                    });
                })
                .catch((e) => {
                  notification.error({
                    message: e.error || "project already exists",
                    duration: 1,
                  });
                })
                .catch((err) => {
                  notification.error({
                    message: err.error || "client already exists",
                    duration: 1,
                  });
                });
            });
        } catch (e) {
          notification.error({
            message: e.error || "client already exists",
            duration: 1,
          });
        }
      } else {
        // setCurrentQuot("desQuot");
        // handleCloseModal();
        // handleOk2();
      }
    }
  };

  const getPipelineStage = (id) => {
    for (let i of stageData) {
      if (i._id === id) {
        return i.stageName;
      }
    }
    return "-";
  };

  const formatDate1 = (dateString) => {
    const date = new Date(dateString);

    // Get the day of the month
    const day = String(date.getDate()).padStart(2, "0");

    // Get the month name
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];

    // Get the year and take only the last two digits
    const year = String(date.getFullYear()).slice(-2);

    return `${day} ${month} ${year}`;
  };

  // for email
  const showEmailModalHandler = (data) => {
    if (data?.length > 0) {
      setAllEmails([data.email]);
      setOpenShareEmail(true);
      hide();
    } else {
      setAllEmails([details.email]);
      setOpenShareEmail(true);
      hide();
    }
  };
  const mailTemplateHandler = (data) => {
    setTemplateName(data.name);
    setSubjectForEmail(data.subject);
    setShareData(data.data);
  };
  const deleteEmail = (email) => {
    setAllEmails(allEmails.filter((e) => e !== email));
  };
  function setMailData(events) {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,100}$/i;
    const form4Example1 = document.getElementById("form4Example1");
    if (events.key === "Enter") {
      if (emailPattern.test(events.target.value)) {
        setAllEmails([...allEmails, events.target.value]);
        setTempmail("");
        setEmailError(false);
        events.target.value = "";
      } else {
        if (tempmail.length > 0) {
          setEmailError(true);
        } else {
          setEmailError(false);
        }

        form4Example1.setCustomValidity("I expect an e-mail, darling!");
        // setLeadEmail(inputValue);
        // events.target.value = ;
      }
    } else {
      if (tempmail.length == 0) {
        setEmailError(false);
      }
    }
  }
  const handleMentionSelect = (mention) => {
    const editor = quillRef.current?.getEditor();
    const mentionText = mention;
    const atIndex = shareData.lastIndexOf("@");
    const replacedText =
      shareData.slice(0, atIndex) +
      mentionText +
      shareData.slice(atIndex + mentionQuery.length + 1);

    setShareData(replacedText);
    setMentionDropdownVisible(false);
    setMentionQuery("");
  };
  const handleKeyDownTask2 = (event) => {
    if (event.key === "@") {
      setMentionDropdownVisible(true);
      setMentionQuery("");
    } else if (mentionDropdownVisible) {
      if (/[a-zA-Z]/.test(event.key)) {
        setMentionQuery((prev) => prev + event.key);
        // if (event.key === "Enter") {
        //   const mention = filteredMentions[0].fullName;
        //   const atIndex = shareData.lastIndexOf("@");
        //   const replacedText =
        //     shareData.slice(0, atIndex) +
        //     `${mention}` +
        //     shareData.slice(atIndex + mentionQuery.length + 1);

        //   setShareData(replacedText);
        // }
        // Append to query
      } else if (event.key === "Backspace") {
        setMentionQuery((prev) => prev.slice(0, -1)); // Handle backspace
      }
    }
  };
  function removeFile(index) {
    const filteredFiles = mailFiles?.filter((item, idx) => idx !== index);
    setMailFiles([...filteredFiles]);
  }
  const sendEmail = async () => {
    const res2 = await axios.post(
      `${constants.EMAIL_API}/api/e-service/send-email`,
      {
        subject: subjectForEmail,
        email: allEmails && allEmails,
        html: shareData,
        companyName: profileData?.CompanySettingDetails?.companyName,
        attachments: mailFiles,
      }
    );
    if (res2) {
      //   setShareSheet(false)
      toast.success(`Email Sent`);
      //   window.location.reload()
      setMailFiles([]);
    }
  };
  const [dropdownOpen, setDropdownOpen] = useState(false); // To manage dropdown visibility

  const uploadFile = async (event) => {
    const { files } = event.target;
    for (let i = 0; i < files.length; i++) {
      let filesToUpload = new FormData();
      filesToUpload.append("files", event.target.files[i]);
      const res = await putReq(`${constants.S3_API}/api/upload`, filesToUpload);
      if (res && !res.error) {
        setMailFiles([
          ...mailFiles,
          {
            filename: files[i].name,
            path: res.data.locations[0],
          },
        ]);
      } else {
        console.log(res.error);
      }
    }
  };
  const getUserbyId = async (id) => {
    const res = await getReq(
      `${constants.CRM_API}/user/getUserById?userId=${localStorage.getItem("userId")}`
    );
    if (res && !res.error) {
      console.log(res?.data?.data, "res!")
      setEditLeadBoolean(res?.data?.data?.rolesAssigned?.crm?.edit);
    } else {
      console.log(res.error);
    }
  };
  useEffect(() => {
    getUserbyId();
  }, [])

  const closeCancelModal = () => {
    setShowCancel(false);
  };

  function createFormat(dateStr) {
    // Parse the input date string to a Date object
    const date = new Date(dateStr);

    // Add 5 hours and 30 minutes
    date.setUTCMinutes(date.getUTCMinutes() + 330); // 5 hours * 60 + 30 minutes = 330 minutes

    // Define arrays for month names
    const months = [
      "January", "February", "March", "April", "May", "June", "July",
      "August", "September", "October", "November", "December"
    ];

    // Extract components from the Date object
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear().toString().slice(-2);
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Format the date string
    const formattedDate = `on ${day} ${month}, ${year} ${hours}:${minutes} ${ampm}`;

    return formattedDate;
  }

  const formatTimeAgo = (date) => {
    if (!date) return "Unknown date";
    const timeAgo = formatDistanceToNow(new Date(date), { addSuffix: true });
    return timeAgo;
  };

  const createdAt = details?.createdAt;
  const timeAgoText = formatTimeAgo(createdAt);

  const getStatus = (stage, statusId) => {
    const stage1 =
      stageData[stageData.findIndex((item) => item?.stageName === stage)];
    const status =
      stage1?.status[stage1?.status.findIndex((item) => item.id === statusId)]
        .statusName;
    return status;
  };

  const getStatusObj = (stage, statusId) => {
    if (stageData.length > 0 && stage && statusId !== (undefined || null)) {
      const stage1 =
        stageData[stageData.findIndex((item) => item.stageName === stage)];
      const status =
        stage1.status[stage1.status.findIndex((item) => item.id === statusId)];
      return status;
    }
  };
  const capitalizeFirstLetter = (name) => {
    if (!name) return "";
    return name
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" ");
  };
  useEffect(() => {
    async function fetchMembers() {
      try {
        const response = await axios.get(
          `${constants.CRM_API
          }/api/enterprise/get-team-leaders?userId=${localStorage.getItem(
            "userId"
          )}&access=1`
        );
        const data = response?.data?.data;
        setMembers(
          data?.map((item) => ({ value: item._id, label: item.fullName }))
        );
      } catch (err) {
        console.log(err);
      }
    }

    fetchMembers();
  }, []);
  const changeAssignedHandler = async (value, label) => {
    try {
      const response = await axios.get(
        `${constants.CRM_API}/api/get-lead-by-id`,
        {
          params: { id: details._id }
        }
      );
      const data = response.data.data;
      const obj = {
        assignedTo: value,
        logText: `The Lead has been reassigned from ${data.name} to ${label}`,
      };
      const updateResponse = await axios.post(
        `${constants.CRM_API}/user/edit-lead`,
        obj,
        {
          params: {
            id: details._id,
            userId: localStorage.getItem("userId"),
          }
        }
      );

      toast.success("Lead Assigned")
      getData();
      setRefreshDetails(true);
      hide();
      init();
    } catch (err) {
      if (err.response) {
        console.error('Error Response Data:', err.response.data);
        console.error('Error Response Status:', err.response.status);
        console.error('Error Response Headers:', err.response.headers);
      } else if (err.request) {
        // The request was made, but no response was received
        console.error('Error Request:', err.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error Message:', err.message);
      }
    }
  };
  const [showDropdown, setShowDropdown] = useState(false); // Control dropdown visibility

  const toggleDropdownHandler = () => {
    setShowDropdown(!showDropdown); // Toggle dropdown visibility
  };

  const handleSelect = (item) => {
    setAssignTo(item.label);
    setSelectAssignTo(item.value);
    setShowDropdown(false); // Close the dropdown after selecting an item
  };
  const createProjectAndGoToDesignQuotation = async () => {
    if (details?.projectId) {
      localStorage.setItem("projectId", details?.projectId);
      // Redirect to the quotation page (uncomment if needed)
      // window.location.assign(
      //   `${constants.BASE_URL}/quotation/enterprise-quotation/${details?.projectId}`
      // );
    } else {
      try {
        // Add client request
        const clientResponse = await axios.post(
          `${constants.PMT_API}/api/projects/addClient?userId=${localStorage.getItem("userId")}`,
          {
            email: details.email,
            location: details.address,
            name: details.name,
            projectName: details.name,
          }
        );
  
        // Add project request
        const projectResponse = await axios.post(
          `${constants.PMT_API}/api/projects/add-project?userId=${localStorage.getItem("userId")}`,
          {
            clientId: clientResponse.data._id,
            location: details.address,
            projectName: details.name,
            userId: localStorage.getItem("userId"),
            isConfirmed: false,
            phoneNumber: details.phoneNumber,
            clientName: details.name,
            isFromLead: true,
            projectAdmin: details?.assignedTo?._id,
            teamMembers: [details?.assignedTo?._id],
          }
        );
  
        localStorage.setItem("projectId", projectResponse?.data?._id);
  
        // Save projectId to lead
        await postReq(
          `${constants.CRM_API}/api/save-projectId-leads?leadId=${details?._id}&projectId=${projectResponse?.data?._id}`
        );
  
        // Fetch API and handle the next steps
        // apiFetch(tab);
  
        // Redirect (uncomment if needed)
        // window.location.assign(
        //   `${constants.BASE_URL}/quotation/enterprise-quotation/${projectResponse?.data?._id}`
        // );
      } catch (error) {
        const errorMessage = error?.response?.data?.message || "An error occurred";
        notification.error({
          message: errorMessage,
          duration: 1,
        });
      }
    }
  };
  return (
    <>
      <AssignLead
        show={showAssignLead}
        hide={hideAssignLeadHandler}
        leadsToChange={[details._id]}
      />
      <EditLeadModal
        show={showEditLead}
        onHide={hideEditLead}
        details={details}
      />
      {/* <EditLead show={showEditLead} hide={hideEditLead} /> */}
      <ConfirmCancel
        show={showCancel}
        hide={closeCancelModal}
        name={"Lead"}
        action={deleteLeadHandler}
      />
      <input
        className="d-none"
        ref={uploadFileRef}
        onChange={uploadFile}
        // multiple
        type="file"
        name=""
        id=""
      />
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={openShareEmail}
        size="lg"
        onHide={() => setOpenShareEmail(false)}
        centered
      >
        <Modal.Body>
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="share_head">Send Notification</div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setMailFiles([]);
                  setOpenShareEmail(false);
                }}
              >
                <img src={crossBold} alt="" />
              </div>
            </div>
            <div
              style={{
                gap: "17px",
                display: "flex",
                flexDirection: "column",
                marginTop: "1.2rem",
              }}
            >
              <div>
                {/* <div style={{ fontSize: "12px", fontWeight: "400", color: "#333", paddingBottom: "10px" }}>Email Template</div> */}
                <div>
                  <Dropdown drop="left">
                    <Dropdown.Toggle
                      className="toggleenew"
                      style={{
                        height: "36px",
                        width: "250px",
                        backgroundColor: "white",
                        border: "1px solid #CCCCCC",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                          padding: "16px",
                        }}
                      >
                        <div
                          style={{
                            color: "#454545",
                            fontSize: "12px",
                            fontWeight: "400",
                          }}
                        >
                          {templateName}
                        </div>
                        <div>
                          <img src={downArrowGrey} alt="" />
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ width: "250px" }}>
                      {/* <Dropdown.Item onClick={() => setTemplateName("Default Template")}>Default Template</Dropdown.Item>
                              <Dropdown.Item onClick={() => setTemplateName("Milstone Email for Valued Customer")}>Milstone Email for Valued Customer</Dropdown.Item>
                              <Dropdown.Item onClick={() => setTemplateName("Milestone Template")}>Milestone Template</Dropdown.Item> */}
                      {templates.map((item) => (
                        <Dropdown.Item
                          key={item.id}
                          onClick={mailTemplateHandler.bind(null, item)}
                        >
                          {item.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="mailModalToDiv">
                <div
                  style={{ fontSize: "12px", fontWeight: "400", color: "#333" }}
                >
                  To:
                </div>
                <div className="chips_whole">
                  {allEmails?.length > 0 &&
                    allEmails?.map((data) => {
                      return (
                        <div className="singleChip">
                          <div className="chipName">{data}</div>
                          <img
                            src={close}
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteEmail(data)}
                          />
                        </div>
                      );
                    })}
                  <input
                    type="text"
                    // ref={inputFocusRef}
                    id="form4Example1"
                    placeholder="Enter mail"
                    // autoFocus
                    // className="form-control br-0"
                    //   onClick={handleInputClick}
                    // value={activeLead?.email || ""}
                    onChange={(e) => setTempmail(e.target.value)}
                    onKeyUp={setMailData}
                    // disabled={mailStatus?.of}
                    className="emailModalAddEmail"
                    style={{
                      height: "inherit",
                      fontSize: "13px",
                      border: "none",
                      // borderBottom: "1px solid grey",
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                gap: "10px",
                display: "flex",
                paddingTop: "14px",
                paddingBottom: "14px",
                gap: "8px",
                alignItems: "center",
                borderBottom: "1px solid #DEDEDE",
              }}
            >
              <div
                style={{ fontSize: "12px", fontWeight: "400", color: "#333" }}
              >
                Subject:
              </div>
              <div>
                <input
                  className="input-custom"
                  type="text"
                  value={subjectForEmail}
                  onChange={(e) => setSubjectForEmail(e.target.value)}
                />
              </div>
            </div>
            <div style={{ paddingTop: "14px", position: "relative" }}>
              {/* <textarea
                                className="main_email"
                                value={shareData}
                                onChange={(e) => setShareData(e.target.value)}
                            ></textarea> */}
              {mentionDropdownVisible && dummyfilteredMentions.length > 0 && (
                <div
                  className="mention-dropdown"
                  style={{
                    position: "absolute",
                    background: "#fff",
                    zIndex: 1000,
                    padding: "8px",
                    borderRadius: "8px",
                    height: "8rem",
                    overflowY: "scroll",
                    boxShadow:
                      "0px -4px 8px 0px rgba(193, 192, 192, 0.08), 4px 8px 16px 0px rgba(193, 192, 192, 0.25)",
                    //  left: mentionPosition.left, top: mentionPosition.top,
                    right: "20%",
                    top: "10%",
                  }}
                >
                  {dummyfilteredMentions.map((mention) => (
                    <div
                      style={{ cursor: "pointer" }}
                      key={mention}
                      onClick={() => handleMentionSelect(mention)}
                    >
                      @{mention}
                    </div>
                  ))}
                </div>
              )}
              <ReactQuill
                ref={quillRef}
                theme="snow"
                rows="10"
                className="custom_quill"
                value={shareData}
                onChange={setShareData}
                onKeyDown={handleKeyDownTask2}
                style={{
                  width: "100%",
                  whiteSpace: "pre-wrap",
                  borderRadius: "4px",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
                modules={{ toolbar: toolbarOptions }}
              />
            </div>
            <div
              className="chipContainer"
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "6px",
                padding: "0px 20px",
                margin: "",
                height: "4.2rem",
                marginTop: "10px",
              }}
            >
              {mailFiles
                ?.filter((data) => data?.filename != undefined)
                ?.map((file, index) => {
                  return (
                    <div className="chips">
                      <span
                        style={{ whiteSpace: "nowrap" }}
                        onClick={() => {
                          window.open(file?.path);
                        }}
                      >
                        {file?.filename}
                      </span>
                      <span>
                        <RxCross2
                          size={16}
                          style={{ cursor: "pointer" }}
                          color="#74737F"
                          onClick={() => removeFile(index)}
                        />
                      </span>
                    </div>
                  );
                })}

              {mailFiles?.filter((file, inde) => {
                return;
              })}
            </div>
            <div
              className="attach-main"
              style={{
                display: "flex",
                marginLeft: "10px",
                height: "36px",
                width: "116px",
                padding: "4px 8px",
                alignItems: "center",
                borderRadius: "1px",
                gap: "4px",
                background: "#fff",
                justifyContent: "center",
              }}
            >
              <img
                src={attachedFile}
                className="attachfile"
                alt=""
                style={{ borderWidth: 0 }}
                onClick={() => uploadFileRef.current.click()}
              />
              <span
                className="attach"
                style={commonCss}
                onClick={() => uploadFileRef.current.click()}
              >
                Attach Files
              </span>
              {mailFiles?.length > 0 && (
                <span>
                  (
                  {
                    mailFiles?.filter((data) => data?.filename != undefined)
                      ?.length
                  }
                  )
                </span>
              )}
            </div>
            <div className="whole">
              {" "}
              <div
                className="send_btn"
                onClick={() => {
                  sendEmail();
                  setOpenShareEmail(false);
                }}
              >
                Send
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Offcanvas
        show={show}
        hide={hide}
        placement="end"
        style={{ width: "600px", marginTop: "3rem" }}
        onClick={addTagsCloseHandler}
        ref={offcanvasRef}
      >

        <Offcanvas.Body
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div style={{
            display: "flex",
            gap: "20px",
            flexDirection: "column", marginTop: "10px"
          }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <button onClick={hide} className={styles.offcanvasClose}>
                <PiCaretDoubleRight style={{ height: "18px", width: "18px" }} />{" "}
                Close Preview
              </button>
              <div style={{ display: "flex", gap: "8px", display: "flex", justifyContent: "end" }}>
                <button
                  className={styles.offcanvasDetails}
                  style={{ cursor: editLeadBoolean ? "pointer" : "default" }}
                  onClick={() => { editLeadBoolean ? showEmailModalHandler() : toast.error("Access disabled by Admin") }}
                >
                  <PiEnvelopeSimple /> Send Email
                </button>
                <button
                  className={styles.offcanvasDetails}
                  style={{ cursor: editLeadBoolean ? "pointer" : "default" }}
                  onClick={() => { editLeadBoolean ? createProjectAndGoToQuotation() : toast.error("Access disabled by Admin") }}
                >
                  <PiClipboardText />
                  Send Quotation
                </button>
                <button
                  onClick={() => {
                    localStorage.setItem("projectId", details?.projectId);
                    dispatch(selectedLeadData(details));
                    navigate(`/leads/lead-full-details/${id}`)
                  }}
                  className={styles.offcanvasDetails}
                >
                  View full details
                </button>
              </div>

            </div>
            <div
              style={{
                width: "100%",
                flexDirection: "column",
                gap: "16px",
                display: collapse ? "none" : "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                {details?.type == 1 ?
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip">
                        <span> {`${capitalizeFirstLetter(details?.otherContactDetails[0]?.otherContactFirstName)} ${capitalizeFirstLetter(details?.otherContactDetails[0]?.otherContactLastName)}`}</span>
                      </Tooltip>
                    }
                  >
                    <div className={styles.offcanvasName}>
                      {details?.otherContactDetails[0]?.otherContactFirstName && details?.otherContactDetails[0]?.otherContactLastName
                        ? `${capitalizeFirstLetter(details?.otherContactDetails[0]?.otherContactFirstName)} ${capitalizeFirstLetter(details?.otherContactDetails[0]?.otherContactLastName)}`
                        : details?.otherContactDetails[0]?.otherContactFirstName ? `${capitalizeFirstLetter(details?.otherContactDetails[0]?.otherContactFirstName)}` : "-"}
                    </div>
                  </OverlayTrigger>
                  :
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip">
                        <span> {details.name ? details.name : "-"}</span>
                      </Tooltip>
                    }
                  >
                    <div className={styles.offcanvasName}>{details.name}</div>
                  </OverlayTrigger>
                }
                <div
                  style={{ display: "flex", gap: "19px", alignItems: "center" }}
                >
                  <div
                    style={{
                      color: "#454545",
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    Created on:  {formatTimeAgo(details?.createdAt)}
                  </div>
                  {editLeadBoolean ? (
                    <Dropdown style={{ display: "flex", justifyContent: "center" }}>
                      <Dropdown.Toggle
                        style={{ backgroundColor: "white", border: "none" }}
                      >
                        <BsThreeDotsVertical
                          style={{
                            color: "#0084EF",
                            height: "18px",
                            width: "18px",
                          }}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className={styles.offcanvasHeaderOptions}>
                        <Dropdown.Item
                          className={styles.offcanvasHeaderOptionsItem}
                          onClick={() => { hide(); setShowEditLead(true); }}
                        >
                          <PiPencilSimpleLine
                            className={styles.offcanvasHeaderOptionsIcon}
                          />{" "}
                          Edit Lead
                        </Dropdown.Item>
                        <Dropdown.Item
                          className={styles.offcanvasHeaderOptionsItem}
                          onClick={assignLeadsHandler}
                        >
                          <PiUserCirclePlus
                            className={styles.offcanvasHeaderOptionsIcon}
                          />{" "}
                          Assign Lead to
                        </Dropdown.Item>
                        <Dropdown.Item
                          className={styles.offcanvasHeaderOptionsItem}
                          onClick={createProjectAndGoToQuotation}
                        >
                          <img
                            src={dollar}
                            className={styles.offcanvasHeaderOptionsIcon}
                          />{" "}
                          Send a Quotation
                        </Dropdown.Item>
                        <Dropdown.Item
                          className={styles.offcanvasHeaderOptionsItem}
                          onClick={() =>
                            window.location.assign(
                              `${constants.BASE_URL}/pmt/?leadId=${id}`
                            )
                          }
                        >
                          <img
                            src={folder}
                            className={styles.offcanvasHeaderOptionsIcon}
                          />{" "}
                          Convert to project
                        </Dropdown.Item>
                        <Dropdown.Item
                          className={styles.offcanvasHeaderOptionsItem}
                          onClick={() => setShowCancel(true)}
                        >
                          <PiTrash className={styles.offcanvasHeaderOptionsIcon} />{" "}
                          Delete Lead
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>) :
                    <BsThreeDotsVertical
                      onClick={() => toast.error("Access disabled by Admin")}
                      style={{
                        color: "#0084EF",
                        height: "18px",
                        width: "18px",
                      }}
                    />

                  }
                </div>
              </div>
              <div>
                <div style={{ display: "flex", gap: "12px" }}>
                  {presentTags &&
                    presentTags.map((item) => (
                      <div
                        style={{
                          backgroundColor: `${item?.tagColorCode}20`,
                          color: `${item?.tagColorCode}`,
                          width: "fit-content",
                          padding: "6px 10px",
                          fontWeight: "500",
                          fontSize: "12px",
                          borderRadius: "4px",
                        }}
                      >
                        {item?.tagName}
                      </div>
                    ))}
                  <button
                    style={{
                      padding: "6px 10px",
                      backgroundColor: "#F5FAFF",
                      display: "flex",
                      borderRadius: "4px",
                      gap: "8px",
                      color: "#0084EF",
                      cursor: editLeadBoolean ? "pointer" : "default",
                      fontWeight: "500",
                      fontSize: "12px",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "none",
                    }}
                    onClick={() => { editLeadBoolean ? setAddTags(true) : toast.error("Access disabled by Admin") }}
                  >
                    <PiTag />
                    Add Tags
                  </button>
                </div>
                {addTags && (
                  <div
                    onClick={propagationHandler}
                    style={{
                      position: "fixed",
                      backgroundColor: "white",
                      width: "15%",
                      padding: "15px",
                      borderRadius: "4px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 2px 7px",
                      zIndex: "50",
                    }}
                  >
                    <div
                      style={{
                        color: "#212121",
                        fontWeight: "500",
                        fontSize: "12px",
                      }}
                    >
                      Tags
                    </div>
                    <div className={styles.offcanvasPrevTags}>
                      {presentTags.map((item) => (
                        <div
                          style={{
                            backgroundColor: `${item?.tagColorCode}20`,
                            color: `${item?.tagColorCode}`,
                            width: "fit-content",
                            textWrap: "nowrap",
                            padding: "3px 3px 3px 3px",
                            fontWeight: "500",
                            fontSize: "12px",
                            borderRadius: "4px",
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          {item?.tagName}
                          <button
                            onClick={removeTagHandler.bind(null, item?._id)}
                            style={{
                              border: "none",
                              backgroundColor: `${item?.tagColorCode}10`,
                              color: `${item?.tagColorCode}`,
                            }}
                          >
                            <PiX />
                          </button>
                        </div>
                      ))}
                      <input
                        className={styles.offcanvasSearchTag}
                        type="text"
                        // placeholder="Search tags"
                        onChange={searchTagsHander}
                        value={tagSearch}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      {filteredTags &&
                        filteredTags.map((item) => (
                          <div
                            style={{
                              backgroundColor: `${item?.tagColorCode}20`,
                              color: `${item?.tagColorCode}`,
                              width: "fit-content",
                              padding: "6px 10px",
                              fontWeight: "500",
                              fontSize: "12px",
                              borderRadius: "4px",
                              cursor: "pointer",
                            }}
                            onClick={addTagHandler.bind(null, item._id)}
                          >
                            {item.tagName}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      padding: "0px 12px",
                      width: "293px"
                    }}
                  >
                    <div
                      style={{
                        color: "#454545",
                        fontWeight: "400",
                        fontSize: "11px",
                        textWrap: "nowrap"
                      }}
                    >
                      Phone no.
                    </div>
                    {details?.type == 1 ?
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip">
                            <span>{details?.otherContactDetails[0]?.otherContactPhoneNumber[0] ? details?.otherContactDetails[0]?.otherContactPhoneNumber[0] : "-"}</span>
                          </Tooltip>
                        }
                      >
                        <div className={styles.offcanvasDetails1} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                          {details?.otherContactDetails[0]?.otherContactPhoneNumber[0] ? details?.otherContactDetails[0]?.otherContactPhoneNumber[0] : "-"}
                        </div>
                      </OverlayTrigger>
                      :
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip">
                            <span>{details?.phoneNumber ? details?.phoneNumber : "-"}</span>
                          </Tooltip>
                        }
                      >
                        <div className={styles.offcanvasDetails1} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                          {details?.phoneNumber ? details?.phoneNumber : "-"}
                        </div>
                      </OverlayTrigger>
                    }
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      padding: "0px 12px",
                      width: "293px"
                    }}
                  >
                    <div
                      style={{
                        color: "#454545",
                        fontWeight: "400",
                        fontSize: "11px",
                      }}
                    >
                      Email address
                    </div>
                    {details?.type == 1 ?
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip">
                            <span>{details?.otherContactDetails[0]?.otherContactEmailId ? details?.otherContactDetails[0]?.otherContactEmailId : "-"}</span>
                          </Tooltip>
                        }
                      >
                        <div className={styles.offcanvasDetails1} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                          {details?.otherContactDetails[0]?.otherContactEmailId ? details?.otherContactDetails[0]?.otherContactEmailId : "-"}
                        </div>
                      </OverlayTrigger>
                      :
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip">
                            <span>{details?.email ? details?.email : "-"}</span>
                          </Tooltip>
                        }
                      >
                        <div className={styles.offcanvasDetails1} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                          {details?.email ? details?.email : "-"}
                        </div>
                      </OverlayTrigger>
                    }
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      borderRight: "1px solid #DEDEDE",
                      padding: "0px 12px",
                      marginTop: "10px",
                      width: "281px"
                    }}
                  >
                    <div
                      style={{
                        color: "#454545",
                        fontWeight: "400",
                        fontSize: "11px",
                        width: "281px"
                      }}
                    >
                      Exp. closure date
                    </div>
                    <div className={styles.offcanvasDetails1}>
                      {details?.expClosureDate
                        ? formatDate1(details?.expClosureDate)
                        : "-"}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      padding: "0px 12px",
                      width: "293px",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        color: "#454545",
                        fontWeight: "400",
                        fontSize: "11px",
                      }}
                    >
                      Pipeline Stage
                    </div>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip">
                          <span> {details?.leadStage ? details?.leadStage?.stageName : "-"}</span>
                        </Tooltip>
                      }
                    >
                      <div className={styles.offcanvasDetails1} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                        {details?.leadStage ? details?.leadStage?.stageName : "-"}
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #DEDEDE",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  width: "100%",
                  marginTop: "32px"
                }}
              >
                <div
                  style={{
                    color: active === "updateStatus" ? "#0084EF" : "#888686",
                    fontSize: "14px",
                    fontWeight: "600",
                    borderBottom:
                      active === "updateStatus" && "2px solid #0084EF",
                    cursor: "pointer",
                    padding: "0px 16px 12px 16px",
                  }}
                  onClick={makeUpdateStatusActive}
                >
                  Update Status
                </div>
                <div
                  style={{
                    color: active === "logHistory" ? "#0084EF" : "#888686",
                    fontSize: "14px",
                    fontWeight: "600",
                    borderBottom:
                      active === "logHistory" && "2px solid #0084EF",
                    cursor: "pointer",
                    padding: "0px 16px 12px 16px",
                  }}
                  onClick={makeLogHistoryActive}
                >
                  Log History
                </div>
              </div>
              {/* <button
                className={styles.offcanvasCollapse}
                onClick={collapseHandler}
              >
                <PiArrowsInSimple style={{ height: "12px", width: "12px" }} />
                Collapse
              </button> */}
            </div>
            {active === "updateStatus" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  height: "100%",
                  overflowY: "auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        color: "#191D23",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Assign To
                    </div>
                    {editLeadBoolean ? (
                      <Dropdown
                        className={styles.offcanvasStage}
                        show={showDropdown}
                        onToggle={toggleDropdownHandler}
                      >
                        <Dropdown.Toggle
                          className={styles.offcanvasSelectStage}
                          style={{borderColor:"#000000"}}
                          onClick={toggleDropdownHandler} // Toggle dropdown manually
                        >
                          <div
                            style={{
                              color:  "#000000",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            {assignTo ? assignTo : details?.assignToName ? details?.assignToName : "Select Member"}
                          </div>
                          <PiCaretDown className={styles.offcanvasCaretDown} />
                        </Dropdown.Toggle>

                        {showDropdown && (
                          <Dropdown.Menu className={styles.offcanvasStageDropdown}>
                            {members.map((item) => (
                              <Dropdown.Item
                                key={item.value}
                                className={styles.offcanvasStageOption}
                                onClick={() => handleSelect(item)} // Call the handler to select the item and close the dropdown
                              >
                                {item.label}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        )}
                      </Dropdown>)
                      :
                      <Dropdown
                        className={styles.offcanvasStage}
                        show={showDropdown}
                      >
                        <Dropdown.Toggle
                          className={styles.offcanvasSelectStage}
                          onClick={() => toast.error("Access disabled by Admin")}
                        >
                          <div
                            style={{
                              color: assignTo ? "#000000" : "#888888",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            {assignTo ? assignTo : details?.assignToName ? details?.assignToName : "Select Member"}
                          </div>
                          <PiCaretDown className={styles.offcanvasCaretDown} />
                        </Dropdown.Toggle>
                      </Dropdown>}
                    {/* changeAssignedHandler(item.value, item.label) */}

                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        color: "#191D23",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Stage
                    </div>
                    {editLeadBoolean ? (
                      <Dropdown
                        className={styles.offcanvasStage}
                        show={showStage}
                        onToggle={(details?.assignedTo !== undefined || assignTo !== null) ? toggleStageHandler : undefined}
                      >
                        <Dropdown.Toggle
                          className={styles.offcanvasSelectStage}
                          style={{ cursor: (details?.assignedTo == undefined || assignTo !== null) && "default",borderColor:(selectedStage && (details?.assignedTo !== undefined || assignTo !== null)) ? "#000000":"#888888" }}
                        // onClick={toggleStageHandler}  style={{borderColor:"#000000"}}
                        >
                          <div
                            style={{
                              color: (selectedStage && (details?.assignedTo !== undefined || assignTo !== null)) ? "#000000" : "#888888",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            {selectedStage
                              ? selectedStage?.stageName
                              : "Select Stage"}
                          </div>
                          <PiCaretDown className={styles.offcanvasCaretDown} />
                        </Dropdown.Toggle>
                        {showStage && (
                          <Dropdown.Menu
                            className={styles.offcanvasStageDropdown}
                          >
                            {stageData.map((item) => (
                              <Dropdown.Item
                                key={item._id}
                                className={styles.offcanvasStageOption}
                                onClick={changeStageHandler.bind(null, item)}
                                disabled={details && (details?.leadStage?.stageName === "Won")}
                              >
                                {item.stageName}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        )}
                      </Dropdown>)
                      :
                      <Dropdown
                        className={styles.offcanvasStage}
                        show={showStage}
                      >
                        <Dropdown.Toggle
                          className={styles.offcanvasSelectStage}
                          style={{ cursor: details?.assignedTo == undefined && "default" }}
                          onClick={() => toast.error("Access disabled by Admin")}
                        >
                          <div
                            style={{
                              color: (selectedStage && details?.assignedTo !== undefined) ? "#000000" : "#888888",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            {selectedStage
                              ? selectedStage?.stageName
                              : "Select Stage"}
                          </div>
                          <PiCaretDown className={styles.offcanvasCaretDown} />
                        </Dropdown.Toggle>
                      </Dropdown>
                    }
                    {/* <div className={styles.offcanvasLastActivity}>
                      <IoEllipse
                        style={{
                          color: "#00B200",
                          height: "6px",
                          width: "6px",
                        }}
                      />{" "}
                      Last Activity: {formatDate(details.updatedAt)}
                    </div> */}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}
                  >
                    <Dropdown
                      className={styles.offcanvasRemarkDropdown}
                      show={showRemark}
                      onToggle={toggleRemarkHandler}
                    >
                      <Dropdown.Toggle
                        className={styles.offcanvasRemarkToggle}
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <div style={{ color: "#191d23" }}>
                          Today's {selectedRemark ? selectedRemark.remark : ""}{" "}
                          Remark
                        </div>
                        <PiCaretDown style={{ color: "#191d23" }} />
                      </Dropdown.Toggle>
                      {showRemark && (
                        <Dropdown.Menu className={styles.offcanvasRemarkMenu}>
                          {callRemark &&
                            callRemark.map((item) => (
                              <Dropdown.Item
                                className={styles.offcanvasRemarkItem}
                                key={item.id}
                                onClick={changeRemarkHandler.bind(null, item)}
                              // disabled={details && (details?.leadStage?.stageName === "Won")}
                              >
                                {item.remark}
                              </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                      )}
                    </Dropdown>

                    {editLeadBoolean ? (
                      <Dropdown
                        className={styles.offcanvasStatus}
                        show={showStatus}
                        style={{ width: "265px", cursor: details?.assignedTo == undefined && "default" }}

                        onToggle={(details?.assignedTo !== undefined || assignTo !== null) ? toggleStatusHandler : undefined}
                      >
                        <Dropdown.Toggle 
                          className={styles.offcanvasSelectStatus}
                         style={{width: "265px",borderColor:(selectedStatus && (details?.assignedTo !== undefined || assignTo !== null)) ? "#000000":"#888888" }}
                        >
                          <div className={styles.offcanvasSelectedStatus}
                            style={{ color: (selectedStatus && (details?.assignedTo !== undefined || assignTo !== null)) ? "#000000" : "#888888", }}
                          >
                            {selectedStatus
                              ? selectedStatus?.statusName
                              : "Select Status"}
                          </div>
                          <PiCaretDown className={styles.offcanvasCaretDown} />
                        </Dropdown.Toggle>
                        {showStatus && (
                          <Dropdown.Menu
                            className={styles.offcanvasStatusDropdown}
                          >
                            {selectedStage &&
                              selectedStage.status.map((item) => (
                                <Dropdown.Item
                                  key={item.id}
                                  onClick={changeStatusHandler.bind(null, item)}
                                  disabled={details && (details?.leadStage?.stageName === "Won")}
                                  className={styles.offcanvasStatusOption}
                                >
                                  {item.statusName}
                                </Dropdown.Item>
                              ))}
                          </Dropdown.Menu>
                        )}
                      </Dropdown>) :
                      <Dropdown
                        className={styles.offcanvasStatus}
                        show={showStatus}
                        style={{ width: "265px", cursor: (details?.assignedTo == undefined || assignTo == null) && "default" }}
                        onClick={() => toast.error("Access disabled by Admin")}
                      >
                        <Dropdown.Toggle
                          style={{ width: "265px" }}
                          className={styles.offcanvasSelectStatus}
                        >
                          <div className={styles.offcanvasSelectedStatus}
                            style={{ color: (selectedStatus && (details?.assignedTo !== undefined || assignTo !== null)) ? "#000000" : "#888888", }}
                          >
                            {selectedStatus
                              ? selectedStatus?.statusName
                              : "Select Status"}
                          </div>
                          <PiCaretDown className={styles.offcanvasCaretDown} />
                        </Dropdown.Toggle>
                      </Dropdown>
                    }
                  </div>
                  {selectedStage?.stageName !== "Lost" && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "12px",
                      }}
                    >
                      <div
                        style={{
                          color: "#191D23",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        Follow up on
                      </div>
                      <div style={{ display: "flex", gap: "7px" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Select date"
                            value={date}
                            onChange={(newValue) => setDate(newValue)}
                            minDate={dayjs()} // Prevents selection of dates before today
                            slots={{
                              openPickerIcon: PiCalendar,
                            }}
                            className={styles.offcanvasDatePicker}
                            slotProps={{
                              textField: {
                                size: "small",
                                InputProps: {
                                  style: {
                                    fontSize: "14px",
                                    // color: "black",
                                    borderColor: "black",
                                  },
                                  sx: {
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: "black",
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "black",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "black",
                                      },
                                      "&.Mui-error fieldset": {
                                        borderColor: "black",
                                      },
                                    },
                                  },
                                },
                                InputLabelProps: {
                                  style: {
                                    fontSize: "14px",
                                    // color: "black",
                                  },
                                },
                              },
                            }}
                            disabled={(details?.assignedTo === undefined && assignTo === null) || !editLeadBoolean}
                          />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            label="Select time"
                            value={time}
                            onChange={(newValue) => setTime(newValue)}
                            slots={{
                              openPickerIcon: PiClock,
                            }}
                            className={`${styles.offcanvasDatePicker} fixedWidthTimePicker`}
                            slotProps={{
                              textField: {
                                size: "small", InputProps: {
                                  style: { fontSize: "14px" } // Apply font size here
                                },
                                InputLabelProps: {
                                  style: { fontSize: "14px" } // Apply font size to the label as well
                                }
                              },
                            }}
                            disabled={(details?.assignedTo === undefined && assignTo === null) || !editLeadBoolean}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <div
                      style={{
                        color: "#191D23",
                        fontWeight: "400",
                        fontSize: "14px",
                      }}
                    >
                      Add Comment
                    </div>
                    <textarea
                      rows="6"
                      cols="50"
                      style={{
                        resize: "none",
                        borderRadius: "4px",
                        height: "140px",
                        marginBottom: "30px",
                        border: isFocused
                          ? "1px solid rgb(0, 132, 239)"
                          : "1px solid #DEDEDE",
                        padding: "8px",
                      }}
                      className={styles.textareaCustom}
                      onFocus={() => {
                        if (editLeadBoolean) {
                          setIsFocused(true);
                        } else {
                          toast.error("Access disabled by Admin");
                        }
                      }}
                      onBlur={() => setIsFocused(false)}
                      placeholder="Type here to comment"
                      value={comment}
                      onChange={(e) => {
                        if (editLeadBoolean) {
                          commentHandler(e);
                        } else {
                          toast.error("Access disabled by Admin");
                        }
                      }}
                      disabled={!editLeadBoolean}
                      id="comment"
                    />
                  </div>
                </div>
                <div style={{ height: "10px", width: "100%", background: "white" }}></div>
                <div>
                  <button
                    style={{
                      width: "47.3%",
                      marginLeft: "-1rem",
                      backgroundColor: ((canUpdate && details?.assignedTo !== undefined) || (assignTo !== null)) ? "#0084EF" : "#D6D6D6",
                      color: canUpdate ? "#FFFFFF" : "#8E8E8E",
                      fontSize: "14px",
                      fontWeight: "400",
                      position: "fixed",
                      bottom: "16px",
                      border: "none",
                      padding: "10px 177px",
                      pointerEvents: (assignTo !== null)
                        ? "unset"
                        : (!canUpdate && details?.assignedTo === undefined)
                          ? "none" :
                          details?.assignedTo === undefined ?
                            "none" : "unset"
                    }}
                    onClick={() => { editLeadBoolean ? updateStatusHandler() : toast.error("Access is disabled by Admin") }}
                  >
                    Update
                  </button>
                </div>
              </div>
            )}
            {active === "logHistory" && (
              <div className={styles.offcanvasLogHistoryMain}>
                {logHistory &&
                  logHistory.map(
                    (item) =>
                      item?.updatedBy && (
                        <div className={styles.offcanvasLogHistoryLog}>
                          {item?.updatedBy && (
                            // item?.leadUpdates?.updateDate &&
                            // item?.leadUpdates?.updateTime &&
                            <div className={styles.offcanvasLogHistoryDiv1}>
                              <div className={styles.offcanvasLogHistoryName}>
                                {item?.updatedBy}
                              </div>
                              <div
                                className={styles.offcanvasLogHistoryDateTime}
                              >
                                {createFormat(item.createdAt)}
                              </div>
                            </div>
                          )}
                          {/* {item?.assignedTo?.fullName &&( <div className={styles.offcanvasLogHistoryDiv1}>
                             
                              <div
                                className={styles.offcanvasLogHistoryDateTime}
                              >
                               Lead Assigned to
                              </div>
                              <div className={styles.offcanvasLogHistoryName}>
                                {item?.assignedTo?.fullName}
                                </div>
                            </div>)} */}
                          {item?.stageOld && item?.stageNew && (
                            <div className={styles.offcanvasLogHistoryDiv2}>
                              <span className={styles.offcanvasLogHistorySpan}>
                                Lead stage updated from
                              </span>
                              <span
                                className={styles.offcanvasLogHistoryStage}
                                style={{
                                  color: assignColor(item?.stageOld).textColor,
                                  backgroundColor: assignColor(item?.stageOld)
                                    .backgroundColor,
                                }}
                              >
                                {item?.stageOld}
                              </span>
                              <span className={styles.offcanvasLogHistorySpan}>
                                to
                              </span>
                              <span
                                className={styles.offcanvasLogHistoryStage}
                                style={{
                                  color: assignColor(item?.stageNew).textColor,
                                  backgroundColor: assignColor(item?.stageNew)
                                    .backgroundColor,
                                }}
                              >
                                {item?.stageNew}
                              </span>
                            </div>
                          )}
                          {item?.leadUpdates &&
                            ((item?.leadUpdates[0]?.callLeadStatus &&
                              item?.todayRemark != null) ||
                              item?.leadUpdates[0]?.text) && (
                              <div className={styles.offcanvasLogHistoryDiv3}>
                                {item?.leadUpdates[0]?.callLeadStatus &&
                                  item?.todayRemark != undefined && (
                                    <div
                                      className={
                                        styles.offcanvasLogHistoryDiv3Div1
                                      }
                                    >
                                      <div
                                        className={
                                          styles.offcanvasLogHistoryDiv3Div
                                        }
                                      >
                                        Meeting type:{" "}
                                        {callRemark[item?.todayRemark].remark}
                                      </div>
                                      <div
                                        className={
                                          styles.offcanvasLogHistoryDiv3Div
                                        }
                                      >
                                        Call Status:{" "}
                                        {getStatus(
                                          item?.stageNew,
                                          item?.leadUpdates[0]?.callLeadStatus
                                        )}
                                      </div>
                                    </div>
                                  )}
                                {item?.leadUpdates[0]?.text && (
                                  <div
                                    className={
                                      styles.offcanvasLogHistoryDiv3Div2
                                    }
                                  >
                                    Comment: {item?.leadUpdates[0]?.text}
                                  </div>
                                )}
                              </div>
                            )}
                        </div>
                      )
                  )}
              </div>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal show={showModal} centered>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ marginBottom: "10px" }}>
              <img src={iconGreen} />
            </div>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "600",
                paddingBottom: "27px",
              }}
            >
              Lead updated successfully
            </div>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "400",
                marginBottom: "40px",
              }}
            >
              Lead's all set! Want to add a follow-up task?
            </div>
            <div style={{ display: "flex", gap: "18px" }}>
              <div
                onClick={() => {
                  setShowModal(false);
                  init();
                }}
                style={{
                  background: "#E4E4E4",
                  color: "#888888",
                  padding: "8px 16px",
                  cursor: "pointer",
                  fontSize: "15px",
                  fontWeight: "500",
                  borderRadius: "8px",
                }}
              >
                Dismiss
              </div>
              <div
                onClick={() => {
                  createProjectAndGoToDesignQuotation();
                  setShowModal(false);
                  localStorage.setItem("projectName", details.name);
                  localStorage.setItem("projectId", details?._id);
                  window.location.href = `${constants.BASE_URL}/timeline/task-manager/all-task/task/${details?._id}?isLead=${true}`;
                }}
                style={{
                  background: "#0084EF",
                  color: "#FFFFFF",
                  padding: "8px 16px",
                  cursor: "pointer",
                  fontSize: "15px",
                  fontWeight: "500",
                  borderRadius: "8px",
                }}
              >
                Create Task
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LeadManagementOffcanvas;
