import React, { useContext } from 'react'
import desquoContext from '../picontext/DashBoard/desquoContext';
import { getReq, postReq } from './apis';
import expf, { constants } from './constants';
import { useDispatch, useSelector } from 'react-redux';
import { setAllAssignToAccessUser, setAllNotificationsObjects } from '../Redux/Slices/notificationSlice';

function useApiFetchCustomHookForEmailService() {
  const context = useContext(desquoContext);
  const profileData = useSelector((state) => state.lmsSlice.profileData);
  const allNotificationsObjects = useSelector(
    (state) => state.notificationSlice.allNotificationsObjects
  );
  const {
    activeLead
  } = context;
  const dispatch = useDispatch()

  console.log('activeleadnotify', activeLead)

  const getNotificationServices = async (tab) => {
    console.log('allNotificationsObjectscaling')

    const res = await postReq(
      `https://notify-api-uat.idesign.market/api/notifications/get-specific-feature-notification?userId=${localStorage.getItem('userId')}`, {
      featureName: "LMS"
    }
    );
    if (res && !res.error) {
      console.log('notification response: ', res.data)
      dispatch(setAllNotificationsObjects(res.data))
    } else {
      console.error("Something went wrong")
    }
  }
  const getAllAssignToAccessUser = async (tab) => {
    console.log('allNotificationsObjectscaling')

    const res = await getReq(
      `${constants.CRM_API}/user/get-lead-assign-access-users?userId=${localStorage.getItem('userId')}`
    );
    if (res && !res.error) {
      console.log('assignto user response: ', res.data.data)
      dispatch(setAllAssignToAccessUser(res.data.data))
    } else {
      console.error("Something went wrong")
    }
  }

  const sendEmailPushBellNotifications = (ctaButton, stringToReplaceInEmailString, reaceiversArray , otherReceivers ) => {

    console.log('stringToReplaceInEmailString', stringToReplaceInEmailString)
    console.log('ctaButton', ctaButton)
    console.log('reaceiversArray', reaceiversArray)

    let isPushNotificationSentOnce = false;
    let isEmailNotificationSentOnce = false;
    let isBellNotificationSentOnce = false;

    const ctaNotificationArray = allNotificationsObjects?.filter(
      (notificationObject) => notificationObject.CTA === ctaButton
    );
    console.log('ctaNotificationArray', ctaNotificationArray)
    ctaNotificationArray.map(async (callingNotificationServiceType) => {
      const usersToReceiveMails = [];
      if (callingNotificationServiceType.superAdmin) {
        usersToReceiveMails?.push(...profileData.superAdmins);
      }
      if (callingNotificationServiceType.admins) {
        usersToReceiveMails?.push(...profileData.admins);
      }
      if (callingNotificationServiceType.reciever) {
        usersToReceiveMails?.push(...reaceiversArray);
      }
      if (callingNotificationServiceType.otherReceivers) {
        usersToReceiveMails?.push(...otherReceivers);
      }

      console.log('usersToReceiveMails', usersToReceiveMails)
      const removingDublicateUsers = usersToReceiveMails.filter(
        (v, i, a) => a.findIndex((v2) => v2?._id === v?._id) === i
      );
      console.log('removingDublicateUsers', removingDublicateUsers)
      if (callingNotificationServiceType.type == 1) {
        isEmailNotificationSentOnce = true;
        // const stringToReplaceInEmailString = {
        //   // "[Client Name]": `${leadFirstName}`,
        //   "[Lead Name]": `${leadFirstName} ${leadLastName}`,
        //   "[Lead Company]": profileData.CompanySettingDetails.companyName,
        //   "[Lead Email]": leadEmail,
        //   "[Lead Phone]": phoneNumber,
        //   "[Lead Source]": leadSource,
        //   "[Assigned Sales Rep]": assignedToName,
        // };
        // const subject = callingNotificationServiceType.subject;
        let emailString = callingNotificationServiceType.emailString;
        let replacedSubject = callingNotificationServiceType.subject

        for (let key in stringToReplaceInEmailString) {
          replacedSubject = replacedSubject.replace(
            key,
            stringToReplaceInEmailString[key]
          );
        }
        for (let key in stringToReplaceInEmailString) {
          emailString = emailString.replace(
            key,
            stringToReplaceInEmailString[key]
          );
        }

        console.log("emailstring: ", emailString);
        // const replacedEmailString = emailString.replace('[Client Name]',`${leadFirstName} ${leadLastName}`)
        removingDublicateUsers?.map(async (Mailuser) => {
          const updatedClientEmailBody = emailString.replace(
            "@Receiver",
            Mailuser.fullName.split(" ")[0]
          );
          const res = await postReq(
            constants.NOTIFY_API + "/api/notifications/send-service?type=1",
            {
              // companyName: profileData.CompanySettingDetails.companyName,
              companyName: profileData.fullName,
              recieverEmails: [Mailuser.email],
              subject: replacedSubject,
              htmlContent: updatedClientEmailBody,
              replyTo: profileData.email
            }
          );
          if (res.error) {
            console.log(res.error);
          }
        });
      } else if (callingNotificationServiceType.type == 2 && !isBellNotificationSentOnce) {
        const userIds = removingDublicateUsers.map((user) => user?._id);
        isBellNotificationSentOnce = true
        let updatedNotificationString = callingNotificationServiceType.notificationString

        for (let key in stringToReplaceInEmailString) {
          updatedNotificationString = updatedNotificationString.replace(
            key,
            stringToReplaceInEmailString[key]
          );
        }
        const res = await postReq(
          constants.NOTIFY_API + "/api/notifications/send-service?type=2",
          {
            userId: localStorage.getItem("userId"),
            // receiverId: 6518f9cedc770dc818ee8898,
            notification: updatedNotificationString,
            featureName: "LMS",
            featureLink: `${constants.BASE_URL}/leads`,
            extraUserIds: [...userIds],//optional
            companyName: ctaButton == "Add Lead" ? profileData?.CompanySettingDetails?.companyName : `${activeLead.firstName} ${activeLead.lastName}`
            // clientId: 64880ca6b08bde3ab8f78a97,//optional
            // shareWithClient: true,//optional
            // forAdmins:[64802123035f491df0ea5756,64880a71fcc15d20a419b83d],//optional
          }
        );
        if (res.error) {
          console.log(res.error);
        }
      } else if (callingNotificationServiceType.type == 3 && !isPushNotificationSentOnce) {
        isPushNotificationSentOnce = true;
        let updatedNotificationString = callingNotificationServiceType.notificationString
        for (let key in stringToReplaceInEmailString) {
          updatedNotificationString = updatedNotificationString.replace(
            key,
            stringToReplaceInEmailString[key]
          );
        }

        console.log('removingDublicateUsers', removingDublicateUsers)
        const userIds = removingDublicateUsers.map((user) => user?._id);
        console.log('userIdsss', userIds)

        const res = await postReq(
          constants.NOTIFY_API + "/api/notifications/send-service?type=3",
          {
            receiverIds: userIds,
            notification: updatedNotificationString,
            projectName: '-'
          }
        );
        if (res.error) {
          console.log(res.error);
        }
      }
    });
  }

  return { getNotificationServices, getAllAssignToAccessUser, sendEmailPushBellNotifications }
}

export default useApiFetchCustomHookForEmailService